import { mockFlag } from '@/utils/mock'
import { APIFailureWrapper } from '@/utils/mock'
import {
  profiles,
  profileToJobTitle,
  users,
} from '@/modules/users/mock/usersSeeds'
import { currentAccount, getCurrentUser } from '@/services/__mocks__/helpers'
import { plans } from '@/services/__mocks__/plansSeeds'
import { farms } from '@/modules/farm/mock/farmSeeds'

const usersRoutes = [
  mockFlag(
    {
      method: 'delete',
      url: '/users/:id',
      result: ({ params }) => {
        const userIndex = users.findIndex((user) => user.id == params.id)
        users.splice(userIndex, 1)

        return APIFailureWrapper({
          content: null,
          errorMessage: 'Erro na deleção de usuário',
        })
      },
    },
    'on'
  ),
  mockFlag(
    {
      method: 'get',
      url: '/users',
      result: () => {
        const currentUser = getCurrentUser()

        const filteredUsers = users.reduce((acc, user) => {
          if (user.account_id == currentUser.user.account) {
            acc.push({
              account_id: user.account_id,
              active: user.active,
              email: user.email,
              farms: user.farms.map((farm) => farm.id),
              id: user.id,
              name: user.name,
              profile_id: user.profile.id,
            })
          }
          return acc
        }, [])

        const response = filteredUsers

        return APIFailureWrapper({
          content: response,
          errorMessage: 'Erro na listagem de usuários',
        })
      },
    },
    'on'
  ),
  mockFlag(
    {
      method: 'get',
      url: '/profiles',
      result: () => {
        return APIFailureWrapper({
          content: profiles,
          errorMessage: 'Erro na listagem de perfis',
        })
      },
    },
    'on'
  ),
  mockFlag(
    {
      method: 'post',
      url: '/users',
      result: ({ requestBody }) => {
        const maxActiveUsers = currentAccount.max_users
        const activeUsersQtt = users.filter(
          (user) => user.account_id == currentAccount.id && !!user.active
        ).length

        if (activeUsersQtt < maxActiveUsers) {
          const requestObj = JSON.parse(requestBody)

          const userFarms = farms.filter((farm) =>
            requestObj.farms.includes(farm.id)
          )
          const accountId = userFarms[0].account_id

          const newUser = {
            account_id: String(accountId),
            active: true,
            email: requestObj.email,
            farms: userFarms,
            id: users.length + 1,
            name: requestObj.name,
            profile: profiles.find(
              (profile) => profile.id == requestObj.profile_id
            ),
            language: 'pt',
            is_admin: false,
            first_login: 'Y',
            job_title: profileToJobTitle[requestObj.profile_id],
            plan: plans[2],
          }
          users.push(newUser)
        } else {
          return APIFailureWrapper({
            content: null,
            failureRate: 100,
            specificError: {
              status: 400,
              message: 'exception.users.exceeded_limit',
            },
          })
        }

        return APIFailureWrapper({
          content: null,
          errorMessage: 'Erro ao criar usuário',
        })
      },
    },
    'on'
  ),
  mockFlag(
    {
      method: 'put',
      url: '/users/:id',
      result: ({ params, requestBody }) => {
        const requestObj = JSON.parse(requestBody)

        users.forEach((user) => {
          if (user.id == params.id) {
            user.email = requestObj.email
            user.farms = farms.filter((farm) =>
              requestObj.farms.includes(farm.id)
            )
            user.name = requestObj.name
            user.profile = profiles.find(
              (profile) => profile.id == requestObj.profile_id
            )
          }
        })

        return APIFailureWrapper({
          content: null,
          errorMessage: 'Erro ao editar usuário',
        })
      },
    },
    'on'
  ),
  mockFlag(
    {
      method: 'put',
      url: '/users/:user_id/active',
      result: ({ params }) => {
        const isActive = users.find((user) => user.id == params.user_id).active

        const maxActiveUsers = currentAccount.max_users
        const activeUsersQtt = users.filter(
          (user) => user.account_id == currentAccount.id && !!user.active
        ).length

        if (activeUsersQtt < maxActiveUsers || isActive) {
          users.forEach((user) => {
            if (user.id == params.user_id) {
              user.active = !user.active
            }
          })
        } else {
          return APIFailureWrapper({
            content: null,
            failureRate: 100,
            specificError: {
              status: 400,
              message: 'exception.users.exceeded_limit',
            },
          })
        }

        return APIFailureWrapper({
          content: null,
          errorMessage: 'Erro ao mudar usuário para ativo / inativo',
        })
      },
    },
    'on'
  ),
]

export { usersRoutes }
