import {
  catalogFungicides,
  catalogNematicides,
  catalogVarieties,
} from '@/modules/catalog/mock/catalogSeeds.js'
import { mockFlag } from '@/utils/mock'
import { APIFailureWrapper } from '@/utils/mock'

const catalogRoutes = [
  mockFlag(
    {
      method: 'get',
      url: '/catalogs',
      result: ({ queryParams }) => {
        const products = {}
        if (queryParams.category === 'fungicide') {
          products.list = catalogFungicides
          products.total = catalogFungicides.length
        } else if (queryParams.category === 'variety') {
          products.list = catalogVarieties
          products.total = catalogVarieties.length
        } else if (queryParams.category === 'nematicide') {
          products.list = catalogNematicides
          products.total = catalogNematicides.length
        }
        return APIFailureWrapper({
          content: products,
          errorMessage: 'Erro ao buscar items do catálogo',
        })
      },
    },
    'on'
  ),
  mockFlag(
    {
      method: 'get',
      url: '/catalogs/fungicide/filters',
      result: () => {
        // TODO: não é o foco do momento
        return APIFailureWrapper({
          content: [],
          errorMessage: 'Erro ao buscar fungicidas do catálogo',
        })
      },
    },
    'on'
  ),
  mockFlag(
    {
      method: 'get',
      url: '/catalogs/:category/filters',
      result: () => {
        // TODO: não é o foco do momento
        return APIFailureWrapper({
          content: [],
          errorMessage: 'Erro ao buscar categorias do catálogo',
        })
      },
    },
    'on'
  ),
]

export { catalogRoutes }
