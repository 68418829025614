export default {
  Users: {
    email: 'Email',
    home: {
      configurations: 'Configurations',
      users: 'Users',
      register: 'Register',
    },
    list: {
      user: 'user',
      status: 'Status',
    },
    list_item: {
      active: 'Active',
      inactive: 'Inactive',
      cancel: 'Cancel',
      confirm: 'Confirm',
      delete_confirm: 'Are you sure you want to delete this user?',
      delete_success: 'User deleted successfully',
    },
    modal: {
      name: 'Name',
      name_placeholder: 'Type a name',
      email_placeholder: 'Type an email',
      profile: 'Profile',
      profile_placeholder: 'Select a profile',
      farms: 'Farms',
      farms_placeholder: 'Select user farms',
      add: 'Add',
      edit: 'Edit',
      edit_user: 'Edit user',
      register_user: 'Register user',
      register_success: 'User registered successfully',
      exceeded_users_title: 'User limit reached',
      exceeded_users_description:
        'You have reached the limit of {0} active users on your account. If you need more users on your account, you can hire them by contacting our sales team. Or deactivating some users so you can activate a new user.',
      comercial_whatsapp: 'Consult commercial',
    },
    profiles: {
      ADMIN: 'General Administrator',
      CONTA: 'Account Administrator',
      FARM: 'Agricultural Engineer',
      ASSISTENTE_TECNICO: 'Observer',
      FINANCEIRO: 'Financial',
      COMERCIAL: 'Commercial',
      ADM_CORP: 'Corp Administrator',
      VEND_CORP: 'Corp Salesperson',
      VEND_DGF: 'Coupon Manager',
      TEC_DGF: 'Rational Manager',
      CON_REV: 'Resale Consultant',
      PESQUISA: 'Research',
      description: {
        ADMIN: 'Can edit, view and create all',
        CONTA: 'Can edit, view and create all',
        FARM: 'Can edit all, except farms and plots',
        ASSISTENTE_TECNICO: 'View only',
        FINANCEIRO: 'Financial',
        COMERCIAL: 'Commercial',
        ADM_CORP: 'Corp Administrator',
        VEND_CORP: 'Corp Salesperson',
        VEND_DGF: 'Coupon Manager',
        TEC_DGF: 'Rational Manager',
        CON_REV: 'Can edit, view and create, except users',
        PESQUISA:
          'Can view users, create and edit programs, sowings, farms and plots',
      },
    },
  },
}
