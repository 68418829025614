import { mockFlag } from '@/utils/mock'
import { APIFailureWrapper } from '@/utils/mock'
import { crops } from '@/services/__mocks__/cropsSeeds'

const cropsRoutes = [
  mockFlag(
    {
      method: 'get',
      url: '/crops',
      result: () => {
        return APIFailureWrapper({
          content: crops,
          errorMessage: 'Erro ao buscar crops',
        })
      },
    },
    'on'
  ),
]

export { cropsRoutes }
