import { crops } from '@/services/__mocks__/cropsSeeds'

const diseases = [
  {
    id: 1,
    name: 'Asian soybean rust',
    crop_id: crops[0].id,
    code: 'soybean_asian_soybean_rust',
  },
  {
    id: 2,
    name: 'Anthracnosis',
    crop_id: crops[0].id,
    code: 'soybean_anthracnosis',
  },
  {
    id: 3,
    name: 'Target spot',
    crop_id: crops[0].id,
    code: 'soybean_target_spot',
  },
  {
    id: 4,
    name: 'Powdery mildew',
    crop_id: crops[0].id,
    code: 'soybean_powdery_mildew',
  },
  {
    id: 5,
    name: 'Cercospora blight',
    crop_id: crops[0].id,
    code: 'soybean_cercospora_blight',
  },
  {
    id: 6,
    name: 'Leaf rust',
    crop_id: crops[1].id,
    code: 'wheat_leaf_rust',
  },
  {
    id: 7,
    name: 'Powdery mildew',
    crop_id: crops[1].id,
    code: 'wheat_powdery_mildew',
  },
  {
    id: 8,
    name: 'Yellow leaf spot',
    crop_id: crops[1].id,
    code: 'wheat_yellow_leaf_spot',
  },
  {
    id: 9,
    name: 'Fusarium head blight',
    crop_id: crops[1].id,
    code: 'wheat_fusarium_head_blight',
  },
  {
    id: 10,
    name: 'Blast',
    crop_id: crops[1].id,
    code: 'wheat_blast',
  },
  {
    id: 11,
    name: 'Septoria leaf spot',
    crop_id: crops[0].id,
    code: 'soybean_septoria_leaf_spot',
  },
  {
    id: 12,
    name: 'Aerial web blight',
    crop_id: crops[0].id,
    code: 'soybean_aerial_web_blight',
  },
  {
    id: 13,
    name: 'White mold',
    crop_id: crops[0].id,
    code: 'soybean_white_mold',
  },
]

export { diseases }
