import { APIFailureWrapper, mockFlag } from '@/utils/mock'
import { performances } from '@/modules/performance/mock/performanceSeeds'

const performanceRoutes = [
  mockFlag(
    {
      method: 'get',
      url: '/fazendas/:farm_id/stats',
      result: ({ params }) => {
        const response = performances.find(
          (performance) => performance.talhoes[0].fazenda_id == params.farm_id
        )

        return APIFailureWrapper({
          content: response,
          errorMessage: 'Erro na listagem da conta',
        })
      },
    },
    'on'
  ),
]

export { performanceRoutes }
