export default {
  Users: {
    email: 'Email',
    home: {
      configurations: 'Configuraciones',
      users: 'Usuarios',
      register: 'Registrar',
    },
    list: {
      user: 'Usuario',
      status: 'Status',
    },
    list_item: {
      active: 'Activo',
      inactive: 'Inactivo',
      cancel: 'Cancelar',
      confirm: 'Confirmar',
      delete_confirm: '¿Estás seguro de que quieres eliminar este usuario?',
      delete_success: 'Usuario eliminado con éxito',
    },
    modal: {
      name: 'Nombre',
      name_placeholder: 'Introduce un nombre',
      email_placeholder: 'Introduce un email',
      profile: 'Perfil',
      profile_placeholder: 'Seleccione un perfil',
      farms: 'Haciendas',
      farms_placeholder: 'Seleccione haciendas del usuario',
      add: 'Agregar',
      edit: 'Editar',
      edit_user: 'Editar usuario',
      register_user: 'Agregar usuario',
      register_success: 'Usuario registrado con éxito',
      exceeded_users_title: 'Límite de usuarios alcanzado',
      exceeded_users_description:
        'Ha alcanzado el límite de {0} usuarios activos en su cuenta. Si necesitas más usuarios en tu cuenta, puedes contratarlos contactando a nuestro equipo de ventas. O desactivar algunos usuarios para que puedas activar un nuevo usuario.',
      comercial_whatsapp: 'Consulta comercial',
    },
    profiles: {
      ADMIN: 'Administrador General',
      CONTA: 'Administrador de Cuenta',
      FARM: 'Ingeniero Agrónomo',
      ASSISTENTE_TECNICO: 'Observador',
      FINANCEIRO: 'Financiero',
      COMERCIAL: 'Comercial',
      ADM_CORP: 'Administrador Corporativo',
      VEND_CORP: 'Vendedor Corporativo',
      VEND_DGF: 'Gestor de Cupones',
      TEC_DGF: 'Gestor Racional',
      CON_REV: 'Consultor de Reventa',
      PESQUISA: 'Investigación',
      description: {
        ADMIN: 'Puede editar, ver y crear todos',
        CONTA: 'Puede editar, ver y crear todos',
        FARM: 'Puede editar todo excepto granjas y lotes.',
        ASSISTENTE_TECNICO: 'Solo lectura',
        FINANCEIRO: 'Financiero',
        COMERCIAL: 'Comercial',
        ADM_CORP: 'Administrador Corporativo',
        VEND_CORP: 'Vendedor Corporativo',
        VEND_DGF: 'Gestor de Cupones',
        TEC_DGF: 'Gestor Racional',
        CON_REV: 'Puede editar, ver y crear, excepto los usuarios',
        PESQUISA:
          'Puede visualizar usuarios, crear y editar programas, siembras, haciendas y lotes',
      },
    },
  },
}
