const crops = [
  {
    id: 1,
    name: 'Soybean',
    messageKey: 'crop.soybean',
  },
  {
    id: 2,
    name: 'Wheat',
    messageKey: 'crop.wheat',
  },
  {
    id: 3,
    name: 'Corn',
    messageKey: 'crop.corn',
  },
]

const cropsByName = crops.reduce((acc, crop) => {
  acc[String(crop.name).toLowerCase()] = crop
  return acc
}, {})

export { crops, cropsByName }
