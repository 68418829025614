import { loginUsers } from '@/modules/auth/mock/authSeeds'
import { jobTitles } from '@/modules/users/mock/usersSeeds'
import { APIFailureWrapper, mockFlag } from '@/utils/mock'

const authAdminRoutes = [
  mockFlag(
    {
      method: 'get',
      url: '/job-titles',
      result: () => {
        return APIFailureWrapper({
          content: jobTitles,
          errorMessage: 'Erro na listagem de títulos de cargos',
        })
      },
    },
    'on'
  ),
  mockFlag(
    {
      method: 'post',
      url: '/logout',
      result: () => {
        const response = { message: 'Logout realizado com sucesso' }

        return APIFailureWrapper({
          content: response,
          errorMessage: 'Erro na listagem de títulos de cargos',
        })
      },
    },
    'on'
  ),
  mockFlag(
    {
      method: 'post',
      url: '/select-account',
      result: ({ requestBody }) => {
        const requestObj = JSON.parse(requestBody)

        let filteredUser = {}
        loginUsers.forEach((user) => {
          user.accounts.forEach((account) => {
            if (account.id == requestObj.account_id) {
              filteredUser = user
            }
          })
        })

        const response = {
          accounts: filteredUser.accounts.map((acc) => {
            return {
              account_type_id: acc.account_type_id,
              account_type_name: acc.account_type_name,
              city_id: acc.city_id,
              country_id: acc.country_id,
              id: acc.id,
              name: acc.name,
              state_id: acc.state_id,
            }
          }),
          is_first_login: filteredUser.is_first_login,
          is_first_login_full_plan: filteredUser.is_first_login_full_plan,
          recovery_password: filteredUser.recovery_password,
          token: filteredUser.token,
          user: {
            id: filteredUser.user.id,
            email: filteredUser.user.email,
            user_profile: {
              id: filteredUser.user.user_profile.id,
              name: filteredUser.user.user_profile.name,
            },
            name: filteredUser.user.name,
            profile_name: filteredUser.user.user_profile.name,
            language: filteredUser.user.language,
            languageAccount: filteredUser.user.languageAccount,
            is_admin: filteredUser.user.is_admin,
            first_login: filteredUser.user.first_login,
            is_qa_mode_enabled: filteredUser.user.is_qa_mode_enabled,
            notes_enabled: filteredUser.user.notes_enabled,
            academy_url: filteredUser.user.academy_url,
            job_title: filteredUser.user.job_title,
            plan: filteredUser.user.plan,
            used_area: filteredUser.user.used_area,
            total_area_account: filteredUser.user.total_area_account,
            account: filteredUser.user.account,
            logo: filteredUser.user.logo,
            ndvi: filteredUser.user.ndvi,
            password_type: filteredUser.user.password_type,
            social_token: filteredUser.user.social_token,
            active: filteredUser.user.active,
          },
        }

        return APIFailureWrapper({
          content: response,
          errorMessage: 'Erro na seleção de conta',
        })
      },
    },
    'on'
  ),
]

export { authAdminRoutes }
