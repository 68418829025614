const performances = [
  {
    total_sacas_produzidas_fazenda: 10,
    total_aplicacoes_fazenda: 0,
    total_semeaduras_fazenda: 1,
    talhao_mais_produtivo: {
      id: 10000,
      talhao: 'Esse cara não está um mock correto',
      produtividade_media: '10',
    },
    talhao_menos_produtivo: {
      id: 10000,
      talhao: 'Esse cara não está um mock correto',
      produtividade_media: '10',
    },
    media_total_sacas_produzidas_fazenda: 10,
    talhoes: [
      {
        fazenda_id: 1,
        talhao_id: 10000,
        talhao_nome: 'Esse cara não está um mock correto',
        area: '50',
        area_total_semeada: '15',
        qnt_sc_media_semeada_talhao: '10',
        qnt_sc_total_semeada_talhao: '20',
        qnt_semeaduras: 1,
        colheitas_realizadas: 1,
        total_producao: '10',
        total_area_colhida: '15',
        ciclo_medio: '200',
        quantidade_total_aplicacoes: 0,
        fungicidas_aplicados: 0,
        porcentagem_colhida: 70,
      },
    ],
    total_cultivares: 1,
  },
  {
    total_sacas_produzidas_fazenda: 20,
    total_aplicacoes_fazenda: 1,
    total_semeaduras_fazenda: 2,
    talhao_mais_produtivo: {
      id: 20000,
      talhao: 'Esse cara não está um mock correto 2',
      produtividade_media: '20',
    },
    talhao_menos_produtivo: {
      id: 20000,
      talhao: 'Esse cara não está um mock correto 2',
      produtividade_media: '20',
    },
    media_total_sacas_produzidas_fazenda: 20,
    talhoes: [
      {
        fazenda_id: 2,
        talhao_id: 20000,
        talhao_nome: 'Esse cara não está um mock correto 2',
        area: '60',
        area_total_semeada: '25',
        qnt_sc_media_semeada_talhao: '20',
        qnt_sc_total_semeada_talhao: '30',
        qnt_semeaduras: 2,
        colheitas_realizadas: 2,
        total_producao: '20',
        total_area_colhida: '25',
        ciclo_medio: '300',
        quantidade_total_aplicacoes: 1,
        fungicidas_aplicados: 1,
        porcentagem_colhida: 80,
      },
    ],
    total_cultivares: 1,
  },
]

export { performances }
