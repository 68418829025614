import {
  varieties,
  soybeanVarieties,
  wheatVarieties,
} from '@/pages/cultivares/mock/varietySeeds.js'
import { SOYBEAN_ID, WHEAT_ID } from '@/utils/crops'
import { mockFlag } from '@/utils/mock'
import { APIFailureWrapper } from '@/utils/mock'

const varietyRoutes = [
  mockFlag(
    {
      method: 'get',
      url: '/sementes/fazenda/:farm_id',
      result: ({ params, queryParams }) => {
        let products = []
        if (queryParams.crop_id == SOYBEAN_ID) {
          products = soybeanVarieties
        } else if (queryParams.crop_id == WHEAT_ID) {
          products = wheatVarieties
        }

        products = products.map((product) => {
          return {
            // added so mock can work
            added: product.added,
            cultivar_id: product.cultivar_id,
            // default response
            asset_owner_id: product.asset_owner_id,
            asset_owner_name: product.asset_owner_name,
            ativo: product.ativo,
            ciclo: product.ciclo,
            ciclo_dias: product.ciclo_dias,
            created_at: product.created_at,
            crop_cycle_id: product.crop_cycle_id,
            crop_id: product.crop_id,
            crop_name: product.crop_name,
            cultivar: product.cultivar,
            deleted_at: product.deleted_at,
            estado_id: product.estado_id,
            fcc: product.fcc,
            growth_habit_id: product.growth_habit_id,
            grupo: product.grupo,
            habito: product.habito,
            icv: product.icv,
            id: product.id,
            inox: product.inox,
            ogm: product.ogm,
            pais_id: product.pais_id,
            populacao_max: product.populacao_max,
            populacao_min: product.populacao_min,
            recomendado: product.recomendado,
            regiao_sojicola: product.regiao_sojicola,
            safra_id: product.safra_id,
            semeadura_fim: product.semeadura_fim,
            semeadura_ini: product.semeadura_ini,
            semente_id: product.semente_id,
            titular: product.titular,
            updated_at: product.updated_at,
            vcu: product.vcu,
          }
        })
        const response =
          params.farm_id == 1
            ? products.slice(0, -1)
            : [products[products.length - 1]]

        return APIFailureWrapper({
          content: response,
          errorMessage: 'Erro ao listar as cultivares',
        })
      },
    },
    'on'
  ),
  mockFlag(
    {
      method: 'get',
      url: '/cultivares',
      result: ({ queryParams }) => {
        const formattedVarieties = varieties.map((variety) => {
          return {
            allowed_farms_to_copy_variety: [],
            ciclo: variety.ciclo,
            conta_id: 'todo',
            crop_id: variety.crop_id,
            cultivar: variety.cultivar,
            fazenda_id: queryParams.fazenda_id,
            grupo: variety.grupo,
            id: variety.id,
            origem: 'todo',
            quantidade: 'todo',
            recommended: variety.recomendado,
            semeadura_fim: variety.semeadura_fim,
            semeadura_ini: variety.semeadura_ini,
            semente_id: variety.semente_id,
            titular: variety.titular,
          }
        })
        const farm1Varieties = formattedVarieties.slice(0, 7).map((variety) => {
          return {
            ...variety,
            fazenda_id: 1,
          }
        })
        const otherFarmsVarieties = formattedVarieties.slice(
          4,
          formattedVarieties.length
        )

        const response =
          queryParams.fazenda_id == 1 ? farm1Varieties : otherFarmsVarieties

        return APIFailureWrapper({
          content: response,
          errorMessage: 'Erro ao listar as cultivares da safra',
        })
      },
    },
    'on'
  ),
  mockFlag(
    {
      method: 'post',
      url: '/varieties',
      result: ({ requestBody }) => {
        const responseObj = JSON.parse(requestBody)
        varieties.forEach((variety) => {
          if (variety.semente_id == responseObj.seedId) variety.added = true
        })
        return APIFailureWrapper({
          content: Number(responseObj.seedId),
          errorMessage: 'Erro ao adicionar a cultivar',
        })
      },
    },
    'on'
  ),
  mockFlag(
    {
      method: 'delete',
      url: '/cultivares/:variety_id/fazenda/:farm_id',
      result: ({ queryParams }) => {
        varieties.forEach((variety) => {
          if (variety.cultivar_id == queryParams.variety_id)
            variety.added = false
        })
        return APIFailureWrapper({
          content: [],
          errorMessage: 'Erro ao deletar a cultivar',
        })
      },
    },
    'on'
  ),
]

export { varietyRoutes }
