import { cropsByName } from '@/services/__mocks__/cropsSeeds'

const assetOwners = [
  { id: 1, name: 'Brevant' },
  { id: 2, name: 'Dow AgroSciences' },
  { id: 3, name: 'Pioneer' },
  { id: 4, name: 'OR Melhoramento de Sementes' },
  { id: 5, name: 'Coodetec' },
  { id: 6, name: 'Biotrigo Genética' },
  { id: 7, name: 'Dono de Milho' },
]

const soybeanVarieties = [
  {
    asset_owner: assetOwners[0].name,
    crop: cropsByName['soybean'],
    cycle: 132,
    epoca_de_semeadura: {
      inicio: new Date().getDate() + 1,
      fim: new Date().getDate() + 90,
    },
    germinative_power: 59,
    gmr: 6.4,
    group: 6.4,
    growth_habit: null,
    id: 1,
    name: '5D6215 IPRO',
    ogm: 'Sim',
    max_population: 300,
    min_population: 200,
    seed_vigor: 76,

    ativo: null,
    added: false,
    asset_owner_id: assetOwners[0].id,
    asset_owner_name: assetOwners[0].name,
    ciclo: null,
    ciclo_dias: 132,
    created_at: '2022-08-04 00:00:00',
    crop_cycle_id: null,
    crop_id: cropsByName['soybean'].id,
    crop_name: cropsByName['soybean'].name,
    cultivar: '5D6215 IPRO',
    cultivar_id: 1,
    deleted_at: null,
    estado_id: null,
    fcc: null,
    growth_habit_id: 1,
    icv: null,
    inox: 0,
    grupo: '6.4',
    habito: null,
    pais_id: 1,
    populacao_max: 300,
    populacao_min: 200,
    recomendado: 0,
    regiao_sojicola: '101',
    safra_id: 8,
    semeadura_fim: null,
    semeadura_ini: null,
    semente_id: 1,
    titular: null,
    updated_at: '2024-03-19 18:49:30',
    vcu: null,
  },
  {
    asset_owner: assetOwners[1].name,
    crop: cropsByName['soybean'],
    cycle: 136,
    epoca_de_semeadura: {
      inicio: new Date().getDate() + 1,
      fim: new Date().getDate() + 90,
    },
    id: 2,
    germinative_power: 80,
    growth_habit: null,
    gmr: 6.3,
    group: 6.3,
    max_population: 300,
    min_population: 220,
    name: '5D634 RR',
    ogm: 'Sim',
    seed_vigor: 97,

    added: false,
    asset_owner_id: assetOwners[1].id,
    asset_owner_name: assetOwners[1].name,
    ativo: null,
    regiao_sojicola: '101',
    ciclo: null,
    ciclo_dias: 136,
    created_at: '2019-10-10 12:12:01',
    crop_cycle_id: null,
    crop_name: cropsByName['soybean'].name,
    crop_id: cropsByName['soybean'].id,
    cultivar: '5D634 RR',
    cultivar_id: 2,
    deleted_at: null,
    estado_id: null,
    fcc: null,
    growth_habit_id: 2,
    grupo: '6.3',
    habito: null,
    icv: null,
    inox: 0,
    pais_id: 1,
    populacao_max: 300,
    populacao_min: 220,
    recomendado: 1,
    safra_id: 8,
    semeadura_fim: '2024-12-31',
    semeadura_ini: '2024-09-20',
    semente_id: 2,
    titular: null,
    updated_at: '2024-03-19 18:49:30',
    vcu: null,
  },
  {
    asset_owner: assetOwners[2].name,
    crop: cropsByName['soybean'],
    cycle: 123,
    epoca_de_semeadura: {
      inicio: new Date().getDate() + 1,
      fim: new Date().getDate() + 90,
    },
    gmr: 5.0,
    id: 3,
    ogm: 'Sim',
    germinative_power: 34,
    group: 5.0,
    growth_habit: null,
    max_population: 300,
    min_population: 280,
    name: '95R30 IPRO',
    seed_vigor: 53,

    added: false,
    asset_owner_id: assetOwners[2].id,
    asset_owner_name: assetOwners[2].name,
    ativo: null,
    created_at: null,
    crop_cycle_id: null,
    crop_id: cropsByName['soybean'].id,
    crop_name: cropsByName['soybean'].name,
    ciclo: null,
    ciclo_dias: 123,
    cultivar: '95R30 IPRO',
    cultivar_id: 3,
    deleted_at: null,
    estado_id: null,
    fcc: null,
    growth_habit_id: 2,
    grupo: '5.0',
    habito: null,
    icv: null,
    inox: 0,
    pais_id: 1,
    populacao_max: 300,
    populacao_min: 280,
    recomendado: 1,
    regiao_sojicola: '101',
    safra_id: 8,
    semeadura_fim: '2024-12-15',
    semeadura_ini: '2024-10-01',
    semente_id: 3,
    titular: null,
    updated_at: '2024-03-19 18:49:30',
    vcu: null,
  },
  {
    asset_owner: assetOwners[2].name,
    crop: cropsByName['soybean'],
    cycle: 122,
    epoca_de_semeadura: {
      inicio: new Date().getDate() + 1,
      fim: new Date().getDate() + 90,
    },
    germinative_power: 99,
    gmr: 5.4,
    group: 5.4,
    growth_habit: null,
    id: 4,
    ogm: 'Sim',
    max_population: 350,
    min_population: 250,
    name: '95R40 IPRO',
    seed_vigor: 99,

    added: true,
    ativo: null,
    asset_owner_id: assetOwners[2].id,
    asset_owner_name: assetOwners[2].name,
    ciclo: null,
    ciclo_dias: 122,
    created_at: '2020-10-01 12:43:56',
    crop_cycle_id: null,
    crop_id: cropsByName['soybean'].id,
    crop_name: cropsByName['soybean'].name,
    cultivar: '95R40 IPRO',
    cultivar_id: 4,
    deleted_at: null,
    fcc: null,
    growth_habit_id: 1,
    grupo: '5.4',
    habito: null,
    icv: null,
    inox: 0,
    safra_id: 8,
    semente_id: 4,
    estado_id: null,
    pais_id: 1,
    recomendado: 0,
    populacao_max: 350,
    populacao_min: 250,
    regiao_sojicola: '101',
    semeadura_fim: null,
    semeadura_ini: null,
    titular: null,
    updated_at: '2024-03-19 18:49:30',
    vcu: null,
  },
  {
    asset_owner: assetOwners[2].name,
    crop: cropsByName['soybean'],
    cycle: 120,
    epoca_de_semeadura: {
      inicio: new Date().getDate() + 1,
      fim: new Date().getDate() + 90,
    },
    germinative_power: 74,
    gmr: 5.3,
    growth_habit: null,
    group: 5.3,
    id: 5,
    max_population: 390,
    min_population: 320,
    name: '95R51',
    ogm: 'Sim',
    seed_vigor: 11,

    added: false,
    ativo: null,
    asset_owner_id: assetOwners[2].id,
    asset_owner_name: assetOwners[2].name,
    ciclo: null,
    ciclo_dias: 120,
    crop_cycle_id: null,
    crop_id: cropsByName['soybean'].id,
    crop_name: cropsByName['soybean'].name,
    created_at: '2020-11-16 13:22:55',
    cultivar: '95R51',
    cultivar_id: 5,
    deleted_at: null,
    estado_id: null,
    fcc: null,
    growth_habit_id: 2,
    grupo: '5.3',
    habito: null,
    icv: null,
    inox: 0,
    pais_id: 1,
    populacao_max: 390,
    populacao_min: 320,
    recomendado: 0,
    regiao_sojicola: '101',
    safra_id: 8,
    semeadura_fim: null,
    semeadura_ini: null,
    semente_id: 5,
    titular: null,
    updated_at: '2024-03-19 18:49:30',
    vcu: null,
  },
]

const wheatVarieties = [
  {
    asset_owner: assetOwners[3].name,
    crop: cropsByName['wheat'],
    cycle: 125,
    id: 6,
    epoca_de_semeadura: {
      inicio: new Date().getDate() + 1,
      fim: new Date().getDate() + 90,
    },
    germinative_power: 1,
    gmr: null,
    growth_habit: null,
    group: null,
    max_population: 350,
    min_population: 330,
    name: 'Ametista',
    ogm: 'Não',
    seed_vigor: 1,

    added: false,
    asset_owner_id: assetOwners[3].id,
    asset_owner_name: assetOwners[3].name,
    ativo: null,
    ciclo_dias: 125,
    ciclo: null,
    created_at: '2021-09-09 00:00:00',
    crop_id: cropsByName['wheat'].id,
    crop_name: cropsByName['wheat'].name,
    crop_cycle_id: 3,
    cultivar: 'Ametista',
    cultivar_id: 6,
    deleted_at: null,
    estado_id: 21,
    fcc: null,
    grupo: null,
    habito: null,
    icv: null,
    inox: 0,
    growth_habit_id: null,
    pais_id: 1,
    populacao_max: 350,
    populacao_min: 330,
    recomendado: 1,
    regiao_sojicola: null,
    safra_id: 8,
    semeadura_fim: '2024-09-30',
    semeadura_ini: '2024-01-01',
    semente_id: 6,
    titular: null,
    updated_at: '2024-03-19 18:49:30',
    vcu: '1',
  },
  {
    asset_owner: assetOwners[4].name,
    crop: cropsByName['wheat'],
    cycle: 124,
    epoca_de_semeadura: {
      inicio: new Date().getDate() + 1,
      fim: new Date().getDate() + 90,
    },
    germinative_power: 12,
    gmr: null,
    growth_habit: null,
    group: null,
    id: 7,
    max_population: 510,
    min_population: 360,
    name: 'Anak',
    ogm: 'Não',
    seed_vigor: 26,

    added: true,
    ativo: null,
    asset_owner_id: assetOwners[4].id,
    asset_owner_name: assetOwners[4].name,
    ciclo: null,
    ciclo_dias: 124,
    created_at: '2021-09-09 00:00:00',
    crop_cycle_id: 2,
    crop_id: cropsByName['wheat'].id,
    crop_name: cropsByName['wheat'].name,
    cultivar: 'Anak',
    cultivar_id: 7,
    deleted_at: null,
    estado_id: 21,
    fcc: null,
    growth_habit_id: null,
    grupo: null,
    habito: null,
    icv: null,
    inox: 0,
    pais_id: 1,
    populacao_max: 510,
    populacao_min: 360,
    recomendado: 1,
    regiao_sojicola: null,
    safra_id: 8,
    semeadura_fim: '2024-09-30',
    semeadura_ini: '2024-01-01',
    semente_id: 7,
    titular: null,
    updated_at: '2024-03-19 18:49:30',
    vcu: '1',
  },
  {
    asset_owner: assetOwners[5].name,
    crop: cropsByName['wheat'],
    cycle: 148,
    epoca_de_semeadura: {
      inicio: new Date().getDate() + 1,
      fim: new Date().getDate() + 90,
    },
    germinative_power: 93,
    gmr: null,
    growth_habit: null,
    group: null,
    id: 8,
    max_population: 250,
    min_population: 275,
    name: 'Biotrigo Sentinela',
    ogm: 'Não',
    seed_vigor: 78,

    added: false,
    asset_owner_id: assetOwners[5].id,
    asset_owner_name: assetOwners[5].name,
    ativo: null,
    ciclo: null,
    ciclo_dias: 148,
    created_at: '2023-07-27 00:00:00',
    crop_cycle_id: 6,
    crop_id: cropsByName['wheat'].id,
    crop_name: cropsByName['wheat'].name,
    cultivar: 'Biotrigo Sentinela',
    cultivar_id: 8,
    deleted_at: null,
    estado_id: 21,
    growth_habit_id: null,
    grupo: null,
    fcc: null,
    habito: null,
    icv: null,
    inox: 0,
    pais_id: 1,
    populacao_max: 275,
    populacao_min: 250,
    regiao_sojicola: null,
    recomendado: 1,
    safra_id: 8,
    semeadura_fim: '2024-09-30',
    semeadura_ini: '2024-01-01',
    semente_id: 8,
    titular: null,
    updated_at: '2024-03-19 18:49:30',
    vcu: '1',
  },
  {
    asset_owner: assetOwners[5].name,
    crop: cropsByName['wheat'],
    cycle: 117,
    epoca_de_semeadura: {
      inicio: new Date().getDate() + 1,
      fim: new Date().getDate() + 90,
    },
    germinative_power: 75,
    gmr: null,
    growth_habit: null,
    group: null,
    id: 9,
    max_population: 330,
    min_population: 350,
    name: 'Biotrigo Talismã',
    ogm: 'Não',
    seed_vigor: 42,

    added: false,
    ativo: null,
    asset_owner_id: assetOwners[5].id,
    asset_owner_name: assetOwners[5].name,
    ciclo: null,
    ciclo_dias: 117,
    created_at: '2023-07-27 00:00:00',
    crop_cycle_id: 2,
    crop_id: cropsByName['wheat'].id,
    crop_name: cropsByName['wheat'].name,
    cultivar: 'Biotrigo Talismã',
    cultivar_id: 9,
    deleted_at: null,
    growth_habit_id: null,
    grupo: null,
    estado_id: 21,
    fcc: null,
    habito: null,
    icv: null,
    inox: 0,
    pais_id: 1,
    populacao_max: 350,
    populacao_min: 330,
    recomendado: 1,
    regiao_sojicola: null,
    safra_id: 8,
    semeadura_fim: '2024-09-30',
    semeadura_ini: '2024-01-01',
    semente_id: 2063,
    titular: null,
    updated_at: '2024-03-19 18:49:30',
    vcu: '1',
  },
  {
    asset_owner: assetOwners[5].name,
    crop: cropsByName['wheat'],
    cycle: 127,
    epoca_de_semeadura: {
      inicio: new Date().getDate() + 1,
      fim: new Date().getDate() + 90,
    },
    id: 10,
    germinative_power: 66,
    gmr: null,
    growth_habit: null,
    group: null,
    max_population: 330,
    min_population: 300,
    name: 'Biotrigo Titan',
    ogm: 'Não',
    seed_vigor: 88,

    added: false,
    asset_owner_id: assetOwners[5].id,
    asset_owner_name: assetOwners[5].name,
    ativo: null,
    ciclo: null,
    ciclo_dias: 127,
    created_at: '2023-07-27 00:00:00',
    crop_cycle_id: cropsByName['wheat'].id,
    crop_id: 2,
    crop_name: cropsByName['wheat'].name,
    cultivar: 'Biotrigo Titan',
    cultivar_id: 10,
    deleted_at: null,
    estado_id: 21,
    fcc: null,
    grupo: null,
    habito: null,
    icv: null,
    inox: 0,
    growth_habit_id: null,
    pais_id: 1,
    populacao_max: 330,
    populacao_min: 300,
    recomendado: 1,
    regiao_sojicola: null,
    safra_id: 8,
    semente_id: 10,
    semeadura_fim: '2024-09-30',
    semeadura_ini: '2024-01-01',
    titular: null,
    updated_at: '2024-03-19 18:49:30',
    vcu: '1',
  },
]

const cornVarieties = [
  {
    asset_owner: assetOwners[6].name,
    crop: cropsByName['corn'],
    cycle: 120,
    epoca_de_semeadura: {
      inicio: new Date().getDate() + 1,
      fim: new Date().getDate() + 90,
    },
    id: 11,
    germinative_power: 56,
    gmr: null,
    growth_habit: null,
    group: null,
    max_population: 330,
    min_population: 300,
    name: 'Milho 1',
    ogm: 'Não',
    seed_vigor: 95,
  },
  {
    asset_owner: assetOwners[6].name,
    crop: cropsByName['corn'],
    cycle: 120,
    epoca_de_semeadura: {
      inicio: new Date().getDate() + 1,
      fim: new Date().getDate() + 90,
    },
    id: 12,
    germinative_power: 77,
    gmr: null,
    growth_habit: null,
    group: null,
    max_population: 330,
    min_population: 300,
    name: 'Milho 2',
    ogm: 'Não',
    seed_vigor: 62,
  },
  {
    asset_owner: assetOwners[6].name,
    crop: cropsByName['corn'],
    cycle: 120,
    epoca_de_semeadura: {
      inicio: new Date().getDate() + 1,
      fim: new Date().getDate() + 90,
    },
    id: 13,
    germinative_power: 7,
    gmr: null,
    growth_habit: null,
    group: null,
    max_population: 330,
    min_population: 300,
    name: 'Milho 3',
    ogm: 'Não',
    seed_vigor: 98,
  },
  {
    asset_owner: assetOwners[6].name,
    crop: cropsByName['corn'],
    cycle: 120,
    epoca_de_semeadura: {
      inicio: new Date().getDate() + 1,
      fim: new Date().getDate() + 90,
    },
    id: 14,
    germinative_power: 38,
    gmr: null,
    growth_habit: null,
    group: null,
    max_population: 330,
    min_population: 300,
    name: 'Milho 4',
    ogm: 'Não',
    seed_vigor: 55,
  },
  {
    id: 15,
    name: 'Milho 5',
    crop: cropsByName['corn'],
    cycle: 120,
    epoca_de_semeadura: {
      inicio: new Date().getDate() + 1,
      fim: new Date().getDate() + 90,
    },
    gmr: null,
    asset_owner: assetOwners[6].name,
    germinative_power: 27,
    growth_habit: null,
    group: null,
    max_population: 330,
    min_population: 300,
    ogm: 'Não',
    seed_vigor: 39,
  },
]

const varieties = [...soybeanVarieties, ...wheatVarieties]

export { cornVarieties, soybeanVarieties, varieties, wheatVarieties }
