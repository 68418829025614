import { APIFailureWrapper, mockFlag } from '@/utils/mock'
import { loginUsers } from '@/modules/auth/mock/authSeeds'

const authAdminNoAuthRoutes = [
  mockFlag(
    {
      method: 'post',
      url: '/login',
      result: ({ requestBody }) => {
        const requestObj = JSON.parse(requestBody)
        const account = loginUsers.find(
          (account) => account.user.email == requestObj.email
        )

        if (!account) {
          return APIFailureWrapper({
            content: null,
            errorMessage: 'Erro no login',
            failureRate: 100,
          })
        }

        const response = {
          accounts: account.accounts.map((acc) => {
            return {
              account_type_id: acc.account_type_id,
              account_type_name: acc.account_type_name,
              city_id: acc.city_id,
              country_id: acc.country_id,
              id: acc.id,
              name: acc.name,
              state_id: acc.state_id,
            }
          }),
          is_first_login: account.is_first_login,
          is_first_login_full_plan: account.is_first_login_full_plan,
          recovery_password: account.recovery_password,
          token: account.token,
          user: {
            id: account.user.id,
            email: account.user.email,
            user_profile: {
              id: account.user.user_profile.id,
              name: account.user.user_profile.name,
            },
            name: account.user.name,
            profile_name: account.user.profile_name,
            language: account.user.language,
            languageAccount: account.user.languageAccount,
            is_admin: account.user.is_admin,
            first_login: account.user.first_login,
            is_qa_mode_enabled: account.user.is_qa_mode_enabled,
            notes_enabled: account.user.notes_enabled,
            academy_url: account.user.academy_url,
            job_title: account.user.job_title,
            plan: account.user.plan,
            used_area: account.user.used_area,
            total_area_account: account.user.total_area_account,
            account: account.user.account,
            logo: account.user.logo,
            ndvi: account.user.ndvi,
            password_type: account.user.password_type,
            social_token: account.user.social_token,
            active: account.user.active,
          },
        }
        return APIFailureWrapper({
          content: response,
          errorMessage: 'Erro no login',
        })
      },
    },
    'on'
  ),
]

export { authAdminNoAuthRoutes }
