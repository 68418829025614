import { farms } from '@/modules/farm/mock/farmSeeds'
import { plans } from '@/services/__mocks__/plansSeeds'

const jobTitles = [
  {
    id: 1,
    name: 'farm_administrator',
  },
  {
    id: 2,
    name: 'agricultural_technical_consultancy',
  },
  {
    id: 3,
    name: 'manager',
  },
  {
    id: 4,
    name: 'agricultural_engineering_consultancy',
  },
  {
    id: 5,
    name: 'machine_operator',
  },
  {
    id: 6,
    name: 'operation_supervisor',
  },
  {
    id: 7,
    name: 'dealer',
  },
  {
    id: 8,
    name: 'researcher',
  },
  {
    id: 9,
    name: 'student',
  },
  {
    id: 10,
    name: 'other',
  },
  {
    id: 11,
    name: 'manager_admin_digifarmz',
  },
  {
    id: 12,
    name: 'manager_admin_digifarmz_marketing',
  },
  {
    id: 13,
    name: 'manager_admin_digifarmz_sales',
  },
  {
    id: 14,
    name: 'manager_admin_digifarmz_operations',
  },
]

const profiles = [
  {
    id: 2,
    name: 'Administrador de Conta',
    uid: 'CONTA',
  },
  {
    id: 3,
    name: 'Eng. Agronomo',
    uid: 'FARM',
  },
  {
    id: 4,
    name: 'Observador',
    uid: 'ASSISTENTE_TECNICO',
  },
  {
    id: 14,
    name: 'Consultor de Revenda',
    uid: 'CON_REV',
  },
  {
    id: 15,
    name: 'Pesquisa',
    uid: 'PESQUISA',
  },
]

const profileToJobTitle = {
  2: jobTitles[0],
  3: jobTitles[3],
  4: jobTitles[9],
  14: jobTitles[6],
  15: jobTitles[7],
}

const account1Farms = farms.filter((farm) => farm.account_id == 1)
const account2Farms = farms.filter((farm) => farm.account_id == 2)
const account4Farms = farms.filter((farm) => farm.account_id == 4)
const account5Farms = farms.filter((farm) => farm.account_id == 5)
const account6Farms = farms.filter((farm) => farm.account_id == 6)

const users = [
  {
    account_id: '1',
    active: true,
    email: 'full+plan@digifarmz.com',
    farms: account1Farms,
    id: 1,
    name: 'Dev 1',
    profile: profiles[0],
    language: 'pt',
    is_admin: false,
    first_login: 'M',
    job_title: jobTitles[0],
    plan: plans[2],
  },
  {
    account_id: '1',
    active: true,
    email: 'engenheiro+full+plan@digifarmz.com',
    farms: [account1Farms[0], account1Farms[1]],
    id: 2,
    name: 'Engenheiro',
    profile: profiles[1],
    language: 'pt',
    is_admin: false,
    first_login: 'M',
    job_title: jobTitles[3],
    plan: plans[2],
  },
  {
    account_id: '1',
    active: true,
    email: 'observador+full+plan@digifarmz.com',
    farms: [account1Farms[1], account1Farms[2]],
    id: 3,
    name: 'Observador',
    profile: profiles[2],
    language: 'pt',
    is_admin: false,
    first_login: 'M',
    job_title: jobTitles[9],
    plan: plans[2],
  },
  {
    account_id: '1',
    active: true,
    email: 'revendedor+full+plan@digifarmz.com',
    farms: [account1Farms[1]],
    id: 4,
    name: 'Revendedor',
    profile: profiles[3],
    language: 'pt',
    is_admin: false,
    first_login: 'M',
    job_title: jobTitles[6],
    plan: plans[2],
  },
  {
    account_id: '1',
    active: true,
    email: 'pesquisa+full+plan@digifarmz.com',
    farms: [account1Farms[2]],
    id: 5,
    name: 'Pesquisador',
    profile: profiles[4],
    language: 'pt',
    is_admin: false,
    first_login: 'M',
    job_title: jobTitles[7],
    plan: plans[2],
  },
  {
    account_id: '2',
    active: true,
    email: 'dev@digifarmz.com',
    farms: account2Farms,
    id: 6,
    name: 'Dev 2',
    profile: profiles[0],
    language: 'pt',
    is_admin: false,
    first_login: 'Y',
    job_title: null,
    plan: plans[2],
  },
  {
    account_id: '4',
    active: true,
    email: 'pro+plan@digifarmz.com',
    farms: account4Farms,
    id: 7,
    name: 'Pro',
    profile: profiles[0],
    language: 'pt',
    is_admin: false,
    first_login: 'M',
    job_title: jobTitles[0],
    plan: plans[3],
  },
  {
    account_id: '5',
    active: true,
    email: 'light+plan@digifarmz.com',
    farms: account5Farms,
    id: 8,
    name: 'Light',
    profile: profiles[0],
    language: 'pt',
    is_admin: false,
    first_login: 'M',
    job_title: jobTitles[0],
    plan: plans[0],
  },
  {
    account_id: '6',
    active: true,
    email: 'freemium+plan@digifarmz.com',
    farms: account6Farms,
    id: 9,
    name: 'Freemium',
    profile: profiles[0],
    language: 'pt',
    is_admin: false,
    first_login: 'M',
    job_title: jobTitles[0],
    plan: plans[4],
  },
]

export { jobTitles, profiles, profileToJobTitle, users }
