export default {
  Components: {
    input: {
      numbers_only: 'Somente números',
    },
    filter_select: {
      results_not_found: 'Resultado não encontrado',
    },
    date_picker: {
      cancel: 'Cancelar',
      date_not_recommended: 'Data não recomendada para sua região',
    },
    select_dropdown: {
      clean: 'Limpar',
      apply: 'Aplicar',
      results_not_found: 'Nenhum resultado foi encontrado',
    },
  },
}
