import { APIFailureWrapper, mockFlag } from '@/utils/mock'
import { accounts, loginUsers } from '@/modules/auth/mock/authSeeds'
import { getCurrentUser, setCurrentAccount } from '@/services/__mocks__/helpers'
import { users } from '@/modules/users/mock/usersSeeds'

let currentUser = {}

const menuRoutes = [
  mockFlag(
    {
      method: 'get',
      url: '/freemium',
      result: () => {
        return APIFailureWrapper({
          content: {
            limit: 5,
            used: 0,
          },
          errorMessage: 'Erro na busca de contagem do freemium',
        })
      },
    },
    'on'
  ),
  mockFlag(
    {
      method: 'get',
      url: '/menu/contas',
      result: () => {
        const currentUser = getCurrentUser()
        let response = { contas: {} }
        currentUser.accounts.forEach((account) => {
          response.contas[String(account.id)] = account.name
        })

        return APIFailureWrapper({
          content: response,
          errorMessage: 'Erro na listagem de contas',
        })
      },
    },
    'on'
  ),
  mockFlag(
    {
      method: 'get',
      url: '/menu/dados-usuario',
      result: () => {
        currentUser = getCurrentUser()
        const loginUser = loginUsers.find(
          (user) => user.user.id == currentUser.user.id
        )

        const currentAccount = accounts.find(
          (account) => account.id == loginUser.accounts[0].id
        )
        setCurrentAccount(currentAccount)

        const maxActiveUsers = currentAccount.max_users
        const activeUsersQtt = users.filter(
          (user) => user.account_id == currentAccount.id && !!user.active
        ).length

        const response = {
          id: loginUser.user.id,
          email: loginUser.user.email,
          user_profile: {
            id: loginUser.user.user_profile.id,
            name: loginUser.user.user_profile.name,
          },
          name: loginUser.user.name,
          profile_name: loginUser.user.profile_name,
          language: loginUser.user.language,
          languageAccount: loginUser.user.languageAccount,
          is_admin: loginUser.user.is_admin,
          first_login: loginUser.user.first_login,
          is_qa_mode_enabled: loginUser.user.is_qa_mode_enabled,
          notes_enabled: loginUser.user.notes_enabled,
          academy_url: loginUser.user.academy_url,
          job_title: loginUser.user.job_title,
          plan: loginUser.user.plan,
          used_area: loginUser.user.used_area,
          total_area_account: loginUser.user.total_area_account,
          logo: loginUser.user.logo,
          ndvi: loginUser.user.ndvi,
          account: {
            id: loginUser.accounts[0].id,
            name: loginUser.accounts[0].name,
            country_id: loginUser.accounts[0].country_id,
            state_id: loginUser.accounts[0].state_id,
            city_id: loginUser.accounts[0].city_id,
            account_type_id: loginUser.accounts[0].account_type_id,
            account_type_name: loginUser.accounts[0].account_type_name,
          },
          remaining_users: maxActiveUsers - activeUsersQtt,
          total_users: maxActiveUsers,
        }

        return APIFailureWrapper({
          content: response,
          errorMessage: 'Erro na listagem de dados do usuário',
        })
      },
    },
    'on'
  ),
  mockFlag(
    {
      method: 'put',
      url: '/menu/alterar-conta',
      result: ({ requestBody }) => {
        const requestObj = JSON.parse(requestBody)

        let filteredUser = {}
        loginUsers.forEach((user) => {
          user.accounts.forEach((account) => {
            if (account.id == requestObj.id) {
              filteredUser = user
            }
          })
        })

        currentUser = filteredUser

        return APIFailureWrapper({
          content: null,
          errorMessage: 'Erro na alteração da conta',
        })
      },
    },
    'on'
  ),
]

export { menuRoutes }
