import { fields } from '@/modules/plot/mock/fieldsSeeds'
import { varieties } from '@/pages/cultivares/mock/varietySeeds'
import { getCustomDate } from '@/utils/time'

function createSowing(params) {
  const currentFarmId = fields.find(
    (field) => field.id == params.talhao_id
  ).fazenda_id
  const currentVariety = varieties.find(
    (variety) => variety.semente_id == params.semente_id
  )
  const currentField = fields.find((field) => field.id == params.talhao_id)
  const response = {
    talhao_id: currentField.id,
    area: params.area,
    data_emergencia: params.data_emergencia,
    data_semeadura: params.data_semeadura,
    fazenda_id: currentFarmId,
    id: sowings.length + 1,
    quantidade: params.quantidade,
    origem: params.origem,
    seeder_system_id: params.seeder_system_id,
    seed_class_id: params.seed_class_id,
    estimated_cycle: params.estimated_cycle,
    germinative_power: params.germinative_power,
    seed_vigor: params.seed_vigor,
    line_spacing: params.line_spacing,
    population_lines: params.population_lines,
    operating_speed: params.operating_speed,
    seed_treatment: params.seed_treatment,
    inoculant: params.inoculant,
    conta_id: 1,
    cultivar_id: currentVariety.cultivar_id,
    updated_at: getCustomDate(0, true),
    created_at: getCustomDate(0, true),
  }
  const newSowing = {
    ...response,
    colheita: null,
    crop_id: currentVariety.crop_id,
    cultivar: currentVariety.cultivar,
    data_ideal_proxima_aplicacao: null,
    estadio_atual: {
      id: null,
      message_key: null,
      name: null,
    },
    plot_enabled: true,
    programa: false,
    ready_to_harvest: true,
    semente_id: params.semente_id,
    talhao: currentField.nome,
    total_aplicacoes: 3,
    total_execucoes: 0,
    total_sprayings: 0,
  }
  sowings.push(newSowing)
  return response
}

const seederSystems = [
  {
    id: 1,
    message_key: 'seeder_system.perforated_disk',
    messageKey: 'seeder_system.perforated_disk',
  },
  {
    id: 2,
    message_key: 'seeder_system.endless_thread',
    messageKey: 'seeder_system.endless_thread',
  },
  {
    id: 3,
    message_key: 'seeder_system.pinching_fingers',
    messageKey: 'seeder_system.pinching_fingers',
  },
  {
    id: 4,
    message_key: 'seeder_system.perforated_belt',
    messageKey: 'seeder_system.perforated_belt',
  },
]

const seedClasses = [
  {
    id: 1,
    message_key: 'seed_class.certified',
    messageKey: 'seed_class.certified',
  },
  {
    id: 2,
    message_key: 'seed_class.inspected',
    messageKey: 'seed_class.inspected',
  },
  {
    id: 3,
    message_key: 'seed_class.selected',
    messageKey: 'seed_class.inspected',
  },
]

const sowings = [
  {
    id: 1,
    active: true,
    area: fields.length && fields[0].area / 3,
    colheita: null,
    crop_id: varieties[0].crop.id,
    cultivar: varieties[0].name,
    cultivar_id: varieties[0].id,
    data_emergencia: getCustomDate(-390, true),
    data_ideal_proxima_aplicacao: null,
    data_semeadura: getCustomDate(-400, true),
    estadio_atual: {
      id: 25,
      message_key: 'growth_stage.soybean.r9',
      name: 'R9',
    },
    estimated_cycle: varieties[0].cycle + 5,
    germinative_power: varieties[0].germinative_power,
    inoculant: false,
    line_spacing: 12.4,
    operating_speed: 10,
    origem: 'comprado',
    plot_enabled: fields[0].enabled,
    population_lines: 15.4,
    programa: true,
    quantidade: fields.length && fields[0].area / 3,
    ready_to_harvest: true,
    seed_class_id: seedClasses[0].id,
    seed_treatment: true,
    seed_vigor: varieties[0].seed_vigor,
    seeder_system_id: seederSystems[0].id,
    semente_id: 1,
    talhao: fields.length && fields[0].nome,
    talhao_id: fields.length && fields[0].id,
    total_aplicacoes: 0,
    total_execucoes: 0,
    total_sprayings: 0,
  },
  {
    id: 2,
    active: true,
    area: fields.length && fields[0].area / 3,
    colheita: null,
    crop_id: varieties[8].crop.id,
    cultivar: varieties[8].name,
    cultivar_id: varieties[8].id,
    data_emergencia: getCustomDate(5, true),
    data_ideal_proxima_aplicacao: null,
    data_semeadura: getCustomDate(1, true),
    estadio_atual: {
      id: 23,
      message_key: 'growth_stage.soybean.r7',
      name: 'R7',
    },
    estimated_cycle: varieties[8].cycle + 10,
    germinative_power: varieties[8].germinative_power,
    inoculant: false,
    line_spacing: 21.3,
    operating_speed: 8.5,
    origem: 'comprado',
    plot_enabled: fields[0].enabled,
    population_lines: 8.4,
    programa: true,
    quantidade: fields.length && fields[0].area / 3,
    ready_to_harvest: false,
    seed_class_id: seedClasses[1].id,
    seed_treatment: true,
    seed_vigor: varieties[8].seed_vigor,
    seeder_system_id: seederSystems[1].id,
    semente_id: 2,
    talhao: fields.length && fields[0].nome,
    talhao_id: fields.length && fields[0].id,
    total_aplicacoes: 1,
    total_execucoes: 1,
    total_sprayings: 1,
  },
  {
    id: 3,
    active: true,
    area: fields.length && fields[1].area / 10,
    colheita: null,
    crop_id: varieties[2].crop.id,
    cultivar: varieties[2].name,
    cultivar_id: varieties[2].id,
    data_emergencia: getCustomDate(20, true),
    data_ideal_proxima_aplicacao: null,
    data_semeadura: getCustomDate(3, true),
    estadio_atual: {
      id: 25,
      message_key: 'growth_stage.soybean.r9',
      name: 'R9',
    },
    estimated_cycle: varieties[2].cycle + 8,
    germinative_power: varieties[2].germinative_power,
    inoculant: false,
    line_spacing: 4.2,
    operating_speed: 27.9,
    origem: 'comprado',
    plot_enabled: fields[1].enabled,
    population_lines: 5.5,
    programa: false,
    quantidade: fields.length && fields[1].area / 10,
    ready_to_harvest: true,
    seed_class_id: seedClasses[0].id,
    seed_treatment: true,
    seed_vigor: varieties[2].seed_vigor,
    seeder_system_id: seederSystems[0].id,
    semente_id: 1,
    talhao: fields.length && fields[1].nome,
    talhao_id: fields.length && fields[1].id,
    total_aplicacoes: 0,
    total_execucoes: 0,
    total_sprayings: null,
  },
  {
    id: 4,
    active: true,
    area: fields.length && fields[2].area / 5,
    colheita: null,
    crop_id: varieties[3].crop.id,
    cultivar: varieties[3].name,
    cultivar_id: varieties[3].id,
    data_emergencia: getCustomDate(10, true),
    data_ideal_proxima_aplicacao: getCustomDate(32, true),
    data_semeadura: getCustomDate(2, true),
    estadio_atual: {
      id: 25,
      message_key: 'growth_stage.soybean.r9',
      name: 'R9',
    },
    estimated_cycle: varieties[3].cycle + 3,
    germinative_power: varieties[3].germinative_power,
    inoculant: false,
    line_spacing: 37.4,
    operating_speed: 2.2,
    origem: 'comprado',
    plot_enabled: fields[2].enabled,
    population_lines: 48.1,
    programa: true,
    quantidade: fields.length && fields[2].area / 5,
    ready_to_harvest: true,
    seed_class_id: seedClasses[2].id,
    seed_treatment: true,
    seed_vigor: varieties[3].seed_vigor,
    seeder_system_id: seederSystems[2].id,
    semente_id: 3,
    talhao: fields.length && fields[2].nome,
    talhao_id: fields.length && fields[2].id,
    total_aplicacoes: 2,
    total_execucoes: 3,
    total_sprayings: 2,
  },
  {
    id: 5,
    active: true,
    area: fields.length && fields[3].area / 7,
    colheita: null,
    crop_id: varieties[4].crop.id,
    cultivar: varieties[4].name,
    cultivar_id: varieties[4].id,
    data_emergencia: getCustomDate(15, true),
    data_ideal_proxima_aplicacao: getCustomDate(18, true),
    data_semeadura: getCustomDate(1, true),
    estadio_atual: {
      id: 23,
      message_key: 'growth_stage.soybean.r7',
      name: 'R7',
    },
    estimated_cycle: varieties[4].cycle + 5,
    germinative_power: varieties[4].germinative_power,
    inoculant: false,
    line_spacing: 4,
    operating_speed: 8,
    origem: 'comprado',
    plot_enabled: fields[3].enabled,
    population_lines: 3,
    programa: true,
    quantidade: fields.length && fields[3].area / 7,
    ready_to_harvest: false,
    seed_class_id: seedClasses[0].id,
    seed_treatment: true,
    seed_vigor: varieties[4].seed_vigor,
    seeder_system_id: seederSystems[0].id,
    semente_id: 1,
    talhao: fields.length && fields[3].nome,
    talhao_id: fields.length && fields[3].id,
    total_aplicacoes: 0,
    total_execucoes: 1,
    total_sprayings: 0,
  },
  {
    id: 6,
    active: true,
    area: fields.length && fields[4].area / 3,
    colheita: null,
    crop_id: varieties[5].crop.id,
    cultivar: varieties[5].name,
    cultivar_id: varieties[5].id,
    data_emergencia: getCustomDate(-700, true),
    data_ideal_proxima_aplicacao: getCustomDate(-693, true),
    data_semeadura: getCustomDate(-705, true),
    estadio_atual: {
      id: 23,
      message_key: 'growth_stage.soybean.r7',
      name: 'R7',
    },
    estimated_cycle: varieties[5].cycle + 16,
    germinative_power: varieties[5].germinative_power,
    inoculant: false,
    line_spacing: 13.2,
    operating_speed: 5.6,
    origem: 'comprado',
    plot_enabled: fields[4].enabled,
    population_lines: 2.4,
    programa: true,
    quantidade: fields.length && fields[4].area / 3,
    ready_to_harvest: false,
    seed_class_id: seedClasses[0].id,
    seed_treatment: true,
    seed_vigor: varieties[4].seed_vigor,
    seeder_system_id: seederSystems[0].id,
    semente_id: 1,
    talhao: fields.length && fields[4].nome,
    talhao_id: fields.length && fields[4].id,
    total_aplicacoes: 3,
    total_execucoes: 2,
    total_sprayings: 1,
  },
]

export { createSowing, seedClasses, seederSystems, sowings }
