<template>
  <v-navigation-drawer
    id="sub-drawer"
    class="sub-drawer"
    :value="isSubDrawerOpened"
  >
    <v-list-item class="sub-drawer__header">
      <v-btn id="sub-drawer-return" icon @click="closeSubDrawer">
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
      <h2 class="header-title ml-2">
        {{ name ? $t(`plan.menu.${name}`) : '' }}
      </h2>
      <v-spacer />
      <v-btn icon @click="closeDrawerAndSubDrawer">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-list-item>
    <v-divider class="sub-drawer__divider" />
    <loader v-if="loadingPlot" :show-loading-text="false" />
    <div
      v-else-if="!children.length"
      class="d-flex justify-center align-center sub-drawer__no-content"
    >
      {{ $t('plan.menu.no_result') }}
    </div>
    <div v-else class="sub-drawer__content">
      <template v-for="(item, index) in children">
        <div
          class="sub-drawer-nav df-flex-xl align-center flex-nowrap"
          :key="index"
          @click="checkActionAndClose(item)"
        >
          <font-awesome-icon
            :icon="item.icon"
            color="#788476"
            class="sub-drawer-icon"
          />
          <h2 class="sub-drawer-title">
            {{ $t(item.label) || item.translatedLabel }}
          </h2>
        </div>
      </template>
    </div>
  </v-navigation-drawer>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Loader from '@/components/Loaders/Loader.vue'

export default {
  name: 'SubDrawer',

  components: {
    Loader,
  },

  props: {
    children: {
      required: true,
      type: Array,
    },
    name: {
      required: true,
      type: String,
    },
  },

  computed: {
    ...mapGetters('farms', ['currentFarmId']),
    ...mapGetters('layout', ['isSubDrawerOpened']),
    ...mapGetters('plot', ['loadingPlot']),
  },

  methods: {
    ...mapActions('layout', ['setSubDrawer', 'closeDrawerAndSubDrawer']),
    checkActionAndClose(item) {
      this.$router.push({
        path: item.path,
        query: {
          plot_id: item.id,
          fazenda_id: this.currentFarmId,
        },
      })
      this.closeDrawerAndSubDrawer()
    },
    closeSubDrawer() {
      this.setSubDrawer(false)
    },
  },
}
</script>

<style lang="scss" scoped>
.sub-drawer {
  position: fixed;
  left: 256px;
  z-index: 6;
  transition: all 0.3s ease;

  @media (max-width: 960px) {
    left: 0px;
    z-index: 8;
  }
}
.sub-drawer__header {
  position: absolute;
  height: 62px;
  width: calc(100% - 10px);
  background-color: $color-background-white;
  z-index: 1;

  .header-title {
    @include label-medium;
    color: $color-text-secondary;
  }
}
.sub-drawer__divider {
  position: absolute;
  top: 64px;
  width: 256px;
}
.sub-drawer__no-content {
  @include label-small;
  height: 100%;
  color: #788476;
}
.sub-drawer__content {
  margin-top: 80px;
}
.sub-drawer-nav {
  padding: $spacing-m $spacing-xl;

  &:hover {
    background-color: $color-background-primary-lightest;
    cursor: pointer;

    & .sub-drawer-icon {
      color: $color-icon-primary;
    }
    & .sub-drawer-title {
      color: $color-text-primary;
    }
  }
}
.sub-drawer-title {
  @include label-medium;
  color: #788476;
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: normal;
}
</style>
