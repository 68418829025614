const Module = () =>
  import(/* webpackChunkName: "users-module" */ '@/modules/users/Module.vue')
const UsersHome = () =>
  import(
    /* webpackChunkName: "users-module" */ '@/modules/users/views/UsersHome.vue'
  )

const routes = [
  {
    path: '/users',
    component: Module,
    children: [
      {
        path: '/',
        component: UsersHome,
      },
    ],
    meta: {
      requiresAuth: true,
    },
  },
]

export default routes
