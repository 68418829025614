<template>
  <selector-dropdown
    icon-one="seedling"
    icon-two="spray-can"
    icon-button="bars"
    :object-actual="currentFarm"
    :objects="farmTrasform"
    :text-button="$t('farm.see_all_farms')"
    :title="$t('farm.select_farm')"
    :tooltip-one="$t('sowing.active_sowing')"
    :tooltip-two="$t('application.closets_applications')"
    :width-slot="isUSA ? '120px' : '150px'"
    @actionButton="redirectToFarms"
    @clickButton="changeFarm"
  />
</template>

<script>
import SelectorDropdown from '@/modules/menu/components/dropdowns/SelectorDropdown.vue'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'FarmSelector',

  components: {
    SelectorDropdown,
  },

  created() {
    this.$root.$on('change-current-farm', this.changeFarm)
    this.$root.$on('redirect-to-farms', this.redirectToFarms)
  },

  computed: {
    ...mapGetters('farms', ['currentFarm', 'farms']),
    ...mapGetters('user', ['isUSA']),
    farmTrasform() {
      return this.onlyFarmEnable.map((farm) => {
        return {
          ...farm,
          attribute_one: farm.active_sowing,
          attribute_two: farm.closets_applications,
        }
      })
    },
    onlyFarmEnable() {
      return this.farms.filter((farm) => {
        if (farm.enabled) {
          return farm
        }
      })
    },
  },

  methods: {
    ...mapActions('cultivares', {
      fetchSeasonCropVarieties: 'fetchSeasonCropVarieties',
      getSeedsBySelectedCrop: 'getSeedsBySelectedCrop',
      resetCultivaresStore: 'resetStore',
    }),
    ...mapActions('execucoes', {
      loadProgramasPlanejados: 'loadProgramasPlanejados',
      resetExecucaoStore: 'resetStore',
    }),
    ...mapActions('farms', ['fetchFarms', 'setFarm']),
    ...mapActions('notes', {
      fetchNotes: 'fetchNotes',
      resetNotesStore: 'resetStore',
    }),
    ...mapActions('plot', ['getPlots']),
    ...mapActions('semeaduras', ['loadSowingsFilter', 'loadAllSowings']),
    async changeFarm(farm) {
      if (farm.id === this.currentFarm.id) return
      this.setFarm(farm.id)

      if (this.$route.path === '/plot') {
        this.redirectToHome(farm.id)
      } else {
        this.$router.replace({
          query: { fazenda_id: farm.id },
        })
      }

      const fieldParams = {
        farmId: farm.id,
      }
      await Promise.allSettled([
        this.getPlots(fieldParams),
        this.loadAllSowings(['status', 'colheita']),
        this.loadSowingsFilter(),
      ])

      if (this.$route.path === '/notes') {
        const notesParams = { farmId: farm.id }
        await this.fetchNotes(notesParams)
      } else this.resetNotesStore()

      if (this.$route.path === '/comparacao') {
        this.redirectToPrograms()
      }

      this.resetExecucaoStore()
      if (this.$route.path === '/execucao') {
        this.redirectToSowings(farm.id)
      }

      if (this.$route.path === '/cultivares') {
        this.getSeedsBySelectedCrop(farm.id)
      } else if (
        this.$route.path === '/sowings' ||
        this.$route.path === '/performance'
      ) {
        this.fetchSeasonCropVarieties(farm.id)
      } else if (this.$route.path === '/programas') {
        this.fetchSeasonCropVarieties(farm.id)
        // Temporary fix for programs page
        this.$root.$emit('reset-program-variety')
      } else if (
        this.$route.path !== '/cultivares' &&
        this.$route.path !== '/programas' &&
        this.$route.path !== '/sowings' &&
        this.$route.path !== '/performance' &&
        this.$route.path !== '/comparacao'
      ) {
        this.resetCultivaresStore()
      }
    },
    redirectToHome(farmId) {
      this.$router.push({
        path: '/my_farm',
        query: { fazenda_id: farmId },
      })
    },
    redirectToFarms() {
      this.$router.push({
        path: '/farms',
      })
    },
    redirectToPrograms() {
      this.$router.push({
        path: 'programas',
      })
    },
    redirectToSowings(farmId) {
      this.$router.push({
        path: 'sowings',
        query: { fazenda_id: farmId },
      })
    },
  },

  beforeDestroy() {
    this.$root.$off('change-current-farm', this.changeFarm)
    this.$root.$off('redirect-to-farms', this.redirectToFarms)
  },
}
</script>
