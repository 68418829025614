import api from '@/services/api'

function initialState() {
  return {
    cultivar: null,
    cultivares: [],
    cultivaresLoading: false,
    sementes: [],
  }
}

export const cultivares = {
  namespaced: true,

  state: {
    initialState: initialState,
    cultivar: initialState().cultivar,
    cultivares: initialState().cultivares,
    cultivaresLoading: initialState().cultivaresLoading,
    sementes: initialState().sementes,
  },

  getters: {
    cultivares: (state) => {
      return state.cultivares
    },
    cultivaresLoading: (state) => {
      return state.cultivaresLoading
    },
    cultivar: (state) => {
      return state.cultivar
    },
    sementes: (state) => {
      return state.sementes
    },
    varietiesByCrop(state, getters, rootState) {
      const cropId = rootState.crops.currentCrop?.id
      return state.cultivares?.filter((variety) => variety.crop_id == cropId)
    },
  },
  mutations: {
    SET_VARIETIES(state, cultivares) {
      state.cultivares = cultivares
    },
    SET_VARIETIES_LOADING(state, loading) {
      state.cultivaresLoading = loading
    },
    SET_VARIETY(state, cultivar) {
      state.cultivar = cultivar
    },
    SET_SEEDS(state, sementes) {
      state.sementes = sementes
    },
  },

  actions: {
    changeCurrentVariety({ commit }, variety) {
      commit('SET_VARIETY', variety)
    },
    async fetchSeasonCropVarieties({ commit, rootState }, farmId) {
      if (!farmId) return
      const seasonCropId = rootState.seasonsCrops.currentSeasonCrop?.id
      commit('SET_VARIETIES_LOADING', true)
      try {
        const { data } = await api.cultivares.get(farmId, seasonCropId)
        commit('SET_VARIETIES', data)
      } catch (error) {
        if (error) throw new Error('Erro ao buscar cultivares')
      } finally {
        commit('SET_VARIETIES_LOADING', false)
      }
    },
    async excluirCultivar(storage, data) {
      const response = await api.cultivares.delete(data)

      if (!response) throw new Error('Erro ao buscar cultivar')

      return true
    },
    async getSeedsBySelectedCrop({ commit, rootState }, farmId) {
      if (!farmId) return
      const cropId = rootState.crops.currentCrop?.id
      const seasonCrop = rootState.seasonsCrops.currentSeasonCrop

      if (!cropId) throw new Error('Undefined current crop')

      const response = await api.sementes.getSeedsWithCultivares(
        farmId,
        cropId,
        seasonCrop?.id
      )
      await commit('SET_SEEDS', response.data)
    },
    async getNewestSeeds({ commit, rootState }, farmId) {
      const cropId = rootState.crops?.currentCrop?.id

      if (!cropId) throw new Error('Undefined current crop')

      const response = await api.sementes.getSeedsWithCultivares(farmId, cropId)

      await commit('SET_SEEDS', response.data)
    },
    async createVariety(storage, variety) {
      const response = await api.varieties.create(variety)

      return response
    },
    async modalSolicitacao(storage, cultivar) {
      const response = await api.cultivares.solicitacao(
        cultivar.cultivar,
        cultivar.msg
      )

      if (!response) throw new Error('Erro ao enviar solicitação')

      return true
    },
    resetStore({ commit }) {
      commit('SET_VARIETIES', initialState().cultivares)
      commit('SET_VARIETIES_LOADING', initialState().cultivaresLoading)
      commit('SET_VARIETY', initialState().cultivar)
      commit('SET_SEEDS', initialState().sementes)
    },
  },
}
