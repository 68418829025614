<template>
  <v-navigation-drawer
    ref="drawer"
    class="left-drawer"
    app
    disable-route-watcher
    :expand-on-hover="!isDSMobile && !drawer"
    :height="windowHeight"
    :mini-variant="!isDSMobile && !drawer"
    :permanent="!isDSMobile"
    :temporary="isDSMobile && drawer"
    :value="drawer"
    v-click-outside="closeClickingOutside"
    @input="preventClose"
  >
    <v-list-item class="pl-4 pr-5">
      <v-list-item-content>
        <router-link to="/">
          <v-img
            alt="DigiFarmz"
            class="shrink mb-1"
            contain
            max-height="33"
            min-width="160"
            width="160"
            :src="logo"
          />
        </router-link>
      </v-list-item-content>

      <v-btn icon @click.stop="closeDrawerAndSubDrawer">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-list-item>

    <v-list dense nav class="mt-4" data-v-step="menu-changed">
      <template v-for="menuItem in menuItems">
        <v-list-item
          v-if="!menuItem.children"
          active-class="menu-active"
          class="color-text-menu menu-nav pa-0 d-flex justify-center align-center"
          :key="menuItem.index"
          :to="shouldOpenPlanUpgradeModal(menuItem.name) ? '' : menuItem.path"
          @click="selectItemList(menuItem.name)"
        >
          <v-list-item-icon class="d-flex justify-center align-center mx-2">
            <font-awesome-icon :icon="menuItem.icon" />
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title class="menu-title">
              {{ $t(menuItem.label) }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <div
          v-else
          data-id="list-group"
          :key="menuItem.index"
          @click="selectGroupList(menuItem)"
        >
          <v-list-group
            class="menu-nav"
            active-class="menu-active"
            no-action
            append-icon="mdi-chevron-right"
          >
            <template #activator>
              <v-list-item
                class="color-text-menu menu-nav pa-0 d-flex justify-center align-center"
                active-class="menu-active"
              >
                <v-list-item-icon
                  class="d-flex justify-center align-center mx-2"
                >
                  <font-awesome-icon :icon="menuItem.icon" />
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title class="menu-title">
                    {{ $t(menuItem.label) }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list-group>
        </div>
      </template>
    </v-list>
    <template #append>
      <limit-exceeded v-if="isFreemiumPlan" />
      <v-list dense nav>
        <v-list-item
          v-if="showAdminPanelIcon"
          class="color-text-menu menu-nav d-flex justify-center align-center"
          link
          :href="adminPanel()"
          target="_blank"
        >
          <v-list-item-icon class="d-flex justify-center align-center mx-2">
            <font-awesome-icon icon="user-cog" />
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="menu-footer">
              {{ $t('menu.admin_panel') }}
            </v-list-item-title>
          </v-list-item-content> </v-list-item
        ><v-list-item
          v-if="showAdminLinkagePanelIcon"
          class="color-text-menu menu-nav d-flex justify-center align-center"
          link
          :href="linkagePanel()"
          target="_blank"
        >
          <v-list-item-icon class="d-flex justify-center align-center mx-2">
            <font-awesome-icon icon="chart-bar" />
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="menu-footer">
              {{ $t('menu.linkage') }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          class="color-text-menu menu-nav d-flex justify-center align-center"
          link
          :href="termsOfUseUrl"
          target="_blank"
        >
          <v-list-item-icon class="d-flex justify-center align-center mx-2">
            <font-awesome-icon icon="file-alt" />
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="menu-footer">
              {{ $t('menu.termos_de_uso') }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item
          class="color-text-menu menu-nav d-flex justify-center align-center"
          @click="logout"
        >
          <v-list-item-icon class="d-flex justify-center align-center mx-2">
            <font-awesome-icon icon="power-off" />
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="menu-footer">
              {{ $t('sair') }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </template>
    <plan-presentation-modal
      :dialog="modalPlanDialog"
      :images="imagesPlan"
      :title-modal="titlePlanModal"
      @close="closePlanPresentationModal"
    />
  </v-navigation-drawer>
</template>

<script>
import items, { menuTabsNames } from '@/modules/menu/menuItems.js'
import LayoutMixin from '@/components/LayoutMixin.vue'
import LimitExceeded from '@/modules/menu/components/LimitExceeded.vue'
import menuImagesPlan from '@/utils/imagesPlan/menuImagesPlan.js'
import PermissionMixin from '@/components/Permission/PermissionMixin.vue'
import PlanPresentationModal from '@/components/Dialogs/PlanPresentationModal.vue'
import { mapActions, mapGetters } from 'vuex'
import { getTermsOfUse } from '@/utils/termsOfUse'
import { events, logEvent } from '@/services/analytics'
import Cookies from 'js-cookie'
import CryptoJS from 'crypto-js'

export default {
  name: 'LeftDrawer',

  mixins: [LayoutMixin, PermissionMixin],

  components: {
    LimitExceeded,
    PlanPresentationModal,
  },

  data() {
    return {
      imagesPlan: [],
      menuItems: items,
      modalPlanDialog: false,
      titlePlanModal: '',
      windowHeight: window.innerHeight,
    }
  },

  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },

  computed: {
    ...mapGetters('farms', ['currentFarmId']),
    ...mapGetters('layout', [
      'drawer',
      'isSubDrawerOpened',
      'openedSubDrawerName',
    ]),
    ...mapGetters('permissions', ['hasPermission']),
    ...mapGetters('user', ['isFreemiumPlan', 'currentUser']),
    showAdminPanelIcon() {
      return this.hasPermission(this.$p.admin)
    },

    showAdminLinkagePanelIcon() {
      return this.hasPermission(this.$p.linkage)
    },
    termsOfUseUrl() {
      return 'doc/' + getTermsOfUse(this.getCountry)
    },
  },

  methods: {
    ...mapActions('layout', [
      'setDrawer',
      'setSubDrawer',
      'openDrawerAndSubDrawer',
      'closeDrawerAndSubDrawer',
    ]),
    ...mapActions('plot', ['getPlots']),
    ...mapActions('globalStore', ['resetAllStores']),
    async logout() {
      // FIX-ME: Backend is not working as expected, so we shouldn't wait for response
      // to logout the user. Once the backend is fixed, we should use the commented code
      try {
        await this.$api.auth.logout()
        Cookies.remove('token')
        this.$router.push({ path: 'login' })
        this.resetStore()
      } catch (error) {
        console.error({ logoutError: error })
      }

      // try {
      //   const response = await this.$api.auth.logout()
      //   if (response.status === 200) {
      //     Cookies.remove('token')
      //     this.$router.push({ path: 'login' })
      //     this.resetStore()
      //   }
      // } catch (error) {
      //     console.error({ logoutError: error })
      // }
    },
    closeClickingOutside(event) {
      const subDrawer = document.getElementById('sub-drawer')

      const toggleDrawerContentIds = ['toast-drawer', 'toast-drawer-icon']
      if (
        !this.isContentOfToggleButton(event) &&
        !toggleDrawerContentIds.includes(event.target?.id) &&
        !subDrawer?.contains(event.target)
      ) {
        this.closeDrawerAndSubDrawer()
      }
    },
    closePlanPresentationModal() {
      this.modalPlanDialog = false
    },
    checkOpenPlanModal(menuName) {
      const shouldOpenModal = this.shouldOpenPlanUpgradeModal(menuName)
      const params = {
        menu: menuName,
        modal: shouldOpenModal,
      }
      this.sendPlanEvent(shouldOpenModal, menuName, params)

      return !!params.modal
    },
    async checkPlotContent(menu) {
      let response = []
      this.$emit('set-content', response)
      const params = {
        farmId: this.currentFarmId,
        params: 'created_at:desc&enabled=true',
      }
      response = await this.getPlots(params)
      if (!response.length) {
        this.$root.$emit('notify', 'warning', this.$t('talhoes.text_criar'))
        return []
      } else {
        return response.map((plot) => {
          return {
            id: plot.id,
            icon: 'vector-square',
            path: menu.name,
            translatedLabel: plot.nome,
          }
        })
      }
    },
    isContentOfToggleButton(event) {
      return (
        event.target?.parentNode?.id === 'toast-drawer' &&
        event.target.classList &&
        Array.from(event.target.classList).includes('v-btn__content')
      )
    },
    getImages(path) {
      this.imagesPlan = this.isDSMobile
        ? menuImagesPlan[`mobile_${path}`]
        : menuImagesPlan[path]
    },
    openPlanPresentationModal(params) {
      this.titlePlanModal = this.$t(`plan.menu.${params.menu}`)
      this.modalPlanDialog = params.modal
      this.getImages(params.menu)
    },
    preventClose() {
      if (this.drawer) {
        this.$nextTick(() => {
          this.$refs.drawer.isActive = true
        })
      }
    },
    resetStore() {
      this.resetAllStores()
    },

    selectItemList(menuName) {
      this.checkOpenPlanModal(menuName)
      this.closeDrawerAndSubDrawer()
    },
    async selectGroupList(menu) {
      const shouldOpen = this.checkOpenPlanModal(menu.name)
      if (!shouldOpen) {
        if (this.openedSubDrawerName === menu.name) {
          this.setSubDrawer(false)
        } else {
          this.openDrawerAndSubDrawer(menu.name)
          this.$emit('set-sub-menu-name', menu.name)
          let children = menu.children
          if (menu.name === menuTabsNames.plot) {
            children = await this.checkPlotContent(menu)
          }
          if (children.length) {
            this.$emit('set-content', children)
          }
        }
      }
    },
    sendPlanEvent(shouldOpenModal, menuName, params) {
      if (shouldOpenModal) {
        logEvent(events.plansModule.openedModal(menuName))
        this.openPlanPresentationModal(params)
      }
    },
    shouldOpenPlanUpgradeModal(menuName) {
      const menuPermissionDict = {
        plot: 'plot',
        planning: 'planning',
        planting: 'planting',
        catalog: 'catalog',
        notes: 'note',
        // same access permission as planting
        performance: 'planting',
      }

      let shouldOpenModal = false
      if (menuName === 'my_farm')
        shouldOpenModal = !this.hasPermission(this.$p.farm.view)
      else
        shouldOpenModal = !this.hasPermission(
          this.$p[menuPermissionDict[menuName]].list
        )

      return shouldOpenModal
    },
    onResize() {
      this.windowHeight = window.innerHeight
    },
    adminPanel() {
      // Voltar para extrair para uma função especializada
      const k1 = process.env.VUE_APP_SECRET_KEY_K1
      const k2 = process.env.VUE_APP_SECRET_KEY_K2
      const str = Cookies.get('token')

      const key = CryptoJS.enc.Utf8.parse(k1)
      const iv = CryptoJS.enc.Utf8.parse(k2)

      // Encrypting
      const encryptedToken = CryptoJS.AES.encrypt(str, key, { iv }).toString()

      return process.env.VUE_APP_ADMIN_URL + `?token=${encryptedToken}`
    },

    linkagePanel() {
      return this.currentUser?.link_linkage
    },
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
}
</script>

<style lang="scss" scoped>
.left-drawer {
  z-index: 7;
}
.menu-active {
  color: #39af49;
}
.menu-nav:hover {
  color: #39af49 !important;
}
.menu-title {
  color: #788476;
}
::v-deep .v-list-group__header {
  padding: 0 !important;
  &:hover {
    color: #39af49 !important;
  }
}
.color-text-menu.menu-active:has(> .v-list-item__icon) .v-list-item__icon,
.color-text-menu.menu-active:has(> .v-list-item__content)
  .v-list-item__content
  .menu-title {
  color: #39af49;
}
.color-text-menu:hover {
  & .v-list-item__icon {
    color: #39af49;
  }
  & .v-list-item__content .menu-title,
  & .v-list-item__content .menu-footer {
    color: #39af49;
  }
}
.menu-footer {
  font-family: 'Rubik';
  font-size: 12px;
  font-weight: 600;
  color: #788476;
}
</style>

<style lang="scss">
.v-list {
  & .color-text-menu {
    & .v-list-item__content {
      & .menu-title {
        font-family: 'Rubik';
        font-size: 16px;
        font-weight: 600;
      }
    }
    & .v-list-item__icon {
      color: #788476;
    }
  }
}
.menu-active {
  & .v-list-item__icon {
    & .v-icon {
      color: #39af49 !important;
    }
  }
}
.menu-nav .mdi-chevron-right {
  transform: none !important;
}
</style>
