const plans = [
  {
    id: 1,
    name: 'Light',
    logo_uri: {
      web: 'https://dev-digifarmz-platform.s3.amazonaws.com/files/plans/light_logo_web.png',
      mobile:
        'https://dev-digifarmz-platform.s3.amazonaws.com/files/plans/light_logo_mobile.png',
    },
    upsell_uri: {},
    priority_level: 1,
  },
  {
    id: 2,
    name: 'DigiFarmz',
    logo_uri: {
      web: 'https://dev-digifarmz-platform.s3.amazonaws.com/files/plans/digifarmz_logo_web.png',
      mobile:
        'https://dev-digifarmz-platform.s3.amazonaws.com/files/plans/digifarmz_logo_mobile.png',
    },
    upsell_uri: {},
    priority_level: 3,
  },
  {
    id: 3,
    name: 'Full',
    logo_uri: {
      web: 'https://dev-digifarmz-platform.s3.amazonaws.com/files/plans/full_logo_web.png',
      mobile:
        'https://dev-digifarmz-platform.s3.amazonaws.com/files/plans/full_logo_mobile.png',
    },
    upsell_uri: {},
    priority_level: 4,
  },
  {
    id: 4,
    name: 'Pro',
    logo_uri: {
      web: 'https://dev-digifarmz-platform.s3.amazonaws.com/files/plans/pro_logo_web.png',
      mobile:
        'https://dev-digifarmz-platform.s3.amazonaws.com/files/plans/pro_logo_mobile.png',
    },
    upsell_uri: {},
    priority_level: 2,
  },
  {
    id: 5,
    logo_uri: {
      mobile:
        'https://dev-digifarmz-platform.s3.amazonaws.com/files/plans/digifarmz_logo_mobile.png',
      web: 'https://dev-digifarmz-platform.s3.amazonaws.com/files/plans/digifarmz_logo_web.png',
    },
    name: 'Freemium',
    upsell_uri: {},
    priority_level: 0,
  },
]

export { plans }
