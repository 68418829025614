import Cookies from 'js-cookie'
import permissionMap from '@/modules/auth/helper/permissionsMap.js'
import store from '@/store'
import VueRouter from 'vue-router'
import { privateRoutes, publicRoutes } from '@/router/app'
import { isSowingInCurrentSeasons } from '@/utils/seasonsCrops'

const currentStore = store

const routes = [...privateRoutes, ...publicRoutes]

const router = new VueRouter({
  routes,
})

const notifyAlert = (message) => {
  const event = new CustomEvent('notify', {
    bubbles: true,
    detail: {
      color: 'error',
      text: message,
    },
  })
  document.dispatchEvent(event)
}

const isInCurrentSeasons = (to) => {
  const seasonsCrops = currentStore.getters['seasonsCrops/seasonsCrops']
  const currentSowing = currentStore.getters['semeaduras/allSowings'].find(
    (sowing) => sowing.id == to.query.semeadura_id
  )
  return isSowingInCurrentSeasons(currentSowing, seasonsCrops)
}

const myFarmPathRules = (from, to, next) => {
  if (to.path === '/my_farm') {
    // FIXME: So far, the '/permissions' endpoint is called AFTER the first reroute to
    // 'my_farms', so it's not possible to validate using permissions. Once the need
    // for 'seasonsCrops' parameter is removed from '/permissions', the commented
    // code can be applied
    // if (
    //   currentStore.getters['permissions/permissions'].length &&
    //   !currentStore.getters['permissions/hasPermission'](
    //     permissionMap.farm.view
    //   )
    // ) {
    //   notifyAlert('exception.action_unauthorized')
    //   next({
    //     path: '/catalog',
    //     query: { category: 'fungicide' },
    //   })
    // }
    if (!to.query.fazenda_id && from.query.fazenda_id) {
      // adding farm id
      next({
        path: to.path,
        query: { fazenda_id: from.query.fazenda_id },
      })
      return false
    }
    return true
  }
  return true
}

const plansPathRules = (to) => {
  // FIXME: there's no permission coming from /permissions to access the /plans screen
  // once one is set, change the condition to access the screen by removing plan condition
  // and inserting permission condition
  if (to.path === '/plans' && currentStore.getters['user/isPlanLevelFour']) {
    notifyAlert('exception.action_unauthorized')
    return false
  }
  return true
}

const paymentPathRules = (to) => {
  // FIXME: there's no permission coming from /permissions to access the /payment screen
  // once one is set, change the condition to access the screen by removing plan condition
  // and inserting permission condition
  const isUserInAllowedPlans =
    currentStore.getters['user/isPlanLevelOne'] ||
    currentStore.getters['user/isFreemiumPlan']
  if (
    to.path === '/payment' &&
    (!isUserInAllowedPlans || !currentStore.getters['user/isBrazil'])
  ) {
    notifyAlert('exception.action_unauthorized')
    return false
  }
  return true
}

const usersPathRules = (to) => {
  if (
    to.path === '/users' &&
    !currentStore.getters['permissions/hasPermission'](permissionMap.user.list)
  ) {
    notifyAlert('exception.action_unauthorized')
    return false
  }
  return true
}

const executionPathRules = (to) => {
  if (to.path === '/execucao') {
    if (
      !currentStore.getters['permissions/hasPermission'](
        permissionMap.program.list
      )
    ) {
      notifyAlert('exception.action_unauthorized')
      return false
    } else if (!isInCurrentSeasons(to)) {
      notifyAlert('Home.sowing.not_current_season')
      return false
    }
  }
  return true
}

const originalPush = router.push
router.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalPush.call(this, location, onResolve, onReject)
  }

  return originalPush.call(this, location).catch((err) => {
    if (err.name === 'NavigationDuplicated') {
      return Promise.resolve(this.currentRoute)
    } else {
      return Promise.reject(err)
    }
  })
}

const originalReplace = router.replace
router.replace = function replace(location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalReplace.call(this, location, onResolve, onReject)
  }

  return originalReplace.call(this, location).catch((err) => {
    if (err.name === 'NavigationDuplicated') {
      return Promise.resolve(this.currentRoute)
    } else {
      return Promise.reject(err)
    }
  })
}

router.beforeEach((to, from, next) => {
  // Get token from other apps, such as Admin Panel
  if (to.query.token) {
    Cookies.set('token', to.query.token, { expires: 1 })

    let query = Object.assign({}, to.query)
    delete query.token
    router.replace({ path: to.path, query })
  }

  if (to.path == '/account/activate') {
    next()
    return
  }

  if (!Cookies.get('token')) {
    if (to.path == '/login') {
      next()
      return
    }
    next('/login')
    return
  }

  // Check if route requires auth
  const routeRequiresAuth = to.matched.find(
    (r) => to?.path == r?.path && r?.meta?.requiresAuth
  )
  if (!routeRequiresAuth && Cookies.get('token')) {
    if (!to.path.match('/reports/')) {
      next('/my_farm')
      return
    }
  }

  const myFarmPathResult = myFarmPathRules(from, to, next)
  if (!myFarmPathResult) return

  const plansPathResult = plansPathRules(to)
  if (!plansPathResult) return

  const paymentPathResult = paymentPathRules(to)
  if (!paymentPathResult) return

  const usersPathResult = usersPathRules(to)
  if (!usersPathResult) return

  const executionPathResult = executionPathRules(to)
  if (!executionPathResult) return

  // TODO: make validations for all routes

  next()
})

export { router }
export default VueRouter
