import { adminAuthHttp } from '@/utils/http'

const permissions = {
  get: (seasonCropId = null) =>
    adminAuthHttp.get(
      `/permissions` + (seasonCropId ? `?season_crop_id=${seasonCropId}` : '')
    ),
}

export default permissions
