import { APIFailureWrapper, mockFlag } from '@/utils/mock'

const seasonAccountRoutes = [
  mockFlag(
    {
      method: 'get',
      url: '/season-account',
      result: () => {
        const response = {
          id: 1,
          account_id: null,
          count_enabled: null,
        }

        return APIFailureWrapper({
          content: response,
          errorMessage: 'Erro na busca de season account',
        })
      },
    },
    'on'
  ),
]

export { seasonAccountRoutes }
