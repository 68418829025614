import http from '@/utils/http'

const menu = {
  usuario: () => http.get('/menu/dados-usuario'),
  contas: () => http.get('/menu/contas'),
  // Currently not used
  // safras: () => http.get('/menu/safras'),
  // alterarSafra: (seasonId) => http.put('/menu/alterar-safra', { seasonId }),
  // alterarConta: (id) => http.put('/menu/alterar-conta', { id: id }),
  // paisConta: () => http.get('/menu/pais-conta'),
}

export default menu
