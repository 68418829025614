export default {
  Components: {
    input: {
      numbers_only: 'Sólo números',
    },
    filter_select: {
      results_not_found: 'Resultados no encontrados',
    },
    date_picker: {
      cancel: 'Cancelar',
      date_not_recommended: 'Fecha no recomendada para tu región',
    },
    select_dropdown: {
      clean: 'Limpiar',
      apply: 'Aplicar',
      results_not_found: 'No se encontraron resultados',
    },
  },
}
