const Sowings = () =>
  import(/* webpackChunkName: "activities-module" */ '@/pages/semeaduras')
const HomeExecucao = () =>
  import(
    /* webpackChunkName: "activities-module" */ '@/pages/execucao/HomeExecucao.vue'
  )

const routes = [
  {
    name: 'sowings',
    path: '/sowings',
    component: Sowings,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/execucao',
    component: HomeExecucao,
    meta: {
      requiresAuth: true,
    },
  },
]

export default routes
