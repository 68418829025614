import { adminNoAuthHttp } from '@/utils/http'

const accountPending = {
  active: (email, code) => {
    return adminNoAuthHttp.post('/account-active', { email, code })
  },
  resend: (email, language) =>
    adminNoAuthHttp.post('/account-active/resend', { email, language }),
}

export default accountPending
