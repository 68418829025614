<template>
  <div>
    <v-menu left offset-y transition="slide-y-transition">
      <template #activator="{ on, attrs }">
        <df-button
          v-bind="attrs"
          v-on="on"
          icon="chevron-down"
          plain
          round
          variant="text-blue"
          :color-icon="colorIconNeutral"
        >
          <font-awesome-icon
            icon="user-circle"
            size="2x"
            :color="colorIconNeutral"
          />
        </df-button>
      </template>

      <account-configs-dropdown
        @open-config-area-modal="openConfigAreaModal"
        @open-password-modal="openPasswordModal"
      />
    </v-menu>
    <modal-senha v-if="isModalSenhaOpened" @close="closeModalSenha" />
    <modal-config-area
      v-if="isModalConfigAreaOpened"
      @close="closeModalConfigArea"
    />
  </div>
</template>

<script>
import AccountConfigsDropdown from '@/modules/menu/components/dropdowns/AccountConfigsDropdown.vue'
import colors from '@/assets/styles/__colors.module.scss'
import DfButton from '@/lib/components/Button/DfButton.vue'
const ModalConfigArea = () =>
  import('@/modules/menu/components/modals/ModalConfigArea.vue')
const ModalSenha = () =>
  import('@/modules/menu/components/modals/ModalSenha.vue')

export default {
  name: 'AccountConfigs',

  components: {
    AccountConfigsDropdown,
    DfButton,
    ModalConfigArea,
    ModalSenha,
  },

  data() {
    return {
      colorIconNeutral: colors.colorIconNeutral,
      isModalConfigAreaOpened: false,
      isModalSenhaOpened: false,
    }
  },

  methods: {
    closeModalSenha() {
      this.isModalSenhaOpened = false
    },
    closeModalConfigArea() {
      this.isModalConfigAreaOpened = false
    },
    openConfigAreaModal() {
      this.isModalConfigAreaOpened = true
    },
    openPasswordModal() {
      this.isModalSenhaOpened = true
    },
  },
}
</script>
