export default {
  WithoutItemCard: {
    field: {
      title: 'You still have no active field',
      text: 'All your fields will appear here.',
      create_field: 'Create field',
    },
    sowing: {
      title: 'You still have no active sowing',
      text: 'All your sowings will appear here.',
      execute_sowing: 'Execute sowing',
    },
    filter: {
      not_found_title: 'No results found',
      not_found_text: 'Try changing the filters',
    },
  },
}
