import harvests from './harvests'
import integration from './integration'
import ndvi from './ndvi'
import panels from './panels'
import programs from './programs'
import seasonReport from './seasonReport'
import sidebar from './sidebar'
import sowings from './sowings'
import varieties from './varieties'

import catalogModule from '@/modules/catalog/events/catalogModule'
import homeModule from '@/modules/home/events/homeModule'
import performanceModule from '@/modules/performance/events/performanceModule'
import plansModule from '@/modules/plans/events/plansModule'
import plotModule from '@/modules/plot/events/plotModule'
import notesModule from '@/modules/notes/events/notesModule'
import usersModule from '@/modules/users/events/usersModule'

export default {
  catalogModule,
  homeModule,
  integration,
  harvests,
  ndvi,
  notesModule,
  panels,
  performanceModule,
  plansModule,
  plotModule,
  programs,
  seasonReport,
  sidebar,
  sowings,
  usersModule,
  varieties,
}
