import { users } from '@/modules/users/mock/usersSeeds'
import { accountFieldArea } from '@/services/__mocks__/helpers'
import { cities, countries, states } from '@/services/__mocks__/locationSeeds'

const accountTypes = [{ id: 1 }, { id: 2 }]

const fullPlanUser = {
  accounts: [
    {
      account_type_id: accountTypes[1].id,
      account_type_name: 'DigiFarmz - Full',
      city_id: cities.filter((city) => city.uf == states[0].uf)[0].id,
      country_id: countries[0].id,
      id: '1',
      name: 'Conta com fazendas',
      state_id: states[0].id,
      max_users: 5,
      total_area_account: accountFieldArea({ accountId: '1' }) + 200,
    },
  ],
  is_first_login: false,
  is_first_login_full_plan: false,
  recovery_password: null,
  token: 'fullPlanToken',
  user: {
    id: users[0].id,
    email: users[0].email,
    user_profile: {
      id: users[0].profile.id,
      name: users[0].profile.name,
    },
    name: users[0].name,
    language: users[0].language,
    languageAccount: null,
    is_admin: users[0].is_admin,
    first_login: users[0].first_login,
    is_qa_mode_enabled: null,
    notes_enabled: null,
    academy_url: null,
    job_title: {
      id: users[0].job_title.id,
      name: users[0].job_title.name,
    },
    plan: {
      id: users[0].plan.id,
      name: users[0].plan.name,
      logo_uri: {
        web: users[0].plan.logo_uri.web,
        mobile: users[0].plan.logo_uri.mobile,
      },
      upsell_uri: users[0].plan.upsell_uri,
      priority_level: users[0].plan.priority_level,
    },
    used_area: false,
    total_area_account: accountFieldArea({ accountId: '1' }) + 200,
    account: users[0].account_id,
    logo: null,
    ndvi: null,
    password_type: null,
    social_token: null,
    active: users[0].active ? 1 : 0,
  },
}

const devPlanUser = {
  accounts: [
    {
      account_type_id: accountTypes[1].id,
      account_type_name: 'DigiFarmz - Dev',
      city_id: cities.filter((city) => city.uf == states[1].uf)[0].id,
      country_id: countries[0].id,
      id: '2',
      name: 'Conta primeiro login (sem fazendas)',
      state_id: states[1].id,
      max_users: 5,
      total_area_account: accountFieldArea({ accountId: '2' }) + 300,
    },
    {
      account_type_id: accountTypes[1].id,
      account_type_name: 'DigiFarmz - Dev',
      city_id: cities.filter((city) => city.uf == states[2].uf)[0].id,
      country_id: countries[0].id,
      id: '3',
      name: 'Dev',
      state_id: states[2].id,
      max_users: 5,
      total_area_account: accountFieldArea({ accountId: '3' }) + 400,
    },
  ],
  is_first_login: true,
  is_first_login_full_plan: false,
  recovery_password: null,
  token: 'devPlanToken',
  user: {
    id: users[5].id,
    email: users[5].email,
    user_profile: {
      id: users[5].profile.id,
      name: users[5].profile.name,
    },
    name: users[5].name,
    language: users[5].language,
    languageAccount: null,
    is_admin: users[5].is_admin,
    first_login: users[5].first_login,
    is_qa_mode_enabled: null,
    notes_enabled: null,
    academy_url: null,
    job_title: users[5].job_title,
    plan: {
      id: users[5].plan.id,
      name: users[5].plan.name,
      logo_uri: {
        web: users[5].plan.logo_uri.web,
        mobile: users[5].plan.logo_uri.mobile,
      },
      upsell_uri: users[5].plan.upsell_uri,
      priority_level: users[5].plan.priority_level,
    },
    used_area: false,
    total_area_account: accountFieldArea({ accountId: '2' }) + 300,
    account: users[5].account_id,
    logo: null,
    ndvi: null,
    password_type: null,
    social_token: null,
    active: users[5].active ? 1 : 0,
  },
}

const proPlanUser = {
  accounts: [
    {
      account_type_id: accountTypes[1].id,
      account_type_name: 'DigiFarmz - Pro',
      city_id: cities.filter((city) => city.uf == states[3].uf)[0].id,
      country_id: countries[0].id,
      id: '4',
      name: 'Pro',
      state_id: states[3].id,
      max_users: 5,
      total_area_account: accountFieldArea({ accountId: '4' }) + 500,
    },
  ],
  is_first_login: false,
  is_first_login_full_plan: false,
  recovery_password: null,
  token: 'proPlanToken',
  user: {
    id: users[6].id,
    email: users[6].email,
    user_profile: {
      id: users[6].profile.id,
      name: users[6].profile.name,
    },
    name: users[6].name,
    language: users[6].language,
    languageAccount: null,
    is_admin: users[6].is_admin,
    first_login: users[6].first_login,
    is_qa_mode_enabled: null,
    notes_enabled: null,
    academy_url: null,
    job_title: users[6].job_title,
    plan: {
      id: users[6].plan.id,
      name: users[6].plan.name,
      logo_uri: {
        web: users[6].plan.logo_uri.web,
        mobile: users[6].plan.logo_uri.mobile,
      },
      upsell_uri: users[6].plan.upsell_uri,
      priority_level: users[6].plan.priority_level,
    },
    used_area: false,
    total_area_account: accountFieldArea({ accountId: '4' }) + 500,
    account: users[6].account_id,
    logo: null,
    ndvi: null,
    password_type: null,
    social_token: null,
    active: users[6].active ? 1 : 0,
  },
}

const lightPlanUser = {
  accounts: [
    {
      account_type_id: accountTypes[1].id,
      account_type_name: 'DigiFarmz - Light',
      city_id: cities.filter((city) => city.uf == states[4].uf)[0].id,
      country_id: countries[0].id,
      id: '5',
      name: 'Light',
      state_id: states[4].id,
      max_users: 5,
      total_area_account: accountFieldArea({ accountId: '5' }) + 600,
    },
  ],
  is_first_login: false,
  is_first_login_full_plan: false,
  recovery_password: null,
  token: 'lightPlanToken',
  user: {
    id: users[7].id,
    email: users[7].email,
    user_profile: {
      id: users[7].profile.id,
      name: users[7].profile.name,
    },
    name: users[7].name,
    language: users[7].language,
    languageAccount: null,
    is_admin: users[7].is_admin,
    first_login: users[7].first_login,
    is_qa_mode_enabled: null,
    notes_enabled: null,
    academy_url: null,
    job_title: users[7].job_title,
    plan: {
      id: users[7].plan.id,
      name: users[7].plan.name,
      logo_uri: {
        web: users[7].plan.logo_uri.web,
        mobile: users[7].plan.logo_uri.mobile,
      },
      upsell_uri: users[7].plan.upsell_uri,
      priority_level: users[7].plan.priority_level,
    },
    used_area: null,
    total_area_account: accountFieldArea({ accountId: '5' }) + 600,
    account: users[7].account_id,
    logo: null,
    ndvi: null,
    password_type: null,
    social_token: null,
    active: users[7].active ? 1 : 0,
  },
}

const freemiumPlanUser = {
  accounts: [
    {
      account_type_id: accountTypes[1].id,
      account_type_name: 'DigiFarmz - Freemium',
      city_id: cities.filter((city) => city.uf == states[5].uf)[0].id,
      country_id: countries[0].id,
      id: '6',
      name: 'Freemium',
      state_id: states[5].id,
      max_users: 5,
      total_area_account: accountFieldArea({ accountId: '6' }) + 700,
    },
  ],
  is_first_login: false,
  is_first_login_full_plan: false,
  recovery_password: null,
  token: 'freemiumPlanToken',
  user: {
    id: users[8].id,
    email: users[8].email,
    user_profile: {
      id: users[8].profile.id,
      name: users[8].profile.name,
    },
    name: users[8].name,
    language: users[8].language,
    languageAccount: null,
    is_admin: users[8].is_admin,
    first_login: users[8].first_login,
    is_qa_mode_enabled: null,
    notes_enabled: null,
    academy_url: null,
    job_title: users[8].job_title,
    plan: {
      id: users[8].plan.id,
      name: users[8].plan.name,
      logo_uri: {
        web: users[8].plan.logo_uri.web,
        mobile: users[8].plan.logo_uri.mobile,
      },
      upsell_uri: users[8].plan.upsell_uri,
      priority_level: users[8].plan.priority_level,
    },
    used_area: null,
    total_area_account: accountFieldArea({ accountId: '6' }) + 700,
    account: users[8].account_id,
    logo: null,
    ndvi: null,
    password_type: null,
    social_token: null,
    active: users[8].active ? 1 : 0,
  },
}

const accounts = [
  ...devPlanUser.accounts,
  ...freemiumPlanUser.accounts,
  ...fullPlanUser.accounts,
  ...lightPlanUser.accounts,
  ...proPlanUser.accounts,
]
const loginUsers = [
  devPlanUser,
  freemiumPlanUser,
  fullPlanUser,
  lightPlanUser,
  proPlanUser,
]

export { accounts, fullPlanUser, loginUsers }
