import Cookies from 'js-cookie'
import { accounts, loginUsers } from '@/modules/auth/mock/authSeeds'
import { fields } from '@/modules/plot/mock/fieldsSeeds'
import { farms } from '@/modules/farm/mock/farmSeeds'

let currentAccount = accounts ? accounts[2] : {}

const getCurrentUser = () => {
  const token = Cookies.get('token')
  const currentUser = loginUsers.find((user) => user.token == token)

  return currentUser
}

const setCurrentAccount = (account) => {
  currentAccount = account
}

const accountFarmIds = (accountId) =>
  farms.reduce((acc, farm) => {
    if (farm.account_id == accountId) {
      acc.push(farm.id)
    }
    return acc
  }, [])

const accountFieldArea = ({ accountId }) =>
  fields.reduce((acc, field) => {
    if (accountFarmIds(accountId).includes(field.fazenda_id) && field.enabled) {
      acc += field.area
    }
    return acc
  }, 0)

export {
  accountFieldArea,
  accountFarmIds,
  currentAccount,
  getCurrentUser,
  setCurrentAccount,
}
