import http from '@/utils/http'

const users = {
  createUser: (user) => http.post('/users', user),
  deleteUser: (userId) => http.delete(`/users/${userId}`),
  getProfiles: () => http.get('/profiles'),
  getUsers: () => http.get('/users'),
  updateUser: (user) => http.put(`/users/${user.id}`, user),
  updateUserActiveness: ({ active, userId }) =>
    http.put(`/users/${userId}/active`, { active }),
}

export default users
