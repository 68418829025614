import { mockFlag } from '@/utils/mock'
import { APIFailureWrapper } from '@/utils/mock'
import { loginUsers } from '@/modules/auth/mock/authSeeds'
import { jobTitles } from '@/modules/users/mock/usersSeeds'

const authRoutes = [
  mockFlag(
    {
      method: 'post',
      url: '/job-titles',
      result: ({ requestBody }) => {
        const requestObj = JSON.parse(requestBody)
        loginUsers.forEach((user) => {
          if (user.user.id == requestObj.user_id) {
            const jobTitle = jobTitles.find(
              (jobTitle) => jobTitle.id == requestObj.job_title_id
            )
            user.user.job_title = jobTitle
          }
        })

        return APIFailureWrapper({
          content: null,
          errorMessage: 'Erro na seleção de título de cargo',
        })
      },
    },
    'on'
  ),
  mockFlag(
    {
      method: 'post',
      url: '/change-password',
      result: () => {
        return APIFailureWrapper({
          content: null,
          errorMessage: 'Erro na mudança de senha',
        })
      },
    },
    'on'
  ),
]

export { authRoutes }
