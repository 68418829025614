import api from '@/services/api'

function initialState() {
  return {
    cultivar: null,
    execucoes: [],
    isLoadingExecucoes: false,
    isLoadingIrrigacoes: false,
    irrigacoes: null,
    loadingPlannedProgram: false,
    meteorologia: null,
    programa: null,
    programasPlanejados: [],
    semeadura: null,
    semeaduras: [],
  }
}

export const execucoes = {
  namespaced: true,

  state: {
    initialState: initialState,
    cultivar: initialState().cultivar,
    execucoes: initialState().execucoes,
    isLoadingExecucoes: initialState().isLoadingExecucoes,
    isLoadingIrrigacoes: initialState().isLoadingIrrigacoes,
    irrigacoes: initialState().irrigacoes,
    loadingPlannedProgram: initialState().loadingPlannedProgram,
    meteorologia: initialState().meteorologia,
    programa: initialState().programa,
    programasPlanejados: initialState().programasPlanejados,
    semeadura: initialState().semeadura,
    semeaduras: initialState().semeaduras,
  },

  getters: {
    semeaduras: (state) => {
      return state.semeaduras
    },
    semeadura: (state) => {
      return state.semeadura
    },
    cultivar: (state) => {
      return state.cultivar
    },
    meteorologia: (state) => {
      return state.meteorologia
    },
    programa: (state) => {
      return state.programa
    },
    programasPlanejados: (state) => {
      return state.programasPlanejados
    },
    execucoes: (state) => {
      return state.execucoes
    },
    maxDoencas: () => {
      return process.env.VUE_APP_MAX_DOENCAS_PRIORIDADES
    },
    isLoadingExecucoes: (state) => {
      return state.isLoadingExecucoes
    },
    isLoadingIrrigacoes: (state) => {
      return state.isLoadingIrrigacoes
    },
    irrigacoes: (state) => {
      return state.irrigacoes
    },
    nextSpraying: (state) => {
      const notAppliedSprayings = state.execucoes?.filter(
        (execucao) => !execucao?.data_realizada
      )
      return notAppliedSprayings.length > 0 ? notAppliedSprayings[0] : null
    },
    loadingPlannedProgram: (state) => {
      return state.loadingPlannedProgram
    },
  },

  mutations: {
    SET_SEMEADURA_ID(state, semeaduraId) {
      state.semeadura = { id: semeaduraId }
    },
    SET_SEMEADURA(state, semeadura) {
      state.semeadura = semeadura
    },
    SET_SEMEADURAS(state, semeaduras) {
      state.semeaduras = semeaduras
    },
    SET_CULTIVAR_ID(state, cultivarId) {
      state.cultivar = { id: cultivarId }
    },
    SET_PROGRAMA(state, programa) {
      state.programa = programa
    },
    SET_PROGRAMAS_PLANEJADOS(state, programasPlanejados) {
      state.programasPlanejados = programasPlanejados
    },
    SET_EXECUCOES(state, execucoes) {
      state.execucoes = execucoes
    },
    REMOVE_EXECUCAO(state, execucaoId) {
      state.execucoes = state.execucoes.filter(
        (execucao) => execucao.id != execucaoId
      )
    },
    SET_METEREOLOGIA(state, meteorologia) {
      state.meteorologia = meteorologia
    },
    SET_IS_LOADING_EXECUCOES(state, status) {
      state.isLoadingExecucoes = status
    },
    SET_IS_LOADING_IRRIGACOES(state, status) {
      state.isLoadingIrrigacoes = status
    },
    SET_IRRIGACOES(state, irrigacoes) {
      state.irrigacoes = irrigacoes
    },
    SET_IS_LOADING_PLANNED_PROGRAMS(state, loading) {
      state.loadingPlannedProgram = loading
    },
  },

  actions: {
    async loadWeather({ commit }, farmId) {
      const { data } = await api.fazendas.clima(farmId)

      if (!data) throw new Error('Fail to load wheather')

      commit('SET_METEREOLOGIA', data)
    },
    async loadSemeaduras({ commit, state, dispatch, rootState }, farmId) {
      const { start_period, end_period } =
        rootState.seasonsCrops.currentSeasonCrop

      const { data } = await api.semeaduras.findByFazenda(
        farmId,
        ['semente', 'cultivar_regiao_parametros', 'colheita', 'status'],
        start_period,
        end_period
      )

      if (!data || data.length == 0)
        throw new Error('Falha ao carregar semeaduras')

      await commit('SET_SEMEADURAS', data)

      const semeadura = data.find(
        (semeadura) => semeadura.id == state.semeadura.id
      )

      if (!semeadura) throw new Error('Semeadura atual não encontrada')

      commit('SET_SEMEADURA', semeadura)
      await dispatch('crops/setCurrentCrop', semeadura.crop_id, { root: true })
      commit('SET_CULTIVAR_ID', semeadura.cultivar_id)
    },
    async loadPrograma({ commit, state }) {
      const { data } = await api.programas.findBySemeadura(state.semeadura?.id)

      // TODO: move this logic to the page or component layer
      if (
        !data?.length ||
        (!data[0]?.programa && !data[0]?.execucoes?.length)
      ) {
        commit('SET_PROGRAMA', null)
        commit('SET_EXECUCOES', null)
      } else {
        commit('SET_PROGRAMA', data[0].programa)
        commit('SET_EXECUCOES', data[0].execucoes)
      }
    },
    async loadProgramasPlanejados({ commit, state, rootGetters }, varietyId) {
      commit('SET_IS_LOADING_PLANNED_PROGRAMS', true)
      const { start_period, end_period } =
        rootGetters['seasonsCrops/activePeriod']
      const currentVarietyId = state.cultivar?.id || varietyId
      try {
        const { data } = await api.programas.findByCultivar(
          currentVarietyId,
          start_period,
          end_period
        )
        commit('SET_PROGRAMAS_PLANEJADOS', data)
      } catch (error) {
        console.error(error)
      } finally {
        commit('SET_IS_LOADING_PLANNED_PROGRAMS', false)
      }
    },
    async importPrograma({ state, dispatch }, programaPlanejado) {
      if (!programaPlanejado) throw new Error('Programa indefinido')

      await api.programas.importToSemeadura(
        programaPlanejado.id,
        state.semeadura.id
      )

      await dispatch('loadPrograma')
    },
    async createPrograma({ state, dispatch }, programaNovo) {
      programaNovo.semeadura_id = state.semeadura.id
      programaNovo.data_prevista = state.semeadura.data_semeadura

      await api.programas.create(programaNovo)

      await dispatch('loadPrograma')
    },
    async deletePrograma({ state, commit }) {
      if (!state.programa) throw new Error('Programa indefinido')

      await api.programas.delete(state.programa.id)
      commit('SET_PROGRAMA', null)
    },
    async deleteExecucao({ commit, dispatch }, execucao) {
      if (!execucao) throw new Error('Execucao indefinida')

      await api.programas.execucoes.delete(execucao)

      // remove execucao da lista (optimistic)
      commit('REMOVE_EXECUCAO', execucao.id)

      await dispatch('loadPrograma')
    },
    async changeSemeadura({ commit, dispatch }, semeadura) {
      await commit('SET_SEMEADURA', semeadura)
      await commit('SET_CULTIVAR_ID', semeadura.cultivar_id)
      await dispatch('crops/setCurrentCrop', semeadura.crop_id, { root: true })

      await dispatch('loadPrograma')
    },
    async applySpray({ dispatch, commit }, { programId, ordinal, applied_at }) {
      await commit('SET_IS_LOADING_EXECUCOES', true)

      await api.programas.execucoes.applySpray({
        programId,
        ordinal,
        applied_at,
      })
      await dispatch('loadPrograma')

      await commit('SET_IS_LOADING_EXECUCOES', false)
    },
    async unapplySpray({ dispatch, commit }, { programId, ordinal }) {
      await commit('SET_IS_LOADING_EXECUCOES', true)

      await api.programas.execucoes.unapplySpray({
        programId,
        ordinal,
      })
      await dispatch('loadPrograma')

      await commit('SET_IS_LOADING_EXECUCOES', false)
    },
    async loadIrrigacoesSemeadura({ commit, state }) {
      const { data } = await api.irrigacoes.get(state.semeadura)

      if (!data) throw new Error('Fail to load Semeadura')

      await commit('SET_IS_LOADING_IRRIGACOES', true)
      await commit('SET_IRRIGACOES', data)
      await commit('SET_IS_LOADING_IRRIGACOES', false)
    },
    async createIrrigacaoSemeadura({ state, commit, dispatch }, irrigacao) {
      if (!irrigacao) throw new Error('Irrigacao indefinido')

      irrigacao.semeadura_id = state.semeadura.id

      await commit('SET_IS_LOADING_EXECUCOES', true)
      await commit('SET_IS_LOADING_IRRIGACOES', true)

      await api.irrigacoes.create(irrigacao)
      await dispatch('loadPrograma')
      await dispatch('loadIrrigacoesSemeadura')

      await commit('SET_IS_LOADING_EXECUCOES', false)
      await commit('SET_IS_LOADING_IRRIGACOES', false)
    },

    async updateIrrigacaoSemeadura({ state, commit, dispatch }, irrigacao) {
      if (!irrigacao) throw new Error('Irrigacao indefinida')

      irrigacao.semeadura_id = state.semeadura.id

      await commit('SET_IS_LOADING_EXECUCOES', true)
      await commit('SET_IS_LOADING_IRRIGACOES', true)

      await api.irrigacoes.update(irrigacao)

      await dispatch('loadPrograma')
      await dispatch('loadIrrigacoesSemeadura')

      await commit('SET_IS_LOADING_EXECUCOES', false)
      await commit('SET_IS_LOADING_IRRIGACOES', false)
    },
    async deleteIrrigacaoSemeadura({ commit, dispatch }, id) {
      if (!id) throw new Error('Irrigacao indefinida')

      await commit('SET_IS_LOADING_EXECUCOES', true)
      await commit('SET_IS_LOADING_IRRIGACOES', true)

      await api.irrigacoes.delete(id)

      await dispatch('loadPrograma')
      await dispatch('loadIrrigacoesSemeadura')

      await commit('SET_IS_LOADING_EXECUCOES', false)
      await commit('SET_IS_LOADING_IRRIGACOES', false)
    },
    resetStore({ commit }) {
      commit('SET_SEMEADURA', initialState().semeadura)
      commit('SET_SEMEADURAS', initialState().semeaduras)
      commit('SET_PROGRAMA', initialState().programa)
      commit('SET_PROGRAMAS_PLANEJADOS', initialState().programasPlanejados)
      commit('SET_EXECUCOES', initialState().execucoes)
      commit('SET_METEREOLOGIA', initialState().meteorologia)
      commit('SET_IS_LOADING_EXECUCOES', initialState().isLoadingExecucoes)
      commit('SET_IS_LOADING_IRRIGACOES', initialState().isLoadingIrrigacoes)
      commit('SET_IRRIGACOES', initialState().irrigacoes)
      commit(
        'SET_IS_LOADING_PLANNED_PROGRAMS',
        initialState().loadingPlannedProgram
      )
    },
  },
}
