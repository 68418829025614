<template>
  <v-menu
    close-on-content-click
    left
    offset-y
    transition="slide-y-transition"
    min-width="300px"
    max-height="400px"
    width="fit-content"
  >
    <template #activator="{ on, attrs }">
      <df-button
        v-bind="attrs"
        v-on="on"
        icon="chevron-down"
        plain
        variant="text-blue"
        :color-icon="colorIconSecondary"
        :width-slot="widthSlot"
      >
        {{ objectActual.name }}
      </df-button>
    </template>
    <card-dropdown
      :objects="objects"
      :text-button="textButton"
      :icon-button="iconButton"
      :title="title"
      :tooltip-one="tooltipOne"
      :tooltip-two="tooltipTwo"
      :icon-one="iconOne"
      :icon-two="iconTwo"
    />
  </v-menu>
</template>

<script>
import CardDropdown from '@/components/Cards/CardDropdown.vue'
import colors from '@/assets/styles/__colors.module.scss'
import DfButton from '@/lib/components/Button/DfButton.vue'

export default {
  name: 'SelectorDropdown',

  components: {
    DfButton,
    CardDropdown,
  },

  props: {
    iconButton: {
      default: '',
      type: String,
    },
    iconOne: {
      default: '',
      type: String,
    },
    objects: {
      required: true,
      type: Array,
    },
    objectActual: {
      required: true,
      type: Object,
    },
    iconTwo: {
      default: '',
      type: String,
    },
    textButton: {
      default: '',
      type: String,
    },
    title: {
      default: '',
      type: String,
    },
    tooltipOne: {
      default: '',
      type: String,
    },
    tooltipTwo: {
      default: '',
      type: String,
    },
    widthSlot: {
      default: '',
      type: String,
    },
  },

  data() {
    return {
      colorIconSecondary: colors.colorIconSecondary,
    }
  },
}
</script>
