import { getCurrentUser } from '@/services/__mocks__/helpers'
import { mockFlag } from '@/utils/mock'
import { APIFailureWrapper } from '@/utils/mock'
import {
  adminPermissions,
  engineerPermissions,
  freemiumPlanPermissions,
  fullPlanPermissions,
  lightPlanPermissions,
  observerPermissions,
  proPlanPermissions,
  resaleConsultant,
  researcherPermissions,
} from '@/modules/auth/mock/permissionSeeds'

const permissionAdminRoutes = [
  mockFlag(
    {
      method: 'get',
      url: '/permissions',
      result: () => {
        const currentUser = getCurrentUser()

        let permissions = observerPermissions
        switch (currentUser.user.user_profile.id) {
          case 2:
            permissions = adminPermissions
            break
          case 3:
            permissions = engineerPermissions
            break
          case 14:
            permissions = resaleConsultant
            break
          case 15:
            permissions = researcherPermissions
            break
        }

        switch (currentUser.user.plan.name) {
          case 'Freemium':
            permissions = freemiumPlanPermissions
            break
          case 'Light':
            permissions = lightPlanPermissions
            break
          case 'Pro':
            permissions = proPlanPermissions
            break
          case 'Full':
            permissions = fullPlanPermissions
            break
        }

        return APIFailureWrapper({
          content: permissions,
          errorMessage: 'Erro na listagem de permissões',
        })
      },
    },
    'on'
  ),
]

export { permissionAdminRoutes }
