// admin
import { authAdminRoutes } from '@/modules/auth/mock/authAdminRoutes.js'
import { authAdminNoAuthRoutes } from '@/modules/auth/mock/authAdminNoAuthRoutes.js'
import { menuAdminRoutes } from '@/modules/menu/mock/menuAdminRoutes'
import { permissionAdminRoutes } from '@/modules/auth/mock/permissionAdminRoutes.js'
// web
import { authRoutes } from '@/modules/auth/mock/authRoutes.js'
import { catalogRoutes } from '@/modules/catalog/mock/catalogRoutes.js'
import { cropsRoutes } from '@/services/__mocks__/cropsRoutes.js'
import { executionRoutes } from '@/modules/execution/mock/executionRoutes'
import { farmRoutes } from '@/modules/farm/mock/farmRoutes'
import { fieldRoutes } from '@/modules/plot/mock/fieldRoutes.js'
import { homeRoutes } from '@/modules/home/mock/homeRoutes.js'
import { menuRoutes } from '@/modules/menu/mock/menuRoutes.js'
import { locationRoutes } from '@/services/__mocks__/locationRoutes'
import { noteRoutes } from '@/modules/notes/mock/noteRoutes.js'
import { paymentRoutes } from '@/modules/payment/mock/paymentRoutes.js'
import { performanceRoutes } from '@/modules/performance/mock/performanceRoutes'
import { seasonAccountRoutes } from '@/services/__mocks__/seasonAccountRoutes'
import { seasonCropsRoutes } from '@/services/__mocks__/seasonCropsRoutes.js'
import { sowingRoutes } from '@/modules/sowing/mock/sowingRoutes.js'
import { usersRoutes } from '@/modules/users/mock/usersRoutes.js'
import { varietyRoutes } from '@/pages/cultivares/mock/varietyRoutes.js'

const adminApiRoutes = [
  ...authAdminRoutes,
  ...menuAdminRoutes,
  ...permissionAdminRoutes,
]

const adminNoAuthApiRoutes = [...authAdminNoAuthRoutes]

const coreApiRoutes = []

const webApiRoutes = [
  ...authRoutes,
  ...catalogRoutes,
  ...cropsRoutes,
  ...executionRoutes,
  ...farmRoutes,
  ...fieldRoutes,
  ...homeRoutes,
  ...menuRoutes,
  ...locationRoutes,
  ...noteRoutes,
  ...paymentRoutes,
  ...performanceRoutes,
  ...seasonAccountRoutes,
  ...seasonCropsRoutes,
  ...sowingRoutes,
  ...usersRoutes,
  ...varietyRoutes,
]

function globalAPIFailureWrapper(content, failureRate = 0) {
  const chance = Math.floor(Math.random() * 100)
  if (failureRate > chance) {
    throw new Error('Falha por conta causa do wrapper global do mock')
  }
  return content
}

let urlPrefix = 'http://localhost'
if (process.env.VUE_APP_API_URL.includes('sandbox'))
  urlPrefix = 'https://sandbox-api.digifarmz.com'

export const mockServer = {
  models: {},

  routes() {
    this.urlPrefix = urlPrefix
    this.timing = 400

    this.namespace = 'admin-api'
    adminApiRoutes.forEach((route) => {
      this[route.method](route.url, (_, request) =>
        globalAPIFailureWrapper(route.result(request), 0)
      )
    })

    this.namespace = 'public-admin-api'
    adminNoAuthApiRoutes.forEach((route) => {
      this[route.method](route.url, (_, request) =>
        globalAPIFailureWrapper(route.result(request), 0)
      )
    })

    this.namespace = 'core-api/api'
    coreApiRoutes.forEach((route) => {
      this[route.method](route.url, (_, request) =>
        globalAPIFailureWrapper(route.result(request), 0)
      )
    })

    this.namespace = 'web-api/api'
    webApiRoutes.forEach((route) => {
      this[route.method](route.url, (_, request) =>
        globalAPIFailureWrapper(route.result(request), 0)
      )
    })

    this.passthrough('https://maps.googleapis.com/**')
    this.passthrough('https://viacep.com.br/**')
    this.passthrough('https://sandbox-api.digifarmz.com/web-api/api/**')
    this.passthrough(`${process.env.VUE_APP_VINDI_URL}/**`)
    this.passthrough()
  },

  seeds() {},
}
