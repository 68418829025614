export default {
  Users: {
    email: 'Email',
    home: {
      configurations: 'Configurações',
      users: 'Usuários',
      register: 'Cadastrar',
    },
    list: {
      user: 'Usuário',
      status: 'Status',
    },
    list_item: {
      active: 'Ativo',
      inactive: 'Inativo',
      cancel: 'Cancelar',
      confirm: 'Confirmar',
      delete_confirm: 'Você tem certeza que deseja excluir este usuário?',
      delete_success: 'Usuário excluído com sucesso',
    },
    modal: {
      name: 'Nome',
      name_placeholder: 'Digite um nome',
      email_placeholder: 'Digite um email',
      profile: 'Perfil',
      profile_placeholder: 'Selecione um perfil',
      farms: 'Fazendas',
      farms_placeholder: 'Selecione as fazendas do usuário',
      add: 'Adicionar',
      edit: 'Editar',
      edit_user: 'Editar usuário',
      register_user: 'Adicionar usuário',
      register_success: 'Usuário registrado com sucesso',
      exceeded_users_title: 'Limite de usuários atingido',
      exceeded_users_description:
        'Você atingiu o limite de {0} usuários ativos na sua conta. Caso necessite de mais usuários na sua conta você pode contratar entrando em contato com o nosso time comercial. Ou desativando algum usuários para que você possa ativar um novo usuário.',
      comercial_whatsapp: 'Consultar comercial',
    },
    profiles: {
      ADMIN: 'Administrador Geral',
      CONTA: 'Administrador de Conta',
      FARM: 'Eng. Agronomo',
      ASSISTENTE_TECNICO: 'Observador',
      FINANCEIRO: 'Financeiro',
      COMERCIAL: 'Comercial',
      ADM_CORP: 'Administrador Corp',
      VEND_CORP: 'Vendedor Corp',
      VEND_DGF: 'Gestor de Cupons',
      TEC_DGF: 'Gestor Racionais',
      CON_REV: 'Consultor de Revenda',
      PESQUISA: 'Pesquisa',
      description: {
        ADMIN: 'Pode editar, visualizar e criar tudo',
        CONTA: 'Pode editar, visualizar e criar tudo',
        FARM: 'Pode editar tudo, exceto fazendas e talhões',
        ASSISTENTE_TECNICO: 'Somente visualização',
        FINANCEIRO: 'Financeiro',
        COMERCIAL: 'Comercial',
        ADM_CORP: 'Administrador Corp',
        VEND_CORP: 'Vendedor Corp',
        VEND_DGF: 'Gestor de Cupons',
        TEC_DGF: 'Gestor Racionais',
        CON_REV: 'Pode editar, visualizar e criar, exceto usuários',
        PESQUISA:
          'Pode visualizar usuários, criar e editar programas, semeaduras, fazendas e talhões',
      },
    },
  },
}
