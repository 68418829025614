import menuService from '@/modules/menu/services/menuService'
import usersService from '@/modules/users/services/usersService'

function initialState() {
  return {
    accountMaxUsers: null,
    country: null,
    loadingUsers: false,
    user: null,
    users: [],
  }
}

export default {
  namespaced: true,

  state: {
    initialState: initialState,
    accountMaxUsers: initialState().accountMaxUsers,
    country: initialState().country,
    loadingUsers: initialState().loadingUsers,
    user: initialState().user,
    users: initialState().users,
  },

  getters: {
    accountMaxUsers(state) {
      return state.accountMaxUsers
    },
    areaExceededAccount(state) {
      return state?.user?.plan?.priority_level == 4 && state.user?.used_area
    },
    contractedPlanLevel(state) {
      return state?.user?.plan.priority_level
    },
    currentUser(state) {
      return state.user
    },
    getCountry(state) {
      return state.country
    },
    isBrazil(state) {
      return state.country == 1
    },
    isUSA(state) {
      return state.country == 3
    },
    isAdmin(state) {
      return state.user?.is_admin
    },
    isFreemiumPlan(state) {
      return (
        state?.user?.plan?.name == 'Freemium' || state?.user?.plan?.id === 5
      )
    },
    isPlanLevelOne(state) {
      return state?.user?.plan?.priority_level == 1
    },
    isPlanLevelTwo(state) {
      return state?.user?.plan?.priority_level == 2
    },
    isPlanLevelThree(state) {
      return state?.user?.plan?.priority_level == 3
    },
    isPlanLevelFour(state) {
      return state?.user?.plan?.priority_level == 4
    },
    loadingUsers(state) {
      return state.loadingUsers
    },
    users(state) {
      return state.users
    },
  },

  mutations: {
    SET_ACCOUNT_MAX_USERS(state, usersQtt) {
      state.accountMaxUsers = usersQtt
    },
    SET_COUNTRY(state, country) {
      state.country = country
    },
    SET_LOADING_USERS(state, loading) {
      state.loadingUsers = loading
    },
    SET_USER(state, user) {
      state.user = user
    },
    SET_USERS(state, users) {
      state.users = users
    },
  },

  actions: {
    async changeUserStatus({ commit, state }, userId) {
      try {
        const isUserActive = state.users.find(
          (user) => user.id == userId
        ).active

        const params = {
          active: !isUserActive,
          userId: userId,
        }
        await usersService.updateUserActiveness(params)
        const users = state.users.map((user) => {
          if (user.id === userId) {
            user.active = !user.active
          }
          return user
        })

        commit('SET_USERS', users)
      } catch (error) {
        console.error(error)
        throw error
      }
    },
    async createUser({ dispatch }, params) {
      try {
        await usersService.createUser(params)
        dispatch('fetchUsers')
      } catch (error) {
        console.error(error)
        throw error
      }
    },
    async deleteUser({ dispatch }, userId) {
      try {
        await usersService.deleteUser(userId)
        dispatch('fetchUsers')
      } catch (error) {
        console.error(error)
        throw error
      }
    },
    async fetchUserData({ commit }, i18n) {
      try {
        const { data } = await menuService.usuario()
        commit('SET_USER', data)
        commit('SET_ACCOUNT_MAX_USERS', data.total_users)
        if (!localStorage.getItem('language')) {
          i18n.locale = data.language
          localStorage.setItem('language', data.language)
        }
        commit('SET_COUNTRY', data.account.country_id)
      } catch (error) {
        console.error(error)
        throw error
      }
    },
    async fetchUsers({ commit }) {
      commit('SET_LOADING_USERS', true)
      try {
        const { data } = await usersService.getUsers()
        commit('SET_USERS', data)
      } catch (error) {
        console.error(error)
        throw error
      } finally {
        commit('SET_LOADING_USERS', false)
      }
    },
    async updateUser({ dispatch }, params) {
      try {
        await usersService.updateUser(params)
        dispatch('fetchUsers')
      } catch (error) {
        console.error(error)
        throw error
      }
    },
    resetStore({ commit }) {
      commit('SET_ACCOUNT_MAX_USERS', initialState().accountMaxUsers)
      commit('SET_COUNTRY', initialState().country)
      commit('SET_LOADING_USERS', initialState().loadingUsers)
      commit('SET_USER', initialState().user)
      commit('SET_USERS', initialState().users)
    },
  },
}
