import Vuex from 'vuex'
import Vue from 'vue'

import { crops } from '@/store/crops'
import { cultivares } from '@/store/cultivares'
import { defensives } from '@/store/defensives'
import { execucoes } from '@/store/execucoes'
import { globalStore } from '@/store/globalStore'
import { integrations } from '@/store/integrations'
import { seasonsCrops } from '@/store/seasonsCrops'
import { semeaduras } from '@/store/semeaduras'

import apiManager from '@/store/apiManager'
import farms from '@/store/farms'
import freemium from '@/store/freemium'
import program from '@/store/program'

import catalog from '@/modules/catalog/store'
import layout from '@/modules/menu/store/layout'
import notes from '@/modules/notes/store/noteStore'
import permissions from '@/modules/auth/store/permissionStore'
import plans from '@/modules/plans/store/planStore'
import plot from '@/modules/plot/store/plotStore'
import user from '@/modules/users/store/usersStore'

Vue.use(Vuex)

export const modules = {
  apiManager,
  catalog,
  crops,
  cultivares,
  defensives,
  execucoes,
  farms,
  freemium,
  globalStore,
  integrations,
  layout,
  notes,
  permissions,
  plans,
  plot,
  program,
  seasonsCrops,
  semeaduras,
  user,
}

export default new Vuex.Store({
  modules,
})
