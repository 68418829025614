export const isSowingInCurrentSeasons = (sowing, seasonsCrops) => {
  const sowingEmergenceDate = new Date(sowing.data_emergencia)

  return seasonsCrops.some((seasonCrop) => {
    const seasonStartDate = new Date(seasonCrop.start_period)
    const seasonEndDate = new Date(seasonCrop.end_period)

    return (
      sowingEmergenceDate > seasonStartDate &&
      sowingEmergenceDate < seasonEndDate &&
      seasonCrop.season_active &&
      sowing.crop_id == seasonCrop.crop_id
    )
  })
}
