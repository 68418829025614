<template>
  <div class="app-wrapper">
    <d-tour />
    <app-menu />
    <loader v-if="isLoading" />
    <v-main v-else :style="{ background: colorBackgroundLight }">
      <router-view />
    </v-main>

    <loader-overlay />
    <main-alert />

    <modal-first-login
      v-if="isModalFirstLoginOpened"
      first-login
      @close="closeModalFirstLogin"
    />

    <modal-full-access
      v-if="accountId && dialogFullAccess"
      :account-id="accountId"
      :dialog="dialogFullAccess"
      @close="closeModalFullAccess"
      @config="handleConfig"
    />

    <modal-job-title v-if="!hasjobTitle" @close="fetchUserData" />

    <div class="warnings">
      <warning-area-exceeded-account v-if="areaExceededAccount" />
      <warning-farm-disabled v-if="currentFarm && !currentFarm.enabled" />
      <warning-password-recovery v-if="shouldShowWarning" />
    </div>
    <help-button />
  </div>
</template>

<script>
import Cookies from 'js-cookie'
import Vue from 'vue'
import Hotjar from 'vue-hotjar'
import { mapGetters, mapActions } from 'vuex'
import {
  initialize,
  logEvent,
  events,
  identifyUser,
} from '@/services/analytics'

import AppMenu from '@/modules/menu/views/AppMenu.vue'
import colors from '@/assets/styles/__colors.module.scss'
import DTour from '@/components/DTour'
import HelpButton from '@/components/HelpButton.vue'
import LayoutMixin from '@/components/LayoutMixin.vue'
import Loader from '@/components/Loaders/Loader.vue'
import LoaderOverlay from '@/components/LoaderOverlay.vue'
import seasonsCropsStorage from '@/services/storage/seasonsCropsStorage.js'
const MainAlert = () => import('@/components/MainAlert.vue')
const ModalFirstLogin = () =>
  import('@/modules/menu/components/modals/ModalSenha.vue')
const ModalFullAccess = () =>
  import('@/components/ModalFullAccess/ModalFullAccess.vue')
const ModalJobTitle = () =>
  import('@/modules/menu/components/modals/ModalJobTitle.vue')
const WarningAreaExceededAccount = () =>
  import(
    /* webpackChunkName: "warnings" */ '@/components/Warnings/WarningAreaExceededAccount.vue'
  )
const WarningFarmDisabled = () =>
  import(
    /* webpackChunkName: "warnings" */ '@/components/Warnings/WarningFarmDisabled.vue'
  )
const WarningPasswordRecovery = () =>
  import(
    /* webpackChunkName: "warnings" */ '@/components/Warnings/WarningPasswordRecovery.vue'
  )

export default {
  name: 'App',

  mixins: [LayoutMixin],

  components: {
    AppMenu,
    DTour,
    HelpButton,
    Loader,
    LoaderOverlay,
    MainAlert,
    ModalFirstLogin,
    ModalJobTitle,
    ModalFullAccess,
    WarningAreaExceededAccount,
    WarningFarmDisabled,
    WarningPasswordRecovery,
  },

  data() {
    return {
      accountId: null,
      colorBackgroundLight: colors.colorBackgroundLight,
      dialogFullAccess: false,
      isLoading: false,
      isModalFirstLoginOpened: false,
    }
  },

  async created() {
    this.isLoading = true
    await this.fetchUserData(this.$root.$i18n)
    await this.urlRedirect()
    await this.fetchCrops()
    await this.fetchSeasonsCrops(this.currentFarmId)
    await this.loadCurrentCropByStorage()
    await this.loadSeasonCrops()
    await this.fetchPermissions()

    if (this.isFreemiumPlan) {
      try {
        await this.fetchFreemiumLimit()
      } catch (error) {
        console.error(error)
      }
    }

    this.isLoading = false

    initialize(this.currentUser?.id)

    this.configOneSignal()

    if (Cookies.get('token')) {
      identifyUser({
        profile_id: this.currentUser?.user_profile?.id,
        profile_name: this.currentUser?.user_profile?.name,
        account_type_id: this.currentUser?.account?.account_type_id,
        account_type_name: this.currentUser?.account?.account_type_name,
        job_title_id: this.currentUser?.job_title?.id,
        job_title_name: this.currentUser?.job_title?.name,
        user_plan: this.currentUser?.plan?.name,
        screen_size: this.$vuetify.breakpoint.name,
      })
      logEvent(events.usersModule.login, this.currentUser?.account)
    }

    if (
      process.env.VUE_APP_HOTJAR_ENABLE == 'true' &&
      process.env.VUE_APP_HOTJAR_ID
    ) {
      Vue.use(Hotjar, {
        id: process.env.VUE_APP_HOTJAR_ID,
        isProduction: process.env.VUE_APP_HOTJAR_ENABLE == 'true',
      })
    }

    this.isModalFirstLoginOpened = this.currentUser?.first_login === 'Y'

    if (!this.isModalFirstLoginOpened) {
      const isFirstLoginFullPlan =
        Cookies.get('first_login_full_plan') === 'true'

      if (isFirstLoginFullPlan) {
        if (this.currentUser.id) {
          this.accountId = this.currentUser.account.id
          this.dialogFullAccess = true
        }
      }
    }
  },

  watch: {
    isModalFirstLoginOpened(newValue) {
      if (!newValue) {
        const isFirstLoginFullPlan =
          Cookies.get('first_login_full_plan') === 'true'

        if (isFirstLoginFullPlan) {
          this.dialogFullAccess = true
        }
      }
    },

    currentUser(newUser) {
      if (window.Conpass && newUser?.account?.name && newUser?.email) {
        window.Conpass.init({
          name: newUser.account?.name,
          email: newUser.email,
        })
      }
      if (this.$hj) {
        // FIXME: while we don't get Hotjar's business plan, it'll allows to identify users for NPS survey
        this.$router
          .replace({
            path: this.$route.path,
            query: { ...this.$route.query, u: newUser.id },
          })
          // FIXME: this avoid VueRouter to throw exception because we're replacing to same path
          .catch(() => {})
      }
    },
  },

  computed: {
    ...mapGetters('apiManager', ['getApiError']),
    ...mapGetters('user', [
      'currentUser',
      'isFreemiumPlan',
      'areaExceededAccount',
    ]),
    ...mapGetters('crops', ['currentCrop']),
    ...mapGetters('permissions', ['hasPermission']),
    ...mapGetters('seasonsCrops', ['seasonsCrops']),
    ...mapGetters('farms', ['currentFarm']),
    hasjobTitle() {
      return this.currentUser?.job_title !== null
    },
    shouldShowWarning() {
      return (
        this.currentUser &&
        this.currentUser.password_type === 'recovery_temp_password'
      )
    },
  },

  methods: {
    ...mapActions('farms', ['firstFarmLoad']),
    ...mapActions('user', ['fetchUserData']),
    ...mapActions('permissions', ['fetchPermissions']),
    ...mapActions('freemium', ['fetchFreemiumLimit']),
    ...mapActions('seasonsCrops', [
      'fetchSeasonsCrops',
      'setCurrentSeasonCrop',
    ]),
    ...mapActions('crops', ['fetchCrops', 'loadCurrentCropByStorage']),

    async urlRedirect() {
      await this.firstFarmLoad(this.$route?.query?.fazenda_id)
      if (this.currentFarm?.id) {
        return this.$router.replace({
          query: { ...this.$route.query, fazenda_id: this.currentFarm?.id },
        })
      }
    },

    configOneSignal() {
      this.$OneSignal.showSlidedownPrompt()
      this.$OneSignal.sendTag('environment', process.env.NODE_ENV)
      this.$OneSignal.sendTag('account', this.currentUser.account.id)
      this.$OneSignal.sendTag('user_id', this.currentUser.id)
      this.$OneSignal.setExternalUserId('' + this.currentUser.id)
    },
    async loadSeasonCrops() {
      const storedSeasonCrop = seasonsCropsStorage()
        .crop(this.currentCrop?.id)
        .get()

      await this.setCurrentSeasonCrop(storedSeasonCrop ?? this.seasonsCrops[0])
    },

    closeModalFirstLogin() {
      this.isModalFirstLoginOpened = false
    },
    closeModalFullAccess() {
      this.dialogFullAccess = false
    },

    handleConfig() {
      this.closeModalFullAccess()
    },
  },
}
</script>

<style lang="scss" scoped>
.app-wrapper {
  min-height: 100vh;
}
.v-main {
  min-height: 100%;
  background-color: $color-background-white;
}
.warnings {
  position: fixed;
  top: 56px;
  right: 0px;
  width: calc(100% - 56px);
  height: auto;
  display: flex;
  flex-direction: column;
  z-index: 2;

  @include d(m) {
    width: 100%;
  }
}
</style>
