import { fields, ndvis } from '@/modules/plot/mock/fieldsSeeds'
import { sowings } from '@/modules/sowing/mock/sowingSeeds'
import {
  accountFarmIds,
  accountFieldArea,
  currentAccount,
} from '@/services/__mocks__/helpers'
import { mockFlag } from '@/utils/mock'
import { APIFailureWrapper } from '@/utils/mock'
import moment from 'moment'

const fieldRoutes = [
  mockFlag(
    {
      method: 'get',
      url: '/talhoes/:farm_id',
      result: ({ params }) => {
        const filteredFields = fields.reduce((acc, field) => {
          if (
            field.fazenda_id == params.farm_id &&
            field.enabled &&
            !field.deleted_at
          ) {
            acc.push({
              area: field.area,
              area_calculada: field.area_calculada,
              clay_soil: field.clay_soil,
              coordenadas_lat: field.coordenadas_lat,
              coordenadas_lng: field.coordenadas_lng,
              created_at: field.created_at,
              deleted_at: field.deleted_at,
              elevation: field.elevation,
              enabled: field.enabled,
              enabled_ndvi: field.enabled_ndvi,
              enabled_sowing: field.enabled_sowing,
              fazenda_id: field.fazenda_id,
              id: field.id,
              latitude: field.latitude,
              longitude: field.longitude,
              ndviEnabled: field.ndviEnabled,
              nome: field.nome,
              soil_type_id: field.soil_type_id,
              updated_at: field.updated_at,
            })
          }
          return acc
        }, [])

        return APIFailureWrapper({
          content: filteredFields,
          errorMessage: 'Erro na listagem dos talhões',
        })
      },
    },
    'on'
  ),
  mockFlag(
    {
      method: 'get',
      url: '/talhoes/:field_id/fazenda/:farm_id',
      result: ({ params }) => {
        const filteredFields = fields.filter(
          (field) => field.id == params.field_id
        )

        return APIFailureWrapper({
          content: filteredFields,
          errorMessage: 'Erro na listagem do talhão por id',
        })
      },
    },
    'on'
  ),
  mockFlag(
    {
      method: 'get',
      url: '/accounts/:account_id/allPlots',
      result: () => {
        const accountFields = fields.reduce((acc, field) => {
          if (accountFarmIds(currentAccount.id).includes(field.fazenda_id)) {
            acc.push(field)
          }
          return acc
        }, [])

        const currentArea = accountFieldArea({ accountId: currentAccount.id })
        const totalArea = currentAccount.total_area_account

        const response = {
          info_area: {
            remaining: totalArea - currentArea,
            total: totalArea,
          },
          plots: accountFields.reduce((acc, field) => {
            if (field.enabled) {
              acc.push({
                area: field.area,
                enabled: field.enabled,
                enabled_ndvi: field.ndviEnabled,
                enabled_sowing: sowings.filter(
                  (sowing) => sowing.talhao_id == field.id && sowing.active
                ).length
                  ? 1
                  : 0,
                farm_id: field.fazenda_id,
                id: field.id,
                name: field.nome,
              })
            }
            return acc
          }, []),
        }

        return APIFailureWrapper({
          content: response,
          errorMessage: 'Erro na listagem de todos talhões da conta',
        })
      },
    },
    'on'
  ),
  mockFlag(
    {
      method: 'get',
      url: '/accounts/plots-area',
      result: () => {
        return APIFailureWrapper({
          content: accountFieldArea({ accountId: currentAccount.id }),
          errorMessage: 'Erro na busca do total de área de talhões da conta',
        })
      },
    },
    'on'
  ),
  mockFlag(
    {
      method: 'post',
      url: '/talhoes',
      result: ({ requestBody }) => {
        const responseObj = JSON.parse(requestBody)
        responseObj.id = fields.length + 1
        responseObj.enabled = 1
        responseObj.area_calculada = null
        responseObj.clay_soil = 0
        responseObj.created_at = '2024-01-01 01:01:01'
        responseObj.deleted_at = null
        responseObj.elevation = 1
        responseObj.ndviEnabled = 0
        responseObj.soil_type_id = null
        responseObj.updated_at = '2024-01-01 01:01:01'

        fields.push(responseObj)
        return APIFailureWrapper({
          content: null,
          errorMessage: 'Erro na criação do talhão',
        })
      },
    },
    'on'
  ),
  mockFlag(
    {
      method: 'post',
      url: '/ndvi',
      result: ({ requestBody }) => {
        const responseObj = JSON.parse(requestBody)
        const startDate = moment(responseObj.dates[0])
        const endDate = moment(responseObj.dates[1])

        const filteredNdvis = ndvis.filter((ndvi) => {
          const ndviDate = moment(ndvi.date)
          const ndviDateOnly = ndviDate.startOf('day')
          const isBetween = ndviDateOnly.isBetween(
            startDate,
            endDate,
            undefined,
            '[]'
          )
          return ndvi.field_id == responseObj.plot_id && isBetween
        })
        return APIFailureWrapper({
          content: filteredNdvis,
          errorMessage: 'Erro na listagem de NDVIs',
        })
      },
    },
    'on'
  ),
  mockFlag(
    {
      method: 'delete',
      url: '/talhoes/:field_id',
      result: ({ params }) => {
        fields.forEach((field) => {
          if (field.id === Number(params.field_id))
            field.deleted_at = '2024-01-04 01:01:01'
        })
        return APIFailureWrapper({
          content: null,
          errorMessage: 'Erro na deleção do talhão',
        })
      },
    },
    'on'
  ),
]

export { fieldRoutes }
