<template>
  <div class="df-flex-l df-flex-col account-configs-dropdown">
    <div class="df-flex-m df-flex-col">
      <h2 class="dropdown__title">
        {{ $t('Menu.users') }}
      </h2>
      <div
        class="df-flex-l align-center dropdown__item"
        data-v-step="user-menu"
        :class="shouldActivateUsersManagement ? '' : 'disabled'"
      >
        <font-awesome-icon
          icon="users"
          :color="
            shouldActivateUsersManagement ? colorIconNeutral : colorIconDisabled
          "
        />
        <p class="item__text" @click="redirectToUsers">
          {{ $t('Menu.gerenciar_usuarios') }}
        </p>
      </div>
    </div>

    <div v-if="shouldShowAccountSwitcher" class="df-flex-m df-flex-col">
      <h2 class="dropdown__title">
        {{ accountName }}
      </h2>
      <v-menu bottom left offset-x>
        <template #activator="{ on }">
          <div v-on="on" class="df-flex-l align-center dropdown__item">
            <font-awesome-icon icon="sync" :color="colorIconNeutral" />
            <p class="item__text">
              {{ $t('Menu.alterar_conta') }}
            </p>
          </div>
        </template>
        <div class="df-flex-m df-flex-col item__submenu">
          <div
            v-for="(account, accountId) in accounts"
            :key="accountId"
            class="submenu__item"
          >
            <p class="item__text" @click="changeAccount(accountId)">
              {{ account }}
            </p>
          </div>
        </div>
      </v-menu>
    </div>

    <div class="df-flex-m df-flex-col">
      <h2 class="dropdown__title">
        {{ $t('Menu.about_the_user') }}
      </h2>
      <div
        class="df-flex-l align-center dropdown__item"
        data-v-step="user-menu"
      >
        <font-awesome-icon icon="vector-square" :color="colorIconNeutral" />
        <p class="item__text" @click="openConfigAreaModal">
          {{ $t('Menu.config_area') }}
        </p>
      </div>

      <v-menu bottom left offset-x>
        <template #activator="{ on }">
          <div v-on="on" class="df-flex-l align-center dropdown__item">
            <font-awesome-icon icon="language" :color="colorIconNeutral" />
            <p class="item__text">
              {{ $t('Menu.alterar_idioma') }}
            </p>
          </div>
        </template>
        <div class="df-flex-m df-flex-col item__submenu">
          <div
            v-for="(idioma, index) in $idiomas"
            :key="index"
            class="submenu__item"
          >
            <p class="item__text" @click="changeLanguage(idioma.sigla)">
              {{ $t('language.' + idioma.sigla) }}
            </p>
          </div>
        </div>
      </v-menu>

      <div class="df-flex-l align-center dropdown__item">
        <font-awesome-icon icon="key" :color="colorIconNeutral" />
        <p class="item__text" @click="openPasswordModal">
          {{ $t('Menu.trocar_senha') }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import colors from '@/assets/styles/__colors.module.scss'
import Cookies from 'js-cookie'
import menuService from '@/modules/menu/services/menuService'
import PermissionMixin from '@/components/Permission/PermissionMixin.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'AccountConfigsDropdown',

  mixins: [PermissionMixin],

  data() {
    return {
      accounts: {},
      colorIconDisabled: colors.colorIconDisabled,
      colorIconNeutral: colors.colorIconNeutral,
    }
  },

  async created() {
    this.fetchAccounts()
  },

  computed: {
    ...mapGetters('user', ['currentUser']),
    accountName() {
      return this.currentUser?.account?.name || this.$t('report.account')
    },
    shouldShowAccountSwitcher() {
      // This is a temporary solution to hide the account switcher
      return Object.keys(this.accounts).length && false
    },
    shouldActivateUsersManagement() {
      return this.hasPermission(this.$p.user.list)
    },
  },

  methods: {
    async changeAccount(accountId) {
      this.$root.$emit('loaderShow')
      try {
        const response = await this.$api.menu.changeAccount(accountId)

        if (response && response.data.token) {
          const token = response.data.token

          Cookies.set('token', token)

          const accounts = response.data.accounts

          if (accounts.length === 1) {
            await this.getAccountSelected(accounts[0].id)
          } else {
            this.$emit('logged-in', this.loginAPIResponse)
          }
        }

        window.location.reload()
      } catch (e) {
        this.$root.$emit('loaderHide')
      }
    },
    async changeLanguage(language) {
      this.$root.$i18n.locale = language
      localStorage.setItem('language', language)
    },
    async fetchAccounts() {
      try {
        const { data } = await menuService.contas()
        this.accounts = data.contas
      } catch (error) {
        console.error(error)
      }
    },
    async getAccountSelected(accountId) {
      try {
        await this.$api.auth.selectAccount(accountId)
        this.$emit('logged-in', this.loginAPIResponse)
        this.$router.push({
          path: '/my_farm',
        })
      } catch (error) {
        console.error(error)
      }
    },
    openConfigAreaModal() {
      this.$emit('open-config-area-modal')
    },
    openPasswordModal() {
      this.$emit('open-password-modal')
    },
    redirectToUsers() {
      if (this.shouldActivateUsersManagement)
        return this.$router.push({
          path: '/users',
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.account-configs-dropdown {
  min-width: 200px;
  padding: $spacing-m;
  background-color: $color-background-white;

  .dropdown__title {
    @include label-x-small;
    color: $color-text-disabled;
    text-transform: uppercase;
  }
  .dropdown__item {
    min-height: $spacing-xl;

    &.disabled {
      p {
        color: $color-text-disabled;
        cursor: auto;
      }
    }
  }
}
.item__text {
  @include label-small;
  color: $color-text-secondary;
  transition: color 0.3s ease;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover {
    color: $color-text-primary;
  }
}
.item__submenu {
  padding: $spacing-m;
  background-color: $color-background-white;

  .submenu__item {
    height: $spacing-xl;
  }
}
</style>
