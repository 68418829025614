import {
  farmClima,
  farmPrecipitation,
  farmWeather,
  farms,
} from '@/modules/farm/mock/farmSeeds.js'
import { mockFlag } from '@/utils/mock'
import { APIFailureWrapper } from '@/utils/mock'
import { getCurrentUser } from '@/services/__mocks__/helpers'
import { varieties } from '@/pages/cultivares/mock/varietySeeds'
import { fields } from '@/modules/plot/mock/fieldsSeeds'

const farmRoutes = [
  mockFlag(
    {
      method: 'get',
      url: '/farms',
      result: () => {
        const currentUser = getCurrentUser()
        const filteredFarms = farms.filter(
          (farm) => farm.enabled && farm.account_id == currentUser.user.account
        )

        return APIFailureWrapper({
          content: filteredFarms,
          errorMessage: 'Erro na listagem de fazendas',
        })
      },
    },
    'on'
  ),
  mockFlag(
    {
      method: 'get',
      url: '/fazendas/:farm_id',
      result: ({ params }) => {
        const filteredFarm = farms.find((farm) => farm.id && params.farm_id)

        const response = {
          area: filteredFarm.total_area,
          area_calculada: fields.reduce((acc, field) => {
            if (field.fazenda_id == filteredFarm.id) {
              acc += field.area
            }
            return acc
          }, 0),
          ativo: filteredFarm.enabled,
          bag_price: filteredFarm.bag_price,
          conta_id: filteredFarm.account_id,
          coordenadas_lat: null,
          coordenadas_lng: null,
          created_at: filteredFarm.created_at,
          cultivares: varieties.slice(0, 7),
          deleted_at: filteredFarm.deleted_at,
          elevation: null,
          enabled: true,
          estado_id: filteredFarm.location.state.id,
          fazenda_origem: null,
          id: filteredFarm.id,
          latitude: filteredFarm.location.latitude,
          longitude: filteredFarm.location.longitude,
          macro_regiao_id: null,
          municipio: filteredFarm.location.city.name,
          municipio_id: filteredFarm.location.city.id,
          nome: filteredFarm.name,
          pais: filteredFarm.location.country.name,
          pais_id: filteredFarm.location.country.id,
          safra_id: 1,
          semeadura_epoca: null,
          sementeiro: 0,
          total_talhoes: filteredFarm.total_plots,
          uf: filteredFarm.location.state.acronym,
          updated_at: filteredFarm.updated_at,
          valor_saca: filteredFarm.bag_price[0].price,
          vazio_sanitario: null,
        }

        return APIFailureWrapper({
          content: response,
          errorMessage: 'Erro ao buscar a fazenda específica',
        })
      },
    },
    'on'
  ),
  mockFlag(
    {
      method: 'get',
      url: '/farms/:id/weather',
      result: () => {
        // se precisar no futuro, filtra um clima específico pra fazenda
        return APIFailureWrapper({
          content: farmWeather,
          errorMessage: 'Erro ao buscar fazendas',
        })
      },
    },
    'on'
  ),
  mockFlag(
    {
      method: 'get',
      url: '/fazendas/:id/clima',
      result: () => {
        // se precisar no futuro, filtra um clima específico pra fazenda
        return APIFailureWrapper({
          content: farmClima,
          errorMessage: 'Erro ao buscar fazendas',
        })
      },
    },
    'on'
  ),
  mockFlag(
    {
      method: 'get',
      url: '/fazendas/:id/precipitation',
      result: () => {
        // se precisar no futuro, filtra um clima específico pra fazenda
        return APIFailureWrapper({
          content: farmPrecipitation,
          errorMessage: 'Erro ao buscar fazendas',
        })
      },
    },
    'on'
  ),
]
export { farmRoutes }
