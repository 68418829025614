import { getCustomDate } from '@/utils/time'

const countries = [
  {
    id: 1,
    acronym: 'BR',
    name: 'Brasil',
    latitude: '-29.782415680663',
    longitude: '-55.806706269753',
  },
]

const states = [
  {
    id: 1,
    codigoUf: 12,
    nome: 'Acre',
    uf: 'AC',
    created_at: getCustomDate(-180),
    updated_at: getCustomDate(-180),
    deleted_at: null,
    area_total: '16412304.00',
    pais_id: countries[0].id,
  },
  {
    id: 2,
    codigoUf: 27,
    nome: 'Alagoas',
    uf: 'AL',
    created_at: getCustomDate(-180),
    updated_at: getCustomDate(-180),
    deleted_at: null,
    area_total: '2777850.60',
    pais_id: countries[0].id,
  },
  {
    id: 3,
    codigoUf: 16,
    nome: 'Amapá',
    uf: 'AP',
    created_at: getCustomDate(-180),
    updated_at: getCustomDate(-180),
    deleted_at: null,
    area_total: '14282852.10',
    pais_id: countries[0].id,
  },
  {
    id: 4,
    codigoUf: 13,
    nome: 'Amazonas',
    uf: 'AM',
    created_at: getCustomDate(-180),
    updated_at: getCustomDate(-180),
    deleted_at: null,
    area_total: '155915914.80',
    pais_id: countries[0].id,
  },
  {
    id: 5,
    codigoUf: 29,
    nome: 'Bahia',
    uf: 'BA',
    created_at: getCustomDate(-180),
    updated_at: getCustomDate(-180),
    deleted_at: null,
    area_total: '56473317.70',
    pais_id: countries[0].id,
  },
  {
    id: 6,
    codigoUf: 23,
    nome: 'Ceará',
    uf: 'CE',
    created_at: getCustomDate(-180),
    updated_at: getCustomDate(-180),
    deleted_at: null,
    area_total: '14892047.20',
    pais_id: countries[0].id,
  },
  {
    id: 7,
    codigoUf: 53,
    nome: 'Distrito Federal',
    uf: 'DF',
    created_at: getCustomDate(-180),
    updated_at: getCustomDate(-180),
    deleted_at: null,
    area_total: '577999.90',
    pais_id: countries[0].id,
  },
  {
    id: 8,
    codigoUf: 32,
    nome: 'Espírito Santo',
    uf: 'ES',
    created_at: getCustomDate(-180),
    updated_at: getCustomDate(-180),
    deleted_at: null,
    area_total: '4609558.30',
    pais_id: countries[0].id,
  },
  {
    id: 9,
    codigoUf: 52,
    nome: 'Goiás',
    uf: 'GO',
    created_at: getCustomDate(-180),
    updated_at: getCustomDate(-180),
    deleted_at: null,
    area_total: '34011178.30',
    pais_id: countries[0].id,
  },
  {
    id: 10,
    codigoUf: 21,
    nome: 'Maranhão',
    uf: 'MA',
    created_at: getCustomDate(-180),
    updated_at: getCustomDate(-180),
    deleted_at: null,
    area_total: '33193745.00',
    pais_id: countries[0].id,
  },
  {
    id: 11,
    codigoUf: 51,
    nome: 'Mato Grosso',
    uf: 'MT',
    created_at: getCustomDate(-180),
    updated_at: getCustomDate(-180),
    deleted_at: null,
    area_total: '90336619.20',
    pais_id: countries[0].id,
  },
  {
    id: 12,
    codigoUf: 50,
    nome: 'Mato Grosso do Sul',
    uf: 'MS',
    created_at: getCustomDate(-180),
    updated_at: getCustomDate(-180),
    deleted_at: null,
    area_total: '35714553.20',
    pais_id: countries[0].id,
  },
  {
    id: 13,
    codigoUf: 31,
    nome: 'Minas Gerais',
    uf: 'MG',
    created_at: getCustomDate(-180),
    updated_at: getCustomDate(-180),
    deleted_at: null,
    area_total: '58652212.20',
    pais_id: countries[0].id,
  },
  {
    id: 14,
    codigoUf: 15,
    nome: 'Pará',
    uf: 'PA',
    created_at: getCustomDate(-180),
    updated_at: getCustomDate(-180),
    deleted_at: null,
    area_total: '124795466.60',
    pais_id: countries[0].id,
  },
  {
    id: 15,
    codigoUf: 25,
    nome: 'Paraíba',
    uf: 'PB',
    created_at: getCustomDate(-180),
    updated_at: getCustomDate(-180),
    deleted_at: null,
    area_total: '5658500.00',
    pais_id: countries[0].id,
  },
  {
    id: 16,
    codigoUf: 41,
    nome: 'Paraná',
    uf: 'PR',
    created_at: getCustomDate(-180),
    updated_at: getCustomDate(-180),
    deleted_at: null,
    area_total: '19930792.20',
    pais_id: countries[0].id,
  },
  {
    id: 17,
    codigoUf: 26,
    nome: 'Pernambuco',
    uf: 'PE',
    created_at: getCustomDate(-180),
    updated_at: getCustomDate(-180),
    deleted_at: null,
    area_total: '9831161.60',
    pais_id: countries[0].id,
  },
  {
    id: 18,
    codigoUf: 22,
    nome: 'Piauí',
    uf: 'PI',
    created_at: getCustomDate(-180),
    updated_at: getCustomDate(-180),
    deleted_at: null,
    area_total: '25157773.80',
    pais_id: countries[0].id,
  },
  {
    id: 19,
    codigoUf: 33,
    nome: 'Rio de Janeiro',
    uf: 'RJ',
    created_at: getCustomDate(-180),
    updated_at: getCustomDate(-180),
    deleted_at: null,
    area_total: '4378017.20',
    pais_id: countries[0].id,
  },
  {
    id: 20,
    codigoUf: 24,
    nome: 'Rio Grande do Norte',
    uf: 'RN',
    created_at: getCustomDate(-180),
    updated_at: getCustomDate(-180),
    deleted_at: null,
    area_total: '5281104.70',
    pais_id: countries[0].id,
  },
  {
    id: 21,
    codigoUf: 43,
    nome: 'Rio Grande do Sul',
    uf: 'RS',
    created_at: getCustomDate(-180),
    updated_at: getCustomDate(-180),
    deleted_at: null,
    area_total: '28173022.30',
    pais_id: countries[0].id,
  },
  {
    id: 22,
    codigoUf: 11,
    nome: 'Rondônia',
    uf: 'RO',
    created_at: getCustomDate(-180),
    updated_at: getCustomDate(-180),
    deleted_at: null,
    area_total: '23759054.70',
    pais_id: countries[0].id,
  },
  {
    id: 23,
    codigoUf: 14,
    nome: 'Roraima',
    uf: 'RR',
    created_at: getCustomDate(-180),
    updated_at: getCustomDate(-180),
    deleted_at: null,
    area_total: '22430050.60',
    pais_id: countries[0].id,
  },
  {
    id: 24,
    codigoUf: 42,
    nome: 'Santa Catarina',
    uf: 'SC',
    created_at: getCustomDate(-180),
    updated_at: getCustomDate(-180),
    deleted_at: null,
    area_total: '9573616.50',
    pais_id: countries[0].id,
  },
  {
    id: 25,
    codigoUf: 35,
    nome: 'São Paulo',
    uf: 'SP',
    created_at: getCustomDate(-180),
    updated_at: getCustomDate(-180),
    deleted_at: null,
    area_total: '24822236.20',
    pais_id: countries[0].id,
  },
  {
    id: 26,
    codigoUf: 28,
    nome: 'Sergipe',
    uf: 'SE',
    created_at: getCustomDate(-180),
    updated_at: getCustomDate(-180),
    deleted_at: null,
    area_total: '2191511.60',
    pais_id: countries[0].id,
  },
  {
    id: 27,
    codigoUf: 17,
    nome: 'Tocantins',
    uf: 'TO',
    created_at: getCustomDate(-180),
    updated_at: getCustomDate(-180),
    deleted_at: null,
    area_total: '27772052.00',
    pais_id: countries[0].id,
  },
]

const cities = [
  // Acre
  {
    id: 1,
    codigo: 13,
    nome: 'Acrelândia',
    uf: states[0].uf,
    created_at: getCustomDate(-180),
    updated_at: getCustomDate(-180),
    deleted_at: null,
    state: states[0],
    country: countries[0],
    regiao_sojicola: '402',
    vcu: '4',
    longitude: '-67.0523165800206',
    latitude: '-10.07379389',
    elevation: '205.9865875',
  },
  // Alagoas
  {
    id: 100,
    codigo: 102,
    nome: 'Água Branca',
    uf: states[1].uf,
    created_at: getCustomDate(-180),
    updated_at: getCustomDate(-180),
    deleted_at: null,
    state: states[1],
    country: countries[0],
    regiao_sojicola: '501',
    vcu: '4',
    longitude: '-37.940715175964996',
    latitude: '-9.267025497',
    elevation: '630.4493408',
  },
  // Amapá
  {
    id: 200,
    codigo: 105,
    nome: 'Amapá',
    uf: states[2].uf,
    created_at: getCustomDate(-180),
    updated_at: getCustomDate(-180),
    deleted_at: null,
    state: states[2],
    country: countries[0],
    regiao_sojicola: '503',
    vcu: '4',
    longitude: '-50.7932144702699',
    latitude: '2.053921245',
    elevation: '11.48569965',
  },
  // Amazonas
  {
    id: 300,
    codigo: 29,
    nome: 'Alvarães',
    uf: states[3].uf,
    created_at: getCustomDate(-180),
    updated_at: getCustomDate(-180),
    deleted_at: null,
    state: states[3],
    country: countries[0],
    regiao_sojicola: '503',
    vcu: '4',
    longitude: '-64.8135346775686',
    latitude: '-3.2165837689999996',
    elevation: '48.14355087',
  },
  // Bahia
  {
    id: 400,
    codigo: 108,
    nome: 'Abaíra',
    uf: states[4].uf,
    created_at: getCustomDate(-180),
    updated_at: getCustomDate(-180),
    deleted_at: null,
    state: states[4],
    country: countries[0],
    regiao_sojicola: '405',
    vcu: '4',
    longitude: '-41.661608480236',
    latitude: '-13.25353189',
    elevation: '672.5224609',
  },
  // Ceará
  {
    id: 500,
    codigo: 101,
    nome: 'Abaiara',
    uf: states[5].uf,
    created_at: getCustomDate(-180),
    updated_at: getCustomDate(-180),
    deleted_at: null,
    state: states[5],
    country: countries[0],
    regiao_sojicola: '502',
    vcu: '4',
    longitude: '-39.0475466361851',
    latitude: '-7.356976596',
    elevation: '402.854248',
  },
  // Distrito Federal
  {
    id: 600,
    codigo: 108,
    nome: 'Brasília',
    uf: states[6].uf,
    created_at: getCustomDate(-180),
    updated_at: getCustomDate(-180),
    deleted_at: null,
    state: states[6],
    country: countries[0],
    regiao_sojicola: '304',
    vcu: '4',
    longitude: '-47.8879054780313',
    latitude: '-15.79408736',
    elevation: '1115.2719730000001',
  },
  // Espírito Santo
  {
    id: 700,
    codigo: 102,
    nome: 'Afonso Cláudio',
    uf: states[7].uf,
    created_at: getCustomDate(-180),
    updated_at: getCustomDate(-180),
    deleted_at: null,
    state: states[7],
    country: countries[0],
    regiao_sojicola: '302',
    vcu: '4',
    longitude: '-41.1254597171689',
    latitude: '-20.07704001',
    elevation: '361.18463130000004',
  },
  // Goiás
  {
    id: 800,
    codigo: 50,
    nome: 'Abadia de Goiás',
    uf: states[8].uf,
    created_at: getCustomDate(-180),
    updated_at: getCustomDate(-180),
    deleted_at: null,
    state: states[8],
    country: countries[0],
    regiao_sojicola: '401',
    vcu: '4',
    longitude: '-49.440547832657394',
    latitude: '-16.75881189',
    elevation: '893.7097777999999',
  },
  // Maranhão
  {
    id: 900,
    codigo: 55,
    nome: 'Açailândia',
    uf: states[9].uf,
    created_at: getCustomDate(-180),
    updated_at: getCustomDate(-180),
    deleted_at: null,
    state: states[9],
    country: countries[0],
    regiao_sojicola: '501',
    vcu: '4',
    longitude: '-47.5066645931119',
    latitude: '-4.951377',
    elevation: '228.72416690000003',
  },
  // Mato grosso
  {
    id: 1000,
    codigo: 102,
    nome: 'Acorizal',
    uf: states[10].uf,
    created_at: getCustomDate(-180),
    updated_at: getCustomDate(-180),
    deleted_at: null,
    state: states[10],
    country: countries[0],
    regiao_sojicola: '401',
    vcu: '4',
    longitude: '-56.371552566709795',
    latitude: '-15.19788998',
    elevation: '201.51750180000002',
  },
  // Mato Grosso do Sul
  {
    id: 1100,
    codigo: 203,
    nome: 'Água Clara',
    uf: states[11].uf,
    created_at: getCustomDate(-180),
    updated_at: getCustomDate(-180),
    deleted_at: null,
    state: states[11],
    country: countries[0],
    regiao_sojicola: '301',
    vcu: '4',
    longitude: '-52.8751175514539',
    latitude: '-20.44885076',
    elevation: '308.8161926',
  },
  // Minas Gerais
  {
    id: 1200,
    codigo: 104,
    nome: 'Abadia dos Dourados',
    uf: states[12].uf,
    created_at: getCustomDate(-180),
    updated_at: getCustomDate(-180),
    deleted_at: null,
    state: states[12],
    country: countries[0],
    regiao_sojicola: '303',
    vcu: '4',
    longitude: '-47.396832440622205',
    latitude: '-18.48756496',
    elevation: '753.2706909',
  },
  // Pará
  {
    id: 1300,
    codigo: 107,
    nome: 'Abaetetuba',
    uf: states[13].uf,
    created_at: getCustomDate(-180),
    updated_at: getCustomDate(-180),
    deleted_at: null,
    state: states[13],
    country: countries[0],
    regiao_sojicola: '501',
    vcu: '4',
    longitude: '-48.88440382072311',
    latitude: '-1.723469863',
    elevation: '10.08707142',
  },
  // Paraíba
  {
    id: 1400,
    codigo: 106,
    nome: 'Água Branca',
    uf: states[14].uf,
    created_at: getCustomDate(-180),
    updated_at: getCustomDate(-180),
    deleted_at: null,
    state: states[14],
    country: countries[0],
    regiao_sojicola: '502',
    vcu: '4',
    longitude: '-37.6422090442572',
    latitude: '-7.511070151',
    elevation: '746.8110962000001',
  },
  // Paraná
  {
    id: 1500,
    codigo: 103,
    nome: 'Abatiá',
    uf: states[15].uf,
    created_at: getCustomDate(-180),
    updated_at: getCustomDate(-180),
    deleted_at: null,
    state: states[15],
    country: countries[0],
    regiao_sojicola: '201',
    vcu: '3',
    longitude: '-50.312526582882',
    latitude: '-23.30049404',
    elevation: '579.6032715',
  },
  // Pernambuco
  {
    id: 1600,
    codigo: 54,
    nome: 'Abreu e Lima',
    uf: states[16].uf,
    created_at: getCustomDate(-180),
    updated_at: getCustomDate(-180),
    deleted_at: null,
    state: states[16],
    country: countries[0],
    regiao_sojicola: '501',
    vcu: '4',
    longitude: '-34.89913058473039',
    latitude: '-7.90444899',
    elevation: '27.6882',
  },
  // Piauí
  {
    id: 1700,
    codigo: 53,
    nome: 'Acauã',
    uf: states[17].uf,
    created_at: getCustomDate(-180),
    updated_at: getCustomDate(-180),
    deleted_at: null,
    state: states[17],
    country: countries[0],
    regiao_sojicola: '501',
    vcu: '4',
    longitude: '-41.0836378172387',
    latitude: '-8.220786663',
    elevation: '372.5566711',
  },
  // Rio de Janeiro
  {
    id: 1800,
    codigo: 100,
    nome: 'Angra dos Reis',
    uf: states[18].uf,
    created_at: getCustomDate(-180),
    updated_at: getCustomDate(-180),
    deleted_at: null,
    state: states[18],
    country: countries[0],
    regiao_sojicola: '203',
    vcu: '4',
    longitude: '-44.3196272622666',
    latitude: '-23.00911594',
    elevation: '5.847804546',
  },
  // Rio Grande do Norte
  {
    id: 1900,
    codigo: 109,
    nome: 'Acari',
    uf: states[19].uf,
    created_at: getCustomDate(-180),
    updated_at: getCustomDate(-180),
    deleted_at: null,
    state: states[19],
    country: countries[0],
    regiao_sojicola: '502',
    vcu: '4',
    longitude: '-36.643738869998295',
    latitude: '-6.442085089',
    elevation: '258.60232540000004',
  },
  // Rio Grande do Sul
  {
    id: 2000,
    codigo: 34,
    nome: 'Aceguá',
    uf: states[20].uf,
    created_at: getCustomDate(-180),
    updated_at: getCustomDate(-180),
    deleted_at: null,
    state: states[20],
    country: countries[0],
    regiao_sojicola: '101',
    vcu: '1',
    longitude: '-54.1647315363634',
    latitude: '-31.8640153',
    elevation: '238.39164730000002',
  },
  // Rondônia
  {
    id: 2100,
    codigo: 15,
    nome: "Alta Floresta D'Oeste",
    uf: states[21].uf,
    created_at: getCustomDate(-180),
    updated_at: getCustomDate(-180),
    deleted_at: null,
    state: states[21],
    country: countries[0],
    regiao_sojicola: '402',
    vcu: '4',
    longitude: '-61.9998238962936',
    latitude: '-11.935540300000001',
    elevation: '337.5688477',
  },
  // Roraima
  {
    id: 2200,
    codigo: 50,
    nome: 'Alto Alegre',
    uf: states[22].uf,
    created_at: getCustomDate(-180),
    updated_at: getCustomDate(-180),
    deleted_at: null,
    state: states[22],
    country: countries[0],
    regiao_sojicola: '503',
    vcu: '4',
    longitude: '-61.3140223901178',
    latitude: '2.993822948',
    elevation: '121.68631740000001',
  },
  // Santa Catarina
  {
    id: 2300,
    codigo: 51,
    nome: 'Abdon Batista',
    uf: states[23].uf,
    created_at: getCustomDate(-180),
    updated_at: getCustomDate(-180),
    deleted_at: null,
    state: states[23],
    country: countries[0],
    regiao_sojicola: '102',
    vcu: '1',
    longitude: '-51.0252722110367',
    latitude: '-27.60898712',
    elevation: '721.9503784',
  },
  // São Paulo
  {
    id: 2400,
    codigo: 105,
    nome: 'Adamantina',
    uf: states[24].uf,
    created_at: getCustomDate(-180),
    updated_at: getCustomDate(-180),
    deleted_at: null,
    state: states[24],
    country: countries[0],
    regiao_sojicola: '203',
    vcu: '4',
    longitude: '-51.07336474958179',
    latitude: '-21.68831148',
    elevation: '451.4709166999999',
  },
  // Sergipe
  {
    id: 2500,
    codigo: 100,
    nome: 'Amparo do São Francisco',
    uf: states[25].uf,
    created_at: getCustomDate(-180),
    updated_at: getCustomDate(-180),
    deleted_at: null,
    state: states[25],
    country: countries[0],
    regiao_sojicola: '501',
    vcu: '4',
    longitude: '-36.927483689180995',
    latitude: '-10.13397103',
    elevation: '20.00388908',
  },
  // Tocantins
  {
    id: 2600,
    codigo: 251,
    nome: 'Abreulândia',
    uf: states[26].uf,
    created_at: getCustomDate(-180),
    updated_at: getCustomDate(-180),
    deleted_at: null,
    state: states[26],
    country: countries[0],
    regiao_sojicola: '501',
    vcu: '4',
    longitude: '-49.162437555479706',
    latitude: '-9.621790712000001',
    elevation: '239.2407074',
  },
]

export { cities, countries, states }
