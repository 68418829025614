import { APIFailureWrapper, mockFlag } from '@/utils/mock'
import { cities, states } from '@/services/__mocks__/locationSeeds'

const locationRoutes = [
  mockFlag(
    {
      method: 'get',
      url: '/estados/:country_id',
      result: ({ params }) => {
        const response = states.reduce((acc, state) => {
          if (state.pais_id == params.country_id) {
            acc.push({
              area_total: state.area_total,
              codigoUf: state.codigoUf,
              created_at: state.created_at,
              deleted_at: state.deleted_at,
              id: state.id,
              nome: state.nome,
              pais_id: state.pais_id,
              uf: state.uf,
              updated_at: state.updated_at,
            })
          }
          return acc
        }, [])

        return APIFailureWrapper({
          content: response,
          errorMessage: 'Erro na listagem de estados',
        })
      },
    },
    'on'
  ),
  mockFlag({
    method: 'get',
    url: '/municipios/:state_id',
    result: ({ params }) => {
      const response = cities.reduce((acc, city) => {
        if (city.state.id == params.state_id) {
          acc.push({
            codigo: city.codigo,
            created_at: city.created_at,
            deleted_at: city.deleted_at,
            elevation: city.elevation,
            estado_id: city.state.id,
            id: city.id,
            latitude: city.latitude,
            longitude: city.longitude,
            nome: city.nome,
            regiao_sojicola: city.regiao_sojicola,
            uf: city.state.uf,
            updated_at: city.updated_at,
            vcu: city.vcu,
          })
        }
        return acc
      }, [])

      return APIFailureWrapper({
        content: response,
        errorMessage: 'Erro na listagem de municípios',
      })
    },
  }),
]

export { locationRoutes }
