import { plans } from '@/services/__mocks__/plansSeeds'
import { APIFailureWrapper, mockFlag } from '@/utils/mock'

const menuAdminRoutes = [
  mockFlag(
    {
      method: 'get',
      url: '/plans',
      result: () => {
        return APIFailureWrapper({
          content: plans,
          errorMessage: 'Erro na listagem de planos',
        })
      },
    },
    'on'
  ),
]

export { menuAdminRoutes }
