<template>
  <v-dialog v-model="isOpen" persistent width="636px" @keydown.esc="closeModal">
    <div class="df-flex-m df-flex-col plan-presentation-modal">
      <div class="df-flex-m justify-space-between align-start modal__header">
        <div class="df-flex-sm justify-center align-center">
          <h1>{{ titleModal }}</h1>
          <v-img
            aspect-ratio="4.72"
            height="32px"
            min-width="120px"
            :src="upsellLogoLastPlan"
          />
        </div>
        <v-btn icon @click="closeModal">
          <font-awesome-icon icon="times" />
        </v-btn>
      </div>
      <div class="df-flex-m df-flex-column">
        <df-carousel hide-delimiters height="300px" :images="images" />
        <card-info-plan v-if="isOpen" @close-modal="closeModal" />
      </div>
    </div>
  </v-dialog>
</template>

<script>
import CardInfoPlan from '@/components/Cards/CardInfoPlan.vue'
import DfCarousel from '@/components/Carousels/DfCarousel.vue'
import PlanMixin from '@/components/PlanMixin'

export default {
  name: 'PlanPresentationModal',

  mixins: [PlanMixin],

  components: {
    CardInfoPlan,
    DfCarousel,
  },

  data() {
    return {
      isOpen: false,
    }
  },

  props: {
    dialog: {
      default: false,
      type: Boolean,
    },
    images: {
      default: () => [],
      type: Array,
    },
    titleModal: {
      default: '',
      type: String,
    },
  },

  watch: {
    dialog(newDialog) {
      this.isOpen = newDialog
    },
    isOpen(newIsOpen) {
      if (!newIsOpen) {
        this.closeModal()
      }
    },
  },

  async created() {
    await this.getLastUpsellPlan()
  },

  methods: {
    closeModal() {
      this.$emit('close')
    },
  },
}
</script>

<style lang="scss" scoped>
.plan-presentation-modal {
  padding: $spacing-l;
  background-color: $color-background-white;
  border-radius: $border-radius-m;

  .modal__header {
    flex-wrap: nowrap;

    h1 {
      @include heading-medium;

      @include d(m) {
        @include heading-small;
      }
    }
  }
}
::v-deep .v-image__image.v-image__image--cover {
  background-size: contain !important;
}
::v-deep .v-dialog {
  overflow: hidden;
}
</style>
