import http, { adminNoAuthHttp, adminAuthHttp } from '@/utils/http'

const changePasswordUrl = '/change-password'

const auth = {
  login: (email, password) => {
    return adminNoAuthHttp.post('/login', { email, password })
  },
  recoverPassword: (email) =>
    adminNoAuthHttp.post('/recovery-password', { email }),
  selectAccount: (accountId) =>
    adminAuthHttp.post('/select-account', { account_id: accountId }),
  logout: () => adminAuthHttp.post('/logout'),
  refreshToken: () => http.post('/token-refresh'),
  changePassword: (oldPassword, newPassword, passwordConfirmation, userEmail) =>
    adminAuthHttp.post(changePasswordUrl, {
      old_password: oldPassword,
      new_password: newPassword,
      password_confirmation: passwordConfirmation,
      email: userEmail,
    }),
  deleteTemporaryPassword: (userId) =>
    http.delete(`/users/${userId}/recovery-password`),
}

export { changePasswordUrl }
export default auth
