const Module = () =>
  import(
    /* webpackChunkName: "comparison-module" */ '@/modules/comparison/Module.vue'
  )
const ComparisonHome = () =>
  import(
    /* webpackChunkName: "comparison-module" */ '@/modules/comparison/views/ComparisonHome.vue'
  )

const routes = [
  {
    path: '/comparacao',
    component: Module,
    children: [
      {
        path: '/',
        component: ComparisonHome,
      },
    ],
    meta: {
      requiresAuth: true,
    },
  },
]

export default routes
