const adminPermissions = [
  'USER_EXCLUIR',
  'USER_VISUALIZAR',
  'USER_EDITAR',
  'USER_CADASTRAR',
  'USER_LISTAR',
  'PERFIL_EXCLUIR',
  'PERFIL_VISUALIZAR',
  'PERFIL_EDITAR',
  'PERFIL_CADASTRAR',
  'PERFIL_LISTAR',
  'FAZENDA_EXCLUIR',
  'FAZENDA_VISUALIZAR',
  'FAZENDA_EDITAR',
  'FAZENDA_CADASTRAR',
  'FAZENDA_LISTAR',
  'CULTIVAR_CADASTRAR',
  'CULTIVAR_EDITAR',
  'CULTIVAR_EXCLUIR',
  'CULTIVAR_LISTAR',
  'CULTIVAR_VISUALIZAR',
  'FERTILIZANTE_CADASTRAR',
  'FERTILIZANTE_EDITAR',
  'FERTILIZANTE_EXCLUIR',
  'FERTILIZANTE_LISTAR',
  'FERTILIZANTE_VISUALIZAR',
  'DEFENSIVO_CADASTRAR',
  'DEFENSIVO_EDITAR',
  'DEFENSIVO_EXCLUIR',
  'DEFENSIVO_LISTAR',
  'DEFENSIVO_VISUALIZAR',
  'COLHEITA_LISTAR',
  'COLHEITA_CADASTRAR',
  'COLHEITA_EDITAR',
  'COLHEITA_EXCLUIR',
  'GERENCIAR_DEFENSIVOS_LISTAR',
  'GERENCIAR_DEFENSIVOS_CADASTRAR',
  'GERENCIAR_DEFENSIVOS_EDITAR',
  'GERENCIAR_DEFENSIVOS_EXCLUIR',
  'GERENCIAR_DEFENSIVOS_VISUALIZAR',
  'LOGS_ACESSO_LISTAR',
  'SEMEADURA_LISTAR',
  'SEMEADURA_CADASTRAR',
  'SEMEADURA_EDITAR',
  'SEMEADURA_EXCLUIR',
  'SEMEADURA_VISUALIZAR',
  'DASHBOARD_FENOLOGICOS',
  'DASHBOARD_GERAL',
  'DASHBOARD_GRAFICO_IPM',
  'DASHBOARD_AGENDA',
  'PLANEJAMENTO_LISTAR',
  'PLANEJAMENTO_CADASTRAR',
  'PLANEJAMENTO_EDITAR',
  'PLANEJAMENTO_EXCLUIR',
  'PLANEJAMENTO_VISUALIZAR',
  'EXECUCAO_LISTAR',
  'EXECUCAO_CADASTRAR',
  'EXECUCAO_EDITAR',
  'EXECUCAO_EXCLUIR',
  'EXECUCAO_VISUALIZAR',
  'CONTAS_LISTAR',
  'CONTAS_CADASTRAR',
  'CONTAS_EDITAR',
  'CONTAS_EXCLUIR',
  'CONTAS_VISUALIZAR',
  'GERENCIAR_CULTIVARES_LISTAR',
  'GERENCIAR_CULTIVARES_CADASTRAR',
  'GERENCIAR_CULTIVARES_EDITAR',
  'GERENCIAR_CULTIVARES_EXCLUIR',
  'GERENCIAR_CULTIVARES_VISUALIZAR',
  'PARAMETROPESORISCOPROGRAMA_LISTAR',
  'PARAMETROPESORISCOPROGRAMA_CADASTRAR',
  'PARAMETROPESORISCOPROGRAMA_EDITAR',
  'PARAMETROPESORISCOPROGRAMA_EXCLUIR',
  'PARAMETROPESORISCOPROGRAMA_VISUALIZAR',
  'RELATORIO_FAZENDA_PULVERIZACAO',
  'RELATORIO_SEMEADURA',
  'RELATORIO_EXECUCAO_PULVERIZACAO',
  'RELATORIO_DEFENSIVOS_UTILIZADOS',
  'GERENCIAR_SAFRAS_LISTAR',
  'GERENCIAR_SAFRAS_EDITAR',
  'GERENCIAR_SAFRAS_EXCLUIR',
  'GERENCIAR_SAFRAS_VISUALIZAR',
  'GERENCIAR_SAFRAS_CADASTRAR',
  'GERENCIAR_ALVOS_LISTAR',
  'GERENCIAR_ALVOS_EDITAR',
  'GERENCIAR_ALVOS_EXCLUIR',
  'GERENCIAR_ALVOS_VISUALIZAR',
  'GERENCIAR_ALVOS_CADASTRAR',
  'GERENCIAR_FERTILIZANTES_LISTAR',
  'GERENCIAR_FERTILIZANTES_EDITAR',
  'GERENCIAR_FERTILIZANTES_CADASTRAR',
  'GERENCIAR_FERTILIZANTES_EXCLUIR',
  'GERENCIAR_FERTILIZANTES_VISUALIZAR',
  'HELP_CONFIG_LISTAR',
  'FAQ_CONFIG_LISTAR',
  'PERGUNTAS_USERS_LISTAR',
  'EMAIL_CONFIG_LISTAR',
  'GERENCIAR_VOUCHER_LISTAR',
  'GERENCIAR_VOUCHER_EDITAR',
  'GERENCIAR_VOUCHER_CADASTRAR',
  'GERENCIAR_VOUCHER_VISUALIZAR',
  'GERENCIAR_VOUCHER_EXCLUIR',
  'GERENCIAR_BOLETOS_LISTAR',
  'GERENCIAR_BOLETOS_EDITAR',
  'GERENCIAR_BOLETOS_CADASTRAR',
  'GERENCIAR_BOLETOS_VISUALIZAR',
  'GERENCIAR_BOLETOS_EXCLUIR',
  'GERENCIAR_ADJUVANTES_LISTAR',
  'GERENCIAR_ADJUVANTES_EDITAR',
  'GERENCIAR_ADJUVANTES_CADASTRAR',
  'GERENCIAR_ADJUVANTES_VISUALIZAR',
  'GERENCIAR_ADJUVANTES_EXCLUIR',
  'ADJUVANTES_LISTAR',
  'ADJUVANTES_EDITAR',
  'ADJUVANTES_CADASTRAR',
  'ADJUVANTES_VISUALIZAR',
  'ADJUVANTES_EXCLUIR',
  'MENU_CADASTROS',
  'MENU_SAFRAS',
  'MENU_ADMINISTRACAO',
  'MENU_PAINEIS',
  'MENU_SISTEMA',
  'MENU_RELATORIOS',
  'TALHAO_LISTAR',
  'TALHAO_CADASTRAR',
  'TALHAO_EDITAR',
  'TALHAO_VISUALIZAR',
  'TALHAO_EXCLUIR',
  'NOTE_LISTAR',
  'NOTE_CADASTRAR',
  'NOTE_EDITAR',
  'NOTE_EXCLUIR',
]

const engineerPermissions = [
  'FAZENDA_VISUALIZAR',
  'FAZENDA_LISTAR',
  'DEFENSIVO_CADASTRAR',
  'DEFENSIVO_EDITAR',
  'DEFENSIVO_EXCLUIR',
  'DEFENSIVO_LISTAR',
  'DEFENSIVO_VISUALIZAR',
  'FERTILIZANTE_CADASTRAR',
  'FERTILIZANTE_EDITAR',
  'FERTILIZANTE_EXCLUIR',
  'FERTILIZANTE_LISTAR',
  'FERTILIZANTE_VISUALIZAR',
  'CULTIVAR_CADASTRAR',
  'CULTIVAR_EDITAR',
  'CULTIVAR_EXCLUIR',
  'CULTIVAR_LISTAR',
  'CULTIVAR_VISUALIZAR',
  'COLHEITA_LISTAR',
  'COLHEITA_CADASTRAR',
  'COLHEITA_EDITAR',
  'COLHEITA_EXCLUIR',
  'SEMEADURA_LISTAR',
  'SEMEADURA_CADASTRAR',
  'SEMEADURA_EDITAR',
  'SEMEADURA_EXCLUIR',
  'SEMEADURA_VISUALIZAR',
  'PLANEJAMENTO_LISTAR',
  'PLANEJAMENTO_CADASTRAR',
  'PLANEJAMENTO_EDITAR',
  'PLANEJAMENTO_EXCLUIR',
  'PLANEJAMENTO_VISUALIZAR',
  'EXECUCAO_LISTAR',
  'EXECUCAO_CADASTRAR',
  'EXECUCAO_EDITAR',
  'EXECUCAO_EXCLUIR',
  'EXECUCAO_VISUALIZAR',
  'DASHBOARD_FENOLOGICOS',
  'DASHBOARD_GERAL',
  'DASHBOARD_GRAFICO_IPM',
  'DASHBOARD_AGENDA',
  'MENU_CADASTROS',
  'MENU_SAFRAS',
  'MENU_PAINEIS',
  'ADJUVANTES_LISTAR',
  'ADJUVANTES_EDITAR',
  'ADJUVANTES_CADASTRAR',
  'ADJUVANTES_VISUALIZAR',
  'ADJUVANTES_EXCLUIR',
  'RELATORIO_FAZENDA_PULVERIZACAO',
  'RELATORIO_SEMEADURA',
  'RELATORIO_EXECUCAO_PULVERIZACAO',
  'RELATORIO_DEFENSIVOS_UTILIZADOS',
  'MENU_RELATORIOS',
  'TALHAO_LISTAR',
  'TALHAO_VISUALIZAR',
  'PERFIL_LISTAR',
  'NOTE_LISTAR',
  'NOTE_CADASTRAR',
  'NOTE_EDITAR',
  'NOTE_EXCLUIR',
  'CATALOG_LISTAR',
  'MANAGER_ACCOUNT',
]

const observerPermissions = [
  'PLANEJAMENTO_LISTAR',
  'PLANEJAMENTO_VISUALIZAR',
  'EXECUCAO_LISTAR',
  'EXECUCAO_VISUALIZAR',
  'COLHEITA_LISTAR',
  'SEMEADURA_LISTAR',
  'SEMEADURA_VISUALIZAR',
  'DASHBOARD_AGENDA',
  'DASHBOARD_FENOLOGICOS',
  'DASHBOARD_GERAL',
  'DASHBOARD_GRAFICO_IPM',
  'MENU_CADASTROS',
  'MENU_SAFRAS',
  'MENU_PAINEIS',
  'ADJUVANTES_LISTAR',
  'ADJUVANTES_VISUALIZAR',
  'CULTIVAR_LISTAR',
  'CULTIVAR_VISUALIZAR',
  'DEFENSIVO_LISTAR',
  'DEFENSIVO_VISUALIZAR',
  'FAZENDA_LISTAR',
  'FAZENDA_VISUALIZAR',
  'FERTILIZANTE_LISTAR',
  'FERTILIZANTE_VISUALIZAR',
  'MENU_RELATORIOS',
  'RELATORIO_DEFENSIVOS_UTILIZADOS',
  'RELATORIO_EXECUCAO_PULVERIZACAO',
  'RELATORIO_FAZENDA_PULVERIZACAO',
  'RELATORIO_SEMEADURA',
  'TALHAO_LISTAR',
  'TALHAO_VISUALIZAR',
  'PERFIL_LISTAR',
  'NOTE_LISTAR',
  'CATALOG_LISTAR',
  'MANAGER_ACCOUNT',
]

const resaleConsultant = [
  'FAZENDA_VISUALIZAR',
  'FAZENDA_EDITAR',
  'FAZENDA_LISTAR',
  'DEFENSIVO_CADASTRAR',
  'DEFENSIVO_EDITAR',
  'DEFENSIVO_EXCLUIR',
  'DEFENSIVO_LISTAR',
  'DEFENSIVO_VISUALIZAR',
  'FERTILIZANTE_CADASTRAR',
  'FERTILIZANTE_EDITAR',
  'FERTILIZANTE_EXCLUIR',
  'FERTILIZANTE_LISTAR',
  'FERTILIZANTE_VISUALIZAR',
  'CULTIVAR_CADASTRAR',
  'CULTIVAR_EDITAR',
  'CULTIVAR_EXCLUIR',
  'CULTIVAR_LISTAR',
  'CULTIVAR_VISUALIZAR',
  'COLHEITA_LISTAR',
  'COLHEITA_CADASTRAR',
  'COLHEITA_EDITAR',
  'COLHEITA_EXCLUIR',
  'SEMEADURA_LISTAR',
  'SEMEADURA_CADASTRAR',
  'SEMEADURA_EDITAR',
  'SEMEADURA_EXCLUIR',
  'SEMEADURA_VISUALIZAR',
  'PLANEJAMENTO_LISTAR',
  'PLANEJAMENTO_CADASTRAR',
  'PLANEJAMENTO_EDITAR',
  'PLANEJAMENTO_EXCLUIR',
  'PLANEJAMENTO_VISUALIZAR',
  'EXECUCAO_LISTAR',
  'EXECUCAO_CADASTRAR',
  'EXECUCAO_EDITAR',
  'EXECUCAO_EXCLUIR',
  'EXECUCAO_VISUALIZAR',
  'DASHBOARD_FENOLOGICOS',
  'DASHBOARD_GERAL',
  'DASHBOARD_GRAFICO_IPM',
  'DASHBOARD_AGENDA',
  'MENU_CADASTROS',
  'MENU_SAFRAS',
  'MENU_PAINEIS',
  'ADJUVANTES_LISTAR',
  'ADJUVANTES_EDITAR',
  'ADJUVANTES_CADASTRAR',
  'ADJUVANTES_VISUALIZAR',
  'ADJUVANTES_EXCLUIR',
  'FAZENDA_CADASTRAR',
  'FAZENDA_EXCLUIR',
  'TALHAO_LISTAR',
  'TALHAO_CADASTRAR',
  'TALHAO_EDITAR',
  'TALHAO_VISUALIZAR',
  'TALHAO_EXCLUIR',
  'PERFIL_LISTAR',
  'NOTE_LISTAR',
  'NOTE_CADASTRAR',
  'NOTE_EDITAR',
  'NOTE_EXCLUIR',
  'CATALOG_LISTAR',
  'MANAGER_ACCOUNT',
]

const researcherPermissions = [
  'COLHEITA_CADASTRAR',
  'COLHEITA_EDITAR',
  'COLHEITA_EXCLUIR',
  'COLHEITA_LISTAR',
  'CULTIVAR_CADASTRAR',
  'CULTIVAR_EDITAR',
  'CULTIVAR_EXCLUIR',
  'CULTIVAR_LISTAR',
  'CULTIVAR_VISUALIZAR',
  'DASHBOARD_AGENDA',
  'DASHBOARD_FENOLOGICOS',
  'DASHBOARD_GERAL',
  'DASHBOARD_GRAFICO_IPM',
  'DEFENSIVO_LISTAR',
  'DEFENSIVO_VISUALIZAR',
  'EXECUCAO_CADASTRAR',
  'EXECUCAO_EDITAR',
  'EXECUCAO_LISTAR',
  'EXECUCAO_VISUALIZAR',
  'FAZENDA_CADASTRAR',
  'FAZENDA_EDITAR',
  'FAZENDA_EXCLUIR',
  'FAZENDA_LISTAR',
  'FAZENDA_VISUALIZAR',
  'FERTILIZANTE_LISTAR',
  'FERTILIZANTE_VISUALIZAR',
  'MENU_CADASTROS',
  'MENU_PAINEIS',
  'MENU_RELATORIOS',
  'MENU_SAFRAS',
  'PLANEJAMENTO_LISTAR',
  'PLANEJAMENTO_VISUALIZAR',
  'RELATORIO_DEFENSIVOS_UTILIZADOS',
  'RELATORIO_EXECUCAO_PULVERIZACAO',
  'RELATORIO_FAZENDA_PULVERIZACAO',
  'RELATORIO_SEMEADURA',
  'SEMEADURA_CADASTRAR',
  'SEMEADURA_EDITAR',
  'SEMEADURA_LISTAR',
  'SEMEADURA_VISUALIZAR',
  'TALHAO_LISTAR',
  'TALHAO_CADASTRAR',
  'TALHAO_EDITAR',
  'TALHAO_VISUALIZAR',
  'TALHAO_EXCLUIR',
  'PERFIL_LISTAR',
  'NOTE_LISTAR',
  'NOTE_CADASTRAR',
  'NOTE_EDITAR',
  'NOTE_EXCLUIR',
  'CATALOG_LISTAR',
  'MANAGER_ACCOUNT',
]

const freemiumPlanPermissions = [
  'FAZENDA_EXCLUIR',
  'FAZENDA_VISUALIZAR',
  'FAZENDA_EDITAR',
  'TALHAO_LISTAR',
  'TALHAO_CADASTRAR',
  'TALHAO_EDITAR',
  'NOTE_EXCLUIR',
  'CATALOG_LISTAR',
]

const fullPlanPermissions = [
  'FAZENDA_EXCLUIR',
  'FAZENDA_VISUALIZAR',
  'FAZENDA_EDITAR',
  'FAZENDA_CADASTRAR',
  'FAZENDA_LISTAR',
  'CULTIVAR_CADASTRAR',
  'CULTIVAR_EDITAR',
  'CULTIVAR_EXCLUIR',
  'CULTIVAR_LISTAR',
  'CULTIVAR_VISUALIZAR',
  'FERTILIZANTE_CADASTRAR',
  'FERTILIZANTE_EDITAR',
  'FERTILIZANTE_EXCLUIR',
  'FERTILIZANTE_LISTAR',
  'FERTILIZANTE_VISUALIZAR',
  'DEFENSIVO_CADASTRAR',
  'DEFENSIVO_EDITAR',
  'DEFENSIVO_EXCLUIR',
  'DEFENSIVO_LISTAR',
  'DEFENSIVO_VISUALIZAR',
  'SEMEADURA_LISTAR',
  'SEMEADURA_CADASTRAR',
  'SEMEADURA_EDITAR',
  'SEMEADURA_EXCLUIR',
  'SEMEADURA_VISUALIZAR',
  'COLHEITA_LISTAR',
  'COLHEITA_CADASTRAR',
  'COLHEITA_EDITAR',
  'COLHEITA_EXCLUIR',
  'DASHBOARD_FENOLOGICOS',
  'DASHBOARD_GERAL',
  'DASHBOARD_GRAFICO_IPM',
  'DASHBOARD_AGENDA',
  'PLANEJAMENTO_LISTAR',
  'PLANEJAMENTO_CADASTRAR',
  'PLANEJAMENTO_EDITAR',
  'PLANEJAMENTO_EXCLUIR',
  'PLANEJAMENTO_VISUALIZAR',
  'EXECUCAO_LISTAR',
  'EXECUCAO_CADASTRAR',
  'EXECUCAO_EDITAR',
  'EXECUCAO_EXCLUIR',
  'EXECUCAO_VISUALIZAR',
  'CONTAS_EDITAR',
  'RELATORIO_FAZENDA_PULVERIZACAO',
  'RELATORIO_SEMEADURA',
  'RELATORIO_EXECUCAO_PULVERIZACAO',
  'RELATORIO_DEFENSIVOS_UTILIZADOS',
  'MENU_CADASTROS',
  'MENU_SAFRAS',
  'MENU_ADMINISTRACAO',
  'MENU_PAINEIS',
  'MENU_RELATORIOS',
  'ADJUVANTES_LISTAR',
  'ADJUVANTES_EDITAR',
  'ADJUVANTES_CADASTRAR',
  'ADJUVANTES_VISUALIZAR',
  'ADJUVANTES_EXCLUIR',
  'TALHAO_LISTAR',
  'TALHAO_CADASTRAR',
  'TALHAO_EDITAR',
  'TALHAO_VISUALIZAR',
  'TALHAO_EXCLUIR',
  'PERFIL_LISTAR',
  'NOTE_LISTAR',
  'NOTE_CADASTRAR',
  'NOTE_EDITAR',
  'NOTE_EXCLUIR',
  'CATALOG_LISTAR',
]

const lightPlanPermissions = [
  'FAZENDA_EXCLUIR',
  'FAZENDA_VISUALIZAR',
  'FAZENDA_EDITAR',
  'FAZENDA_CADASTRAR',
  'FAZENDA_LISTAR',
  'TALHAO_LISTAR',
  'TALHAO_CADASTRAR',
  'TALHAO_EDITAR',
  'TALHAO_EXCLUIR',
  'CATALOG_LISTAR',
]

const proPlanPermissions = [
  'FAZENDA_EXCLUIR',
  'FAZENDA_VISUALIZAR',
  'FAZENDA_EDITAR',
  'FAZENDA_CADASTRAR',
  'FAZENDA_LISTAR',
  'CULTIVAR_CADASTRAR',
  'CULTIVAR_EDITAR',
  'CULTIVAR_EXCLUIR',
  'CULTIVAR_LISTAR',
  'CULTIVAR_VISUALIZAR',
  'FERTILIZANTE_CADASTRAR',
  'FERTILIZANTE_EDITAR',
  'FERTILIZANTE_EXCLUIR',
  'FERTILIZANTE_LISTAR',
  'FERTILIZANTE_VISUALIZAR',
  'DEFENSIVO_CADASTRAR',
  'DEFENSIVO_EDITAR',
  'DEFENSIVO_EXCLUIR',
  'DEFENSIVO_LISTAR',
  'DEFENSIVO_VISUALIZAR',
  'SEMEADURA_VISUALIZAR',
  'PLANEJAMENTO_LISTAR',
  'PLANEJAMENTO_CADASTRAR',
  'PLANEJAMENTO_EDITAR',
  'PLANEJAMENTO_EXCLUIR',
  'PLANEJAMENTO_VISUALIZAR',
  'EXECUCAO_VISUALIZAR',
  'CONTAS_EDITAR',
  'MENU_CADASTROS',
  'MENU_SAFRAS',
  'MENU_ADMINISTRACAO',
  'MENU_PAINEIS',
  'MENU_RELATORIOS',
  'ADJUVANTES_LISTAR',
  'ADJUVANTES_EDITAR',
  'ADJUVANTES_CADASTRAR',
  'ADJUVANTES_VISUALIZAR',
  'ADJUVANTES_EXCLUIR',
  'TALHAO_LISTAR',
  'TALHAO_CADASTRAR',
  'TALHAO_EDITAR',
  'TALHAO_VISUALIZAR',
  'TALHAO_EXCLUIR',
  'PERFIL_LISTAR',
  'NOTE_LISTAR',
  'NOTE_CADASTRAR',
  'NOTE_EDITAR',
  'NOTE_EXCLUIR',
  'CATALOG_LISTAR',
]

export {
  adminPermissions,
  engineerPermissions,
  observerPermissions,
  resaleConsultant,
  researcherPermissions,
  // plans
  freemiumPlanPermissions,
  fullPlanPermissions,
  lightPlanPermissions,
  proPlanPermissions,
}
