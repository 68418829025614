export default {
  farm: {
    list: 'FAZENDA_LISTAR',
    create: 'FAZENDA_CADASTRAR',
    edit: 'FAZENDA_EDITAR',
    view: 'FAZENDA_VISUALIZAR',
    delete: 'FAZENDA_EXCLUIR',
  },
  plot: {
    list: 'TALHAO_LISTAR',
    create: 'TALHAO_CADASTRAR',
    edit: 'TALHAO_EDITAR',
    view: 'TALHAO_VISUALIZAR',
    delete: 'TALHAO_EXCLUIR',
  },
  planting: {
    list: 'SEMEADURA_LISTAR',
    create: 'SEMEADURA_CADASTRAR',
    edit: 'SEMEADURA_EDITAR',
    delete: 'SEMEADURA_EXCLUIR',
    view: 'SEMEADURA_VISUALIZAR',
  },
  menu: {
    create: 'MENU_CADASTROS',
    season: 'MENU_SAFRAS',
    admin: 'MENU_ADMINISTRACAO',
    panel: 'MENU_PAINEIS',
    repor: 'MENU_RELATORIOS',
  },
  fieldview: {
    view: 'FIELDVIEW_VIEW',
  },
  variety: {
    list: 'CULTIVAR_LISTAR',
    create: 'CULTIVAR_CADASTRAR',
    edit: 'CULTIVAR_EDITAR',
    delete: 'CULTIVAR_EXCLUIR',
    view: 'CULTIVAR_VISUALIZAR',
  },
  program: {
    list: 'EXECUCAO_LISTAR',
    create: 'EXECUCAO_CADASTRAR',
    edit: 'EXECUCAO_EDITAR',
    delete: 'EXECUCAO_EXCLUIR',
    view: 'EXECUCAO_VISUALIZAR',
  },
  planning: {
    list: 'PLANEJAMENTO_LISTAR',
    create: 'PLANEJAMENTO_CADASTRAR',
    edit: 'PLANEJAMENTO_EDITAR',
    delete: 'PLANEJAMENTO_EXCLUIR',
    view: 'PLANEJAMENTO_VISUALIZAR',
  },
  defensive: {
    list: 'DEFENSIVO_LISTAR',
    create: 'DEFENSIVO_CADASTRAR',
    edit: 'DEFENSIVO_EDITAR',
    delete: 'DEFENSIVO_EXCLUIR',
    view: 'DEFENSIVO_VISUALIZAR',
  },
  harvest: {
    list: 'COLHEITA_LISTAR',
    create: 'COLHEITA_CADASTRAR',
    edit: 'COLHEITA_EDITAR',
    delete: 'COLHEITA_EXCLUIR',
  },
  user: {
    list: 'USER_LISTAR',
    create: 'USER_CADASTRAR',
    edit: 'USER_EDITAR',
    delete: 'USER_EXCLUIR',
    view: 'USER_VISUALIZAR',
  },
  note: {
    list: 'NOTE_LISTAR',
    create: 'NOTE_CADASTRAR',
    edit: 'NOTE_EDITAR',
    delete: 'NOTE_EXCLUIR',
  },
  fertilizer: {
    list: 'FERTILIZANTE_LISTAR',
    create: 'FERTILIZANTE_CADASTRAR',
    edit: 'FERTILIZANTE_EDITAR',
    delete: 'FERTILIZANTE_EXCLUIR',
    view: 'FERTILIZANTE_VISUALIZAR',
  },
  dashboard: {
    phenologies: 'DASHBOARD_FENOLOGICOS',
    general: 'DASHBOARD_GERAL',
    graphic: 'DASHBOARD_GRAFICO_IPM',
    agenda: 'DASHBOARD_AGENDA',
  },
  report: {
    farmSpraying: 'RELATORIO_FAZENDA_PULVERIZACAO',
    sowing: 'RELATORIO_SEMEADURA',
    spraying: 'RELATORIO_EXECUCAO_PULVERIZACAO',
    pesticide: 'RELATORIO_DEFENSIVOS_UTILIZADOS',
  },
  adjuvant: {
    list: 'ADJUVANTES_LISTAR',
    create: 'ADJUVANTES_CADASTRAR',
    edit: 'ADJUVANTES_EDITAR',
    delete: 'ADJUVANTES_EXCLUIR',
    view: 'ADJUVANTES_VISUALIZAR',
  },
  catalog: {
    list: 'CATALOG_LISTAR',
  },
  profile: {
    list: 'PERFIL_LISTAR',
  },
  admin: 'MANAGER_ACCOUNT',
  accountManager: 'CONTAS_EDITAR',
  linkage: 'VIEW_POWERBI',
}
