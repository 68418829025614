export default {
  Home: {
    header: {
      hello_user: 'Hello {0}, welcome to',
      edit_farm: 'Farm editing',
      enable_farm: 'Disable farm',
      fieldview_import: 'Import from Climate FieldView',
      conditional_import_farms_and_plots:
        'If you have an account, you can upload plots and farms',
      sown_area: 'Sown area',
    },
    quick_actions: {
      quick_actions: 'quick actions',
      see_catalog: 'see catalog',
      plan_fungicide: 'plan fungicide',
      create_note: 'create note',
      see_fields: 'see fields',
      register_sowing: 'register sowing',
    },
    item_list: {
      fields: 'fields',
      see_all: 'see all',
      active_sowings: 'active sowings',
      sowings: 'sowings',
    },
    sowing: {
      next_spray: 'Next spray',
      complete: 'complete',
      not_current_season:
        "The sowing is not from this season. It's not possible to edit it",
    },
  },
}
