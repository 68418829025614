import { getCustomDate } from '@/utils/time'

const defensives = [
  {
    id: 1,
    nome: 'Abacus HC',
    unidade: 'L',
    tipo: 'phytosanitary_category.diseases',
    pais_id: 1,
    is_white_mold: true,
    doses: {
      soybean_asian_soybean_rust: {
        target_id: 1,
        has_register: false,
        dose_recomendada: '999999.00',
        dose_recomendada_max: '999999.00',
      },
      soybean_anthracnosis: {
        target_id: 2,
        has_register: false,
        dose_recomendada: '999999.00',
        dose_recomendada_max: '999999.00',
      },
      soybean_target_spot: {
        target_id: 3,
        has_register: true,
        dose_recomendada: 0.25,
        dose_recomendada_max: 0.3,
      },
      soybean_powdery_mildew: {
        target_id: 4,
        has_register: true,
        dose_recomendada: 0.25,
        dose_recomendada_max: 0.3,
      },
      soybean_cercospora_blight: {
        target_id: 5,
        has_register: true,
        dose_recomendada: 0.25,
        dose_recomendada_max: 0.3,
      },
      soybean_septoria_leaf_spot: {
        target_id: 11,
        has_register: true,
        dose_recomendada: 0.25,
        dose_recomendada_max: 0.3,
      },
      soybean_aerial_web_blight: {
        target_id: 12,
        has_register: false,
        dose_recomendada: '999999.00',
        dose_recomendada_max: '999999.00',
      },
      soybean_white_mold: {
        target_id: 13,
        has_register: false,
        dose_recomendada: '999999.00',
        dose_recomendada_max: '999999.00',
      },
    },
  },
  {
    id: 2,
    nome: 'Absoluto 500 SC',
    unidade: 'L',
    tipo: 'phytosanitary_category.diseases',
    pais_id: 1,
    is_white_mold: false,
    doses: {
      soybean_asian_soybean_rust: {
        target_id: 1,
        has_register: false,
        dose_recomendada: '999999.00',
        dose_recomendada_max: '999999.00',
      },
      soybean_anthracnosis: {
        target_id: 2,
        has_register: false,
        dose_recomendada: '999999.00',
        dose_recomendada_max: '999999.00',
      },
      soybean_target_spot: {
        target_id: 3,
        has_register: false,
        dose_recomendada: '999999.00',
        dose_recomendada_max: '999999.00',
      },
      soybean_powdery_mildew: {
        target_id: 4,
        has_register: false,
        dose_recomendada: '999999.00',
        dose_recomendada_max: '999999.00',
      },
      soybean_cercospora_blight: {
        target_id: 5,
        has_register: false,
        dose_recomendada: '999999.00',
        dose_recomendada_max: '999999.00',
      },
      soybean_septoria_leaf_spot: {
        target_id: 11,
        has_register: true,
        dose_recomendada: 2,
        dose_recomendada_max: 3,
      },
      soybean_aerial_web_blight: {
        target_id: 12,
        has_register: false,
        dose_recomendada: '999999.00',
        dose_recomendada_max: '999999.00',
      },
      soybean_white_mold: {
        target_id: 13,
        has_register: false,
        dose_recomendada: '999999.00',
        dose_recomendada_max: '999999.00',
      },
    },
  },
  {
    id: 3,
    nome: 'Absoluto FIX',
    unidade: 'L',
    tipo: 'phytosanitary_category.diseases',
    pais_id: 1,
    is_white_mold: false,
    doses: {
      soybean_asian_soybean_rust: {
        target_id: 1,
        has_register: true,
        dose_recomendada: 1.4,
        dose_recomendada_max: 1.8,
      },
      soybean_anthracnosis: {
        target_id: 2,
        has_register: false,
        dose_recomendada: '999999.00',
        dose_recomendada_max: '999999.00',
      },
      soybean_target_spot: {
        target_id: 3,
        has_register: false,
        dose_recomendada: '999999.00',
        dose_recomendada_max: '999999.00',
      },
      soybean_powdery_mildew: {
        target_id: 4,
        has_register: false,
        dose_recomendada: '999999.00',
        dose_recomendada_max: '999999.00',
      },
      soybean_cercospora_blight: {
        target_id: 5,
        has_register: false,
        dose_recomendada: '999999.00',
        dose_recomendada_max: '999999.00',
      },
      soybean_septoria_leaf_spot: {
        target_id: 11,
        has_register: true,
        dose_recomendada: 1.5,
        dose_recomendada_max: 2,
      },
      soybean_aerial_web_blight: {
        target_id: 12,
        has_register: false,
        dose_recomendada: '999999.00',
        dose_recomendada_max: '999999.00',
      },
      soybean_white_mold: {
        target_id: 13,
        has_register: false,
        dose_recomendada: '999999.00',
        dose_recomendada_max: '999999.00',
      },
    },
  },
]

// Hardcoded no momento pq não é o foco
// TODO: criar planejamentos separados, fazer dados dinâmicos
const programs = [
  {
    created_at: getCustomDate(-10),
    cultivar_id: 1,
    dae: null,
    data_prevista: getCustomDate(20),
    doenca_prioritaria_id: 2,
    doenca_secundaria_1_id: 11,
    doenca_secundaria_2_id: null,
    doencas_sugeridas: true,
    id: 1,
    imported_from_planning_id: null,
    nome: 'Programa 1',
    planejamentos: [
      {
        aplicacao: 1,
        created_at: getCustomDate(-10),
        data_aplicacao_planejada: getCustomDate(7),
        deleted_at: null,
        doenca_prioritaria_id: 2,
        doenca_secundaria_1_id: 11,
        doenca_secundaria_2_id: null,
        doencas_sugeridas: false,
        eficacia_ajustada: '0.0000',
        eficacia_potencial: '0.0000',
        eficacias: [
          {
            alvo_id: 1,
            created_at: getCustomDate(-10),
            eficacia_ajustada: 0.5600589729380185,
            eficacia_potencial: 0.5905543588882314,
            id: 1,
            planejamento_id: 1,
            sem_registro: 1,
            updated_at: getCustomDate(-10),
          },
          {
            alvo_id: 2,
            created_at: getCustomDate(-10),
            eficacia_ajustada: 0.5580069854712185,
            eficacia_potencial: 0.616555224862836,
            id: 2,
            planejamento_id: 1,
            sem_registro: 1,
            updated_at: getCustomDate(-10),
          },
        ],
        id: 1,
        num_programa: 0,
        planejamento_adjuvantes: [],
        planejamento_defensivos: [
          {
            created_at: getCustomDate(-10),
            defensivo: {
              asset_owner_id: 200,
              ativo: null,
              ativo_concentracao: null,
              ativo_grupo: null,
              ativo_ingrediente: null,
              ativo_ingrediente_percent: null,
              ativo_unidade: null,
              classe: null,
              code: '9313',
              control_method_id: 1,
              created_at: getCustomDate(-10),
              deleted_at: null,
              distribuidor_id: null,
              fcd1: null,
              fcd2: null,
              fcd3: null,
              fcd4: null,
              fcd5: null,
              fcd6: null,
              fcd7: null,
              formatacao: null,
              grupo_quimico: null,
              id: 1,
              modo_acao: null,
              nome: 'Abacus HC',
              numero_registro: '9210',
              pais_id: 1,
              product_unit_measure_id: 4,
              tipo: null,
              unidade_medida: 'L',
              updated_at: getCustomDate(-10),
              url: null,
              uso: null,
            },
            defensivo_id: 1,
            dose: 2,
            id: 1,
            planejamento_id: 1,
            preco: 3,
            updated_at: getCustomDate(-10),
          },
        ],
        programa_id: 1,
        severidade_id: 1,
        updated_at: getCustomDate(-10),
      },
    ],
    racional_id: 4,
    semeadura_id: null,
    severidade_id: 1,
    total_aplicacoes_sugeridas: 1,
    updated_at: getCustomDate(-10),
    white_mold_enabled: false,
    white_mold_total_sprayings: null,
  },
  {
    created_at: getCustomDate(-20),
    cultivar_id: 1,
    dae: null,
    data_prevista: getCustomDate(30),
    doenca_prioritaria_id: 2,
    doenca_secundaria_1_id: 11,
    doenca_secundaria_2_id: null,
    doencas_sugeridas: true,
    id: 2,
    imported_from_planning_id: null,
    nome: 'Programa 2',
    planejamentos: [
      {
        aplicacao: 1,
        created_at: getCustomDate(-20),
        data_aplicacao_planejada: getCustomDate(7),
        deleted_at: null,
        doenca_prioritaria_id: 2,
        doenca_secundaria_1_id: 11,
        doenca_secundaria_2_id: null,
        doencas_sugeridas: false,
        eficacia_ajustada: '0.0000',
        eficacia_potencial: '0.0000',
        eficacias: [
          {
            alvo_id: 1,
            created_at: getCustomDate(-20),
            eficacia_ajustada: 0.5600589729380185,
            eficacia_potencial: 0.5905543588882314,
            id: 1,
            planejamento_id: 1,
            sem_registro: 1,
            updated_at: getCustomDate(-20),
          },
          {
            alvo_id: 2,
            created_at: getCustomDate(-20),
            eficacia_ajustada: 0.5580069854712185,
            eficacia_potencial: 0.616555224862836,
            id: 2,
            planejamento_id: 1,
            sem_registro: 1,
            updated_at: getCustomDate(-20),
          },
        ],
        id: 1,
        num_programa: 0,
        planejamento_adjuvantes: [],
        planejamento_defensivos: [
          {
            created_at: getCustomDate(-20),
            defensivo: {
              asset_owner_id: 200,
              ativo: null,
              ativo_concentracao: null,
              ativo_grupo: null,
              ativo_ingrediente: null,
              ativo_ingrediente_percent: null,
              ativo_unidade: null,
              classe: null,
              code: '9313',
              control_method_id: 1,
              created_at: getCustomDate(-20),
              deleted_at: null,
              distribuidor_id: null,
              fcd1: null,
              fcd2: null,
              fcd3: null,
              fcd4: null,
              fcd5: null,
              fcd6: null,
              fcd7: null,
              formatacao: null,
              grupo_quimico: null,
              id: 1,
              modo_acao: null,
              nome: 'Abacus HC',
              numero_registro: '9210',
              pais_id: 1,
              product_unit_measure_id: 4,
              tipo: null,
              unidade_medida: 'L',
              updated_at: getCustomDate(-20),
              url: null,
              uso: null,
            },
            defensivo_id: 1,
            dose: 2,
            id: 1,
            planejamento_id: 1,
            preco: 3,
            updated_at: getCustomDate(-20),
          },
        ],
        programa_id: 1,
        severidade_id: 1,
        updated_at: getCustomDate(-20),
      },
    ],
    racional_id: 4,
    semeadura_id: null,
    severidade_id: 1,
    total_aplicacoes_sugeridas: 1,
    updated_at: getCustomDate(-20),
    white_mold_enabled: false,
    white_mold_total_sprayings: null,
  },
  {
    created_at: getCustomDate(-30),
    cultivar_id: 5,
    dae: null,
    data_prevista: getCustomDate(20),
    doenca_prioritaria_id: 2,
    doenca_secundaria_1_id: 11,
    doenca_secundaria_2_id: null,
    doencas_sugeridas: true,
    id: 3,
    imported_from_planning_id: null,
    nome: 'Programa 3',
    planejamentos: [
      {
        aplicacao: 1,
        created_at: getCustomDate(-30),
        data_aplicacao_planejada: getCustomDate(7),
        deleted_at: null,
        doenca_prioritaria_id: 2,
        doenca_secundaria_1_id: 11,
        doenca_secundaria_2_id: null,
        doencas_sugeridas: false,
        eficacia_ajustada: '0.0000',
        eficacia_potencial: '0.0000',
        eficacias: [
          {
            alvo_id: 1,
            created_at: getCustomDate(-30),
            eficacia_ajustada: 0.5600589729380185,
            eficacia_potencial: 0.5905543588882314,
            id: 1,
            planejamento_id: 1,
            sem_registro: 1,
            updated_at: getCustomDate(-30),
          },
          {
            alvo_id: 2,
            created_at: getCustomDate(-30),
            eficacia_ajustada: 0.5580069854712185,
            eficacia_potencial: 0.616555224862836,
            id: 2,
            planejamento_id: 1,
            sem_registro: 1,
            updated_at: getCustomDate(-30),
          },
        ],
        id: 1,
        num_programa: 0,
        planejamento_adjuvantes: [],
        planejamento_defensivos: [
          {
            created_at: getCustomDate(-30),
            defensivo: {
              asset_owner_id: 200,
              ativo: null,
              ativo_concentracao: null,
              ativo_grupo: null,
              ativo_ingrediente: null,
              ativo_ingrediente_percent: null,
              ativo_unidade: null,
              classe: null,
              code: '9313',
              control_method_id: 1,
              created_at: getCustomDate(-30),
              deleted_at: null,
              distribuidor_id: null,
              fcd1: null,
              fcd2: null,
              fcd3: null,
              fcd4: null,
              fcd5: null,
              fcd6: null,
              fcd7: null,
              formatacao: null,
              grupo_quimico: null,
              id: 1,
              modo_acao: null,
              nome: 'Abacus HC',
              numero_registro: '9210',
              pais_id: 1,
              product_unit_measure_id: 4,
              tipo: null,
              unidade_medida: 'L',
              updated_at: getCustomDate(-30),
              url: null,
              uso: null,
            },
            defensivo_id: 1,
            dose: 2,
            id: 1,
            planejamento_id: 1,
            preco: 3,
            updated_at: getCustomDate(-30),
          },
        ],
        programa_id: 1,
        severidade_id: 1,
        updated_at: getCustomDate(-30),
      },
    ],
    racional_id: 4,
    semeadura_id: null,
    severidade_id: 1,
    total_aplicacoes_sugeridas: 1,
    updated_at: getCustomDate(-30),
    white_mold_enabled: false,
    white_mold_total_sprayings: null,
  },
]

export { defensives, programs }
