import { APIFailureWrapper, mockFlag } from '@/utils/mock'
import { diseases } from '@/modules/execution/mock/diseasesSeeds'
import { cropsByName } from '@/services/__mocks__/cropsSeeds'
import { defensives, programs } from '@/modules/execution/mock/executionsSeeds'

const executionRoutes = [
  mockFlag(
    {
      method: 'get',
      url: '/defensives',
      result: () => {
        const response = defensives.map((defensive) => {
          return {
            id: defensive.id,
            nome: defensive.nome,
            unidade: defensive.unidade,
            tipo: defensive.tipo,
            pais_id: defensive.pais_id,
            is_white_mold: defensive.is_white_mold,
            doses: defensive.doses,
          }
        })

        return APIFailureWrapper({
          content: response,
          errorMessage: 'Erro na listagem de defensivos',
        })
      },
    },
    'on'
  ),
  mockFlag(
    {
      method: 'get',
      url: '/defensives/last-values',
      result: () => {
        const response = defensives.reduce((acc, defensive, index) => {
          acc[index] = {
            defensivo_id: defensive.id,
            dose: String(Math.floor(Math.random() * 10) + 1),
            preco: String(Math.floor(Math.random() * 10) + 1),
            ranking: 1,
          }
          return acc
        }, {})

        return APIFailureWrapper({
          content: response,
          errorMessage: 'Erro na listagem de últimos defensivos',
        })
      },
    },
    'on'
  ),
  mockFlag(
    {
      method: 'get',
      url: '/diseases',
      result: ({ queryParams }) => {
        let cropName = 'soybean'
        if (queryParams.seasonCrop !== '5') {
          cropName = 'wheat'
        }

        const filteredDiseases = diseases.filter(
          (disease) => disease.crop_id == cropsByName[cropName].id
        )

        const response = filteredDiseases.map((disease) => {
          return {
            id: disease.id,
            name: disease.name,
            crop_id: disease.crop_id,
            code: disease.code,
          }
        })

        return APIFailureWrapper({
          content: response,
          errorMessage: 'Erro na listagem de doenças',
        })
      },
    },
    'on'
  ),
  mockFlag(
    {
      method: 'get',
      url: '/programas',
      result: ({ queryParams }) => {
        // Hardcoded no momento pq não é o foco
        const response = programs.filter(
          (program) => program.cultivar_id == queryParams.cultivar_id
        )

        return APIFailureWrapper({
          content: response,
          errorMessage: 'Erro na listagem de programas',
        })
      },
    },
    'on'
  ),
]

export { executionRoutes }
